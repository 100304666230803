/**
 * Return a fully formatted currency string
 *
 * @param currency - ISO currency symbol (e.g. USD)
 * @param amount - Amount (in cents)
 * @example - $550,000
 */
export function formatted(currency: string, amount: number) {
  const up = amount / 100;
  return up
    .toLocaleString(undefined, {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    })
    .replace(".00", "");
}

/**
 * Remove the currency code (in brackets only) from a string
 *
 * @param str - String to remove the code from
 * @description - If the string contains a currency code in brackets, it will be removed if its not
 * then remove last 3 characters from the string that has a space before it
 */
export function stripCode(str: string): string {
  const withBracketsRegex = new RegExp(/\([A-Z]+\)/);

  if (withBracketsRegex.test(str)) {
    return str.replace(/\([A-Z]+\)/g, "").trim();
  }

  return str.replace(/\s[A-Za-z]{3}$/g, "").trim();
}
