import { BrandInfo, getColors } from "../entities/brand";

export type ThemeConfig = {
  bodyFont: {
    family: string;
    url: string;
  };
  headingFont: {
    family: string;
    url: string;
  };
  primaryColor: string;
  secondaryColor: string;
  name: string;
};

export const themeMeta = (
  brandInfo: BrandInfo | undefined,
  orgName: string
): ThemeConfig => {
  const {
    backgroundColor: primaryColor,
    textColor: secondaryColor,
  } = getColors(brandInfo);

  return {
    bodyFont: {
      family: "Lato",
      url: "https://fonts.googleapis.com/css?family=Lato",
    },
    headingFont: {
      family: "Lato",
      url: "https://fonts.googleapis.com/css?family=Lato",
    },
    primaryColor,
    secondaryColor,
    name: `${orgName}'s theme`,
  };
};
