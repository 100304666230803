import {
  Button,
  Dropdown,
  Theme,
} from "@screencloud/screencloud-ui-components";
import styled, { StyledComponent } from "styled-components";
import { WhiteLabelStyles } from "../../helpers/whiteLabel";

export const StyledMainNav = styled.div`
  &.sidebar-top {
    .wrapper {
      .button {
        padding: 8px 8px 8px 30px;

        &.goyucu {
          padding-left: 20px;
          span {
            margin-left: 0px;
          }
        }

        i {
          flex: none;
          order: 0;
          flex-grow: 0;
          width: 22px;
          height: 22px;
        }

        .casting {
          padding: 0 8px;
          background: var(--color-black-50);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          order: 2;
          border: 1px solid var(--color-white-20);

          i {
            width: 20px;
            height: 20px;
            background-color: var(--color-white-100);
          }

          span {
            margin: 0 0 0 5px;
            font-size: 0.875em;
            color: var(--color-white-100);
          }

          :hover {
            background: var(--color-black-80);
          }
        }

        span {
          flex: none;
          order: 1;
          flex-grow: 1;
          margin: 0px 19px;
        }

        &.active {
          .casting {
            border: 1px solid transparent;
          }
        }
      }

      a.help {
        margin-top: auto;

        button {
          width: 100%;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      margin: 4px 0;
      background: var(--color-black-50);
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    width: ${Theme.sidebar.minWidth};

    &.sidebar-top {
      .wrapper {
        &.casting {
          .button {
            min-height: 32px;
            flex-direction: column;
          }
        }
        .button.active,
        .button {
          padding: 12px 8px;
          height: auto;
          gap: 8px;
          justify-content: center;

          &.goyucu {
            padding-left: 5px;
          }

          span {
            display: none;
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .button.active,
    .button {
      i + span {
        display: inline-block;
      }
    }
  }
`;

export const SupportNavDropdown: StyledComponent<typeof Dropdown, any> = styled(
  Dropdown
)<WhiteLabelStyles>`
  &.ui.left.pointing.dropdown {
    width: 240px;
    align-items: center;
    border-radius: 0px;
    box-shadow: none;
    display: flex;
    color: ${(props) => props.textColor ?? `var(--color-gray-300)`};
    font-size: 1em;
    font-weight: normal;
    height: 40px;
    padding-left: 30px;
    i.dropdown.icon {
      display: none;
    }
    .support-nav {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 8px;

      i {
        flex-shrink: 0;
        background-color: ${(props) =>
          props.textColor ?? `var(--color-gray-300)`};
        width: 22px;
        height: 22px;

        + span {
          margin-left: 19px;
          width: 100%;
        }

        &[name="arrow-right"] {
          display: none;
        }
      }
    }

    .support-nav-menu {
      .wistia_click_to_play {
        display: flex;
        gap: 10px;
        width: 100% !important;
        height: auto !important;
      }
    }

    &.active,
    &:hover,
    .active:focus,
    .active:hover {
      font-weight: bold;
      background-color: ${(props) =>
        props.activeItemColor ?? `var(--color-black-100)`};
      color: ${(props) => props.activeTextColor ?? `var(--color-white-100)`};

      .support-nav {
        > span {
          color: ${(props) =>
            props.activeTextColor ?? `var(--color-white-100)`};
          font-weight: normal;
        }

        > i {
          background-color: ${(props) =>
            props.activeTextColor ?? `var(--color-white-100)`};
        }
      }

      i[name="arrow-right"] {
        display: flex;
      }
    }
    .menu {
      &.transition.visible {
        ${Theme.default.borderRadius};
        margin-left: 0;
        height: auto;
        width: 180px;
      }
      :after {
        display: none;
      }
    }
  }
  @media screen and (max-width: ${Theme.break.largeHD}) {
    &.ui.left.pointing.dropdown {
      width: 70px;
      padding: 10px;
      justify-content: center;

      .support-nav {
        justify-content: center;
        padding: 0;
        > span {
          display: none;
        }
        i[name="arrow-right"] {
          position: absolute;
          right: 0;
          width: 20px;
        }
      }
    }
  }
`;

export const SupportNavReseller = styled.div<WhiteLabelStyles>`
  align-items: center;
  display: flex;
  color: ${(props) => props.textColor ?? `var(--color-gray-300)`};
  font-size: 1em;
  font-weight: normal;
  height: 40px;
  padding-left: 30px;
  cursor: pointer;

  i {
    background-color: ${(props) => props.textColor ?? `var(--color-gray-300)`};

    + span {
      margin-left: 19px;
    }
  }

  :hover {
    background-color: ${(props) =>
      props.activeItemColor ?? `var(--color-black-100)`};

    span {
      color: ${(props) => props.activeTextColor ?? `var(--color-white-100)`};
    }

    i {
      background-color: ${(props) =>
        props.activeTextColor ?? `var(--color-white-100)`};
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    padding: 10px;
    justify-content: center;

    span {
      display: none;
    }
  }
`;

export const MainNavButton: StyledComponent<typeof Button, any> = styled(
  Button
)<WhiteLabelStyles>`
  &.ui.button.nav {
    display: none;
  }
  &.button {
    align-items: center;
    background: none;
    border-radius: 0px;
    box-shadow: none;
    display: flex;
    color: ${(props) => props.textColor ?? `var(--color-gray-300)`};
    font-size: 1em;
    font-weight: normal;
    height: 40px;
    padding-left: 30px;

    i {
      display: inline-block;
      background-color: ${(props) =>
        props.textColor ?? `var(--color-gray-300)`};

      + span {
        margin-left: 19px;
      }
    }

    &[disabled] {
      opacity: 0.3;
      cursor: default;
    }

    :focus,
    :hover {
      background-color: ${(props) =>
        props.activeItemColor ?? `var(--color-black-100)`};

      span {
        color: ${(props) => props.activeTextColor ?? `var(--color-white-100)`};
      }

      i {
        background-color: ${(props) =>
          props.activeTextColor ?? `var(--color-white-100)`};
      }
    }

    &.active,
    .active:focus,
    .active:hover {
      background-color: var(--color-primary-background);
      color: var(--color-primary-foreground);
      position: relative;

      i {
        background-color: var(--color-primary-foreground);
      }

      span {
        font-weight: bold;
        color: var(--color-primary-foreground);
      }
    }
    :first-child,
    :last-child {
      border-radius: 0px;
    }

    @media screen and (max-width: ${Theme.break.small}) {
      &.ui.button.nav {
        display: flex;
      }
    }
  }
`;

export interface StyledMobileHeaderProps {
  open?: boolean;
}

export const StyledMobileHeader = styled.div<
  StyledMobileHeaderProps & WhiteLabelStyles
>`
  display: none;
  min-width: 375px;
  background: ${(props) => props.bgColor ?? `var(--color-gray-950)`};
  padding: 0;
  width: 100%;
  min-height: 48px;
  position: fixed;
  z-index: 10;

  .logo-banner-mainmenu-wrapper {
    background: var(--color-primary-background-active);
  }

  .logo,
  .menu-toggle {
    position: absolute;
    width: 48px;
    height: 48px !important;
    top: 0;
    padding: 0;
    margin-bottom: 0;
  }

  .logo {
    display: inline-block;
    width: auto;
    margin: 0;

    a {
      display: inline-flex;
      height: 100%;

      .glyph {
        margin-right: 0;
      }
    }
  }

  .menu-toggle {
    padding: 13px 15px;
    position: absolute;
    left: 0;
    display: inline-block !important;
    z-index: 1;
    border-radius: 0;

    .one,
    .two,
    .three {
      width: 100%;
      height: 2px;
      background: ${(props) => props.textColor ?? `var(--color-white-100)`};
      margin: 4px auto;
      backface-visibility: hidden;
      transition-duration: 0.3s;
      ${Theme.default.borderRadius};
    }

    + .logo {
      margin: 0;
    }

    &.active {
      position: fixed;
      top: 0;
      padding: 13px 24px;
      width: 66px;
      .one {
        transform: rotate(45deg) translate(4.5px, 4.5px);
      }
      .two {
        opacity: 0;
      }
      .three {
        transform: rotate(-45deg) translate(4px, -4px);
      }

      + .logo-banner-mainmenu-wrapper + .account-nav + .overlay,
      + .logo + .account-nav + .overlay {
        height: 100%;
        opacity: 1;
        overflow: visible;
        top: 0;
        padding: 58px 0 0 0;
        background: ${(props) => props.bgColor ?? `var(--color-gray-950)`};

        .wrapper {
          opacity: 1;

          .button {
            top: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .overlay {
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;

    .sidebar-top {
      width: 100%;
    }
  }

  &.mobile-header {
    .account-nav {
      display: inline-block;
      position: absolute;
      width: 48px;
      height: 48px;
      top: 0;
      padding: 0;
      margin-bottom: 0;
      right: 0;
      left: auto;
      width: auto;

      .ui.dropdown {
        .account-trigger {
          height: 48px;
          width: 48px;
          padding: 0;
        }

        .menu {
          border-radius: 0;
          border: none;
          left: auto;
          margin: 0;
          padding-top: 0;
          right: 0;
          text-align: right;
          width: 100vw;
        }
      }
    }

    .overlay {
      .wrapper {
        a.help {
          button {
            padding: 0;

            span {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.small}) {
    display: block;
    position: relative;

    .logo-banner-mainmenu-wrapper {
      margin: 0 48px !important;
    }
    .logo {
      width: 100%;
      margin: 0;

      a {
        width: auto;
        display: flex;
        justify-content: flex-start;
        padding: 0 48px;

        .glyph {
          background-position: center;
          height: 32px;
        }
        .wordmark {
          width: 118px;
        }
      }
    }

    &.mobile-header {
      .account-nav {
        .menu {
          .item {
            padding: 0;
          }

          .button {
            width: 100%;
            background: none;
            border-radius: 0;
            box-shadow: none;
            text-align: left;
            font-weight: normal;
          }
        }
      }

      .overlay {
        background: transparent;

        .wrapper {
          .button {
            justify-content: start;
            flex-direction: row;
            padding-left: 21px;

            &.casting {
              padding: 0 8px;

              span {
                margin: 0;
              }
            }

            &.goyucu {
              padding-left: 10px;
              span {
                margin-left: 0px;
              }
            }

            span {
              display: inline-block;
              margin-left: 16px;
            }

            &.active:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;
