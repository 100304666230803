import { Input } from "@screencloud/screencloud-ui-components";
import { useCallback, useContext, useReducer, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../helpers/whiteLabel";
import { SiteContext } from "../sitecontext";
import DashboardPopover from "./dashboardpopover";
import { ActionMessageType } from "./reducers";
import { NavigationControlPanel } from "./styles";

export enum NavigationType {
  Pane = "pane",
  Popup = "popup",
}

export enum FrameWidthType {
  Fit = "fit",
  Custom = "custom",
}

export interface NavigationControlProps {
  url: string;
  recorderUrl: string;
  mode: NavigationType;
  frameWidth: FrameWidthType;
  dispatch: (action) => void;
  onNavigate: (url: string) => void;
  isRecording: boolean;
  hasActions: boolean;
}

interface NavigationControlState {
  urlInput: string;
  canNavigate?: boolean;
  isSecure?: boolean;
}

const navigationReducer = (
  state: NavigationControlState,
  action: any
): NavigationControlState => {
  switch (action.type) {
    case "url:update":
      const urlInput: string = action.payload?.trim();
      const canNavigate = urlInput !== "";
      const isSecure = !urlInput.startsWith("http:");

      return {
        urlInput,
        canNavigate,
        isSecure,
      };

    default:
      return state;
  }
};

export const NavigationControl = (props: NavigationControlProps) => {
  const siteContext = useContext(SiteContext);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(true);

  const [state, setState] = useReducer(navigationReducer, {
    urlInput: props.url,
    canNavigate: props.url !== "",
    isSecure: !props.url.startsWith("http:"),
  });

  const navigateToUrl = useCallback(() => {
    let url = state.urlInput;
    if (!url.startsWith("https://") && !url.startsWith("http://")) {
      url = `https://${url}`;
      setState({ type: "url:update", payload: url });
    }

    props.dispatch({ type: ActionMessageType.Navigate, payload: url });
  }, [state]);

  return (
    <NavigationControlPanel>
      <div className="url" ref={popoverRef}>
        <Input
          disabled={siteContext.isPreview}
          placeholder="Type URL here to start journey"
          value={state.urlInput}
          onChange={(e) =>
            setState({ type: "url:update", payload: e.target.value })
          }
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === "Enter") {
              navigateToUrl();
            }
          }}
          onFocus={() => setPopoverOpen(false)}
          fluid
        />
      </div>
      <div className="controls">
        <PrimaryButton
          disabled={
            !state.canNavigate || props.hasActions || siteContext.isPreview
          }
          onClick={() => navigateToUrl()}
        >
          <FormattedMessage
            id="ui_component.site.recorder.go"
            defaultMessage="Go"
          />
        </PrimaryButton>
      </div>
      <DashboardPopover
        context={popoverRef}
        open={popoverOpen}
        handleDismiss={() => setPopoverOpen(false)}
        position="bottom left"
        header={
          <FormattedMessage
            id="ui_component.common.label.get_started"
            defaultMessage="Get Started"
          />
        }
        content={
          <FormattedMessage
            id="ui_component.site.recorder.clear_url_click_go"
            defaultMessage="Start your journey by entering your URL and clicking Go"
          />
        }
      />
    </NavigationControlPanel>
  );
};
