import { appConfig } from "src/appConfig";

export interface FileConfig {
  mimetype: string;
  name: string;
  source: string;
}

export interface FilesConfig {
  [key: string]: FileConfig;
}

export enum Media {
  BACKGROUND_100 = "Background 100",
  BACKGROUND_101 = "Background 101",
  BACKGROUND_103 = "Background 103",
  BACKGROUND_104 = "Background 104",
  BACKGROUND_107 = "Background 107",
  BACKGROUND_13 = "Background 13",
  BACKGROUND_14 = "Background 14",
  BACKGROUND_16 = "Background 16",
  BACKGROUND_17 = "Background 17",
  BACKGROUND_21 = "Background 21",
  BACKGROUND_24 = "Background 24",
  BACKGROUND_27 = "Background 27",
  BACKGROUND_28 = "Background 28",
  BACKGROUND_3 = "Background 3",
  BACKGROUND_30 = "Background 30",
  BACKGROUND_32 = "Background 32",
  BACKGROUND_33 = "Background 33",
  BACKGROUND_34 = "Background 34",
  BACKGROUND_35 = "Background 35",
  BACKGROUND_4 = "Background 4",
  BACKGROUND_40 = "Background 40",
  BACKGROUND_41 = "Background 41",
  BACKGROUND_43 = "Background 43",
  BACKGROUND_45 = "Background 45",
  BACKGROUND_47 = "Background 47",
  BACKGROUND_48 = "Background 48",
  BACKGROUND_51 = "Background 51",
  BACKGROUND_53 = "Background 53",
  BACKGROUND_58 = "Background 58",
  BACKGROUND_59 = "Background 59",
  BACKGROUND_6 = "Background 6",
  BACKGROUND_60 = "Background 60",
  BACKGROUND_61 = "Background 61",
  BACKGROUND_65 = "Background 65",
  BACKGROUND_66 = "Background 66",
  BACKGROUND_67 = "Background 67",
  BACKGROUND_68 = "Background 68",
  BACKGROUND_69 = "Background 69",
  BACKGROUND_70 = "Background 70",
  BACKGROUND_77 = "Background 77",
  BACKGROUND_8 = "Background 8",
  BACKGROUND_80 = "Background 80",
  BACKGROUND_83 = "Background 83",
  BACKGROUND_84 = "Background 84",
  BACKGROUND_92 = "Background 92",
  BACKGROUND_93 = "Background 93",
  MOTION_BACKGROUND_10 = "Motion Background 10",
  MOTION_BACKGROUND_1 = "Motion Background 1",
  MOTION_BACKGROUND_2 = "Motion Background 2",
  MOTION_BACKGROUND_3 = "Motion Background 3",
  MOTION_BACKGROUND_4 = "Motion Background 4",
  MOTION_BACKGROUND_5 = "Motion Background 5",
  MOTION_BACKGROUND_6 = "Motion Background 6",
  MOTION_BACKGROUND_7 = "Motion Background 7",
  MOTION_BACKGROUND_8 = "Motion Background 8",
  MOTION_BACKGROUND_9 = "Motion Background 9",
  CANVAS_BLACK_CHEVRONS_BG = "Canvas Black Chevrons Bg",
  CANVAS_BLACK_GRADIENT_BG = "Canvas Black Gradient Bg",
  CANVAS_BLUE_GRADIENT_BG = "Canvas Blue Gradient Bg",
  CANVAS_BLUE_SKY_CELEBRATE_BG = "Canvas Blue Sky Celebrate Bg",
  CANVAS_BLUE_WAVE_BG = "Canvas Blue Wave Bg",
  CANVAS_DASHBOARD = "Canvas Dashboard",
  CANVAS_EMPLOYEE_CELEBRATION = "Canvas Employee Celebration",
  CANVAS_EMPLOYEE = "Canvas Employee",
  CANVAS_FACE_MASK_ICON = "Canvas Face Mask Icon",
  CANVAS_FIRE_ICON = "Canvas Fire Icon",
  CANVAS_GREEN_FOREST_BG = "Canvas Green Forest Bg",
  CANVAS_HARD_HAT_ICON = "Canvas Hard Hat Icon",
  CANVAS_HAZARDOUS_WASTE_ICON = "Canvas Hazardous Waste Icon",
  CANVAS_HI_VIS_ICON = "Canvas Hi Vis Icon",
  CANVAS_PROTECTIVE_EYEWEAR_ICON = "Canvas Protective Eyewear Icon",
  CANVAS_SAFETY_BOOTS_ICON = "Canvas Safety Boots Icon",
  CANVAS_SAFETY_GLOVES_ICON = "Canvas Safety Gloves Icon",
  CANVAS_SCAN_QR = "Canvas Scan QR",
  CANVAS_WARNING_ICON = "Canvas Warning Icon",
  CANVAS_THUMBNAIL_BANNER = "banner",
  CANVAS_THUMBNAIL_DEFAULT = "default",
  CANVAS_THUMBNAIL_QRCODE = "qrcode",
  CHANNEL_COVER_1 = "Channel Cover 1",
  CHANNEL_COVER_2 = "Channel Cover 2",
  CHANNEL_COVER_3 = "Channel Cover 3",
  CHANNEL_COVER_4 = "Channel Cover 4",
}

export const BackgroundImages: FilesConfig = {
  [Media.BACKGROUND_100]: {
    mimetype: "image/jpeg",
    name: "Background 100",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 100.jpg`,
  },
  [Media.BACKGROUND_101]: {
    mimetype: "image/jpeg",
    name: "Background 101",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 101.jpg`,
  },
  [Media.BACKGROUND_103]: {
    mimetype: "image/jpeg",
    name: "Background 103",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 103.jpg`,
  },
  [Media.BACKGROUND_104]: {
    mimetype: "image/jpeg",
    name: "Background 104",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 104.jpg`,
  },
  [Media.BACKGROUND_107]: {
    mimetype: "image/jpeg",
    name: "Background 107",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 107.jpg`,
  },
  [Media.BACKGROUND_13]: {
    mimetype: "image/jpeg",
    name: "Background 13",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 13.jpg`,
  },
  [Media.BACKGROUND_14]: {
    mimetype: "image/jpeg",
    name: "Background 14",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 14.jpg`,
  },
  [Media.BACKGROUND_16]: {
    mimetype: "image/jpeg",
    name: "Background 16",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 16.jpg`,
  },
  [Media.BACKGROUND_17]: {
    mimetype: "image/jpeg",
    name: "Background 17",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 17.jpg`,
  },
  [Media.BACKGROUND_21]: {
    mimetype: "image/jpeg",
    name: "Background 21",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 21.jpg`,
  },
  [Media.BACKGROUND_24]: {
    mimetype: "image/jpeg",
    name: "Background 24",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 24.jpg`,
  },
  [Media.BACKGROUND_27]: {
    mimetype: "image/jpeg",
    name: "Background 27",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 27.jpg`,
  },
  [Media.BACKGROUND_28]: {
    mimetype: "image/jpeg",
    name: "Background 28",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 28.jpg`,
  },
  [Media.BACKGROUND_3]: {
    mimetype: "image/jpeg",
    name: "Background 3",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 3.jpg`,
  },
  [Media.BACKGROUND_30]: {
    mimetype: "image/jpeg",
    name: "Background 30",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 30.jpg`,
  },
  [Media.BACKGROUND_32]: {
    mimetype: "image/jpeg",
    name: "Background 32",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 32.jpg`,
  },
  [Media.BACKGROUND_33]: {
    mimetype: "image/jpeg",
    name: "Background 33",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 33.jpg`,
  },
  [Media.BACKGROUND_34]: {
    mimetype: "image/jpeg",
    name: "Background 34",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 34.jpg`,
  },
  [Media.BACKGROUND_35]: {
    mimetype: "image/jpeg",
    name: "Background 35",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 35.jpg`,
  },
  [Media.BACKGROUND_4]: {
    mimetype: "image/jpeg",
    name: "Background 4",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 4.jpg`,
  },
  [Media.BACKGROUND_40]: {
    mimetype: "image/jpeg",
    name: "Background 40",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 40.jpg`,
  },
  [Media.BACKGROUND_41]: {
    mimetype: "image/jpeg",
    name: "Background 41",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 41.jpg`,
  },
  [Media.BACKGROUND_43]: {
    mimetype: "image/jpeg",
    name: "Background 43",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 43.jpg`,
  },
  [Media.BACKGROUND_45]: {
    mimetype: "image/jpeg",
    name: "Background 45",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 45.jpg`,
  },
  [Media.BACKGROUND_47]: {
    mimetype: "image/jpeg",
    name: "Background 47",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 47.jpg`,
  },
  [Media.BACKGROUND_48]: {
    mimetype: "image/jpeg",
    name: "Background 48",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 48.jpg`,
  },
  [Media.BACKGROUND_51]: {
    mimetype: "image/jpeg",
    name: "Background 51",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 51.jpg`,
  },
  [Media.BACKGROUND_53]: {
    mimetype: "image/jpeg",
    name: "Background 53",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 53.jpg`,
  },
  [Media.BACKGROUND_58]: {
    mimetype: "image/jpeg",
    name: "Background 58",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 58.jpg`,
  },
  [Media.BACKGROUND_59]: {
    mimetype: "image/jpeg",
    name: "Background 59",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 59.jpg`,
  },
  [Media.BACKGROUND_6]: {
    mimetype: "image/jpeg",
    name: "Background 6",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 6.jpg`,
  },
  [Media.BACKGROUND_60]: {
    mimetype: "image/jpeg",
    name: "Background 60",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 60.jpg`,
  },
  [Media.BACKGROUND_61]: {
    mimetype: "image/jpeg",
    name: "Background 61",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 61.jpg`,
  },
  [Media.BACKGROUND_65]: {
    mimetype: "image/jpeg",
    name: "Background 65",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 65.jpg`,
  },
  [Media.BACKGROUND_66]: {
    mimetype: "image/jpeg",
    name: "Background 66",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 66.jpg`,
  },
  [Media.BACKGROUND_67]: {
    mimetype: "image/jpeg",
    name: "Background 67",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 67.jpg`,
  },
  [Media.BACKGROUND_68]: {
    mimetype: "image/jpeg",
    name: "Background 68",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 68.jpg`,
  },
  [Media.BACKGROUND_69]: {
    mimetype: "image/jpeg",
    name: "Background 69",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 69.jpg`,
  },
  [Media.BACKGROUND_70]: {
    mimetype: "image/jpeg",
    name: "Background 70",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 70.jpg`,
  },
  [Media.BACKGROUND_77]: {
    mimetype: "image/jpeg",
    name: "Background 77",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 77.jpg`,
  },
  [Media.BACKGROUND_8]: {
    mimetype: "image/jpeg",
    name: "Background 8",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 8.jpg`,
  },
  [Media.BACKGROUND_80]: {
    mimetype: "image/jpeg",
    name: "Background 80",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 80.jpg`,
  },
  [Media.BACKGROUND_83]: {
    mimetype: "image/jpeg",
    name: "Background 83",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 83.jpg`,
  },
  [Media.BACKGROUND_84]: {
    mimetype: "image/jpeg",
    name: "Background 84",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 84.jpg`,
  },
  [Media.BACKGROUND_92]: {
    mimetype: "image/jpeg",
    name: "Background 92",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 92.jpg`,
  },
  [Media.BACKGROUND_93]: {
    mimetype: "image/jpeg",
    name: "Background 93",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Background 93.jpg`,
  },
  [Media.MOTION_BACKGROUND_10]: {
    mimetype: "video/mp4",
    name: "Motion Background 10",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 10.mp4`,
  },
  [Media.MOTION_BACKGROUND_1]: {
    mimetype: "video/mp4",
    name: "Motion Background 1",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 1.mp4`,
  },
  [Media.MOTION_BACKGROUND_2]: {
    mimetype: "video/mp4",
    name: "Motion Background 2",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 2.mp4`,
  },
  [Media.MOTION_BACKGROUND_3]: {
    mimetype: "video/mp4",
    name: "Motion Background 3",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 3.mp4`,
  },
  [Media.MOTION_BACKGROUND_4]: {
    mimetype: "video/mp4",
    name: "Motion Background 4",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 4.mp4`,
  },
  [Media.MOTION_BACKGROUND_5]: {
    mimetype: "video/mp4",
    name: "Motion Background 5",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 5.mp4`,
  },
  [Media.MOTION_BACKGROUND_6]: {
    mimetype: "video/mp4",
    name: "Motion Background 6",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 6.mp4`,
  },
  [Media.MOTION_BACKGROUND_7]: {
    mimetype: "video/mp4",
    name: "Motion Background 7",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 7.mp4`,
  },
  [Media.MOTION_BACKGROUND_8]: {
    mimetype: "video/mp4",
    name: "Motion Background 8",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 8.mp4`,
  },
  [Media.MOTION_BACKGROUND_9]: {
    mimetype: "video/mp4",
    name: "Motion Background 9",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/background/Motion Background 9.mp4`,
  },
};

export const CanvasImages: FilesConfig = {
  [Media.CANVAS_BLACK_CHEVRONS_BG]: {
    mimetype: "image/png",
    name: "Black Chevrons Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/black-chevrons-bg.png`,
  },
  [Media.CANVAS_BLACK_GRADIENT_BG]: {
    mimetype: "image/png",
    name: "Black Gradient Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/black-gradient-bg.png`,
  },
  [Media.CANVAS_BLUE_GRADIENT_BG]: {
    mimetype: "image/jpeg",
    name: "Blue Gradient Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/blue-gradient-bg.jpg`,
  },
  [Media.CANVAS_BLUE_SKY_CELEBRATE_BG]: {
    mimetype: "image/jpeg",
    name: "Blue Sky Celebrate Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/blue-sky-celebrate-bg.jpg`,
  },
  [Media.CANVAS_BLUE_WAVE_BG]: {
    mimetype: "image/jpeg",
    name: "Blue Wave Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/blue-wave-bg.jpg`,
  },
  [Media.CANVAS_DASHBOARD]: {
    mimetype: "image/png",
    name: "Dashboard",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/dashboard.png`,
  },
  [Media.CANVAS_EMPLOYEE_CELEBRATION]: {
    mimetype: "image/jpeg",
    name: "Employee Celebration",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/employee-celebration.jpg`,
  },
  [Media.CANVAS_EMPLOYEE]: {
    mimetype: "image/jpeg",
    name: "Employee",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/employee.jpg`,
  },
  [Media.CANVAS_FACE_MASK_ICON]: {
    mimetype: "image/png",
    name: "Face Mask Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/face-mask-icon.png`,
  },
  [Media.CANVAS_FIRE_ICON]: {
    mimetype: "image/png",
    name: "Fire Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/fire-icon.png`,
  },
  [Media.CANVAS_GREEN_FOREST_BG]: {
    mimetype: "image/jpeg",
    name: "Green Forest Background",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/green-forest.bg.jpg`,
  },
  [Media.CANVAS_HARD_HAT_ICON]: {
    mimetype: "image/png",
    name: "Hard Hat Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/hard-hat-icon.png`,
  },
  [Media.CANVAS_HAZARDOUS_WASTE_ICON]: {
    mimetype: "image/png",
    name: "Hazardous Waste Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/hazardous-waste-icon.png`,
  },
  [Media.CANVAS_HI_VIS_ICON]: {
    mimetype: "image/png",
    name: "Hi Vis Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/hi-vis-icon.png`,
  },
  [Media.CANVAS_PROTECTIVE_EYEWEAR_ICON]: {
    mimetype: "image/png",
    name: "Protective Eyewear Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/protective-eyewear-icon.png`,
  },
  [Media.CANVAS_SAFETY_BOOTS_ICON]: {
    mimetype: "image/png",
    name: "Safety Boots Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/safety-boots-icon.png`,
  },
  [Media.CANVAS_SAFETY_GLOVES_ICON]: {
    mimetype: "image/png",
    name: "Safety Gloves Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/safety-gloves-icon.png`,
  },
  [Media.CANVAS_SCAN_QR]: {
    mimetype: "image/png",
    name: "Scan QR",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/scan-qr.png`,
  },
  [Media.CANVAS_WARNING_ICON]: {
    mimetype: "image/png",
    name: "Warning Icon",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/canvas/warning-icon.png`,
  },
};

export const CanvasThumbnailImages: FilesConfig = {
  [Media.CANVAS_THUMBNAIL_BANNER]: {
    mimetype: "image/png",
    name: "banner",
    source: `s3://${appConfig.altUploadBucket}/canvas-thumbnail/banner.png`,
  },
  [Media.CANVAS_THUMBNAIL_DEFAULT]: {
    mimetype: "image/png",
    name: "default",
    source: `s3://${appConfig.altUploadBucket}/canvas-thumbnail/default.png`,
  },
  [Media.CANVAS_THUMBNAIL_QRCODE]: {
    mimetype: "image/png",
    name: "qrcode",
    source: `s3://${appConfig.altUploadBucket}/canvas-thumbnail/qrcode.png`,
  },
};

export const ChannelCoverImages: FilesConfig = {
  [Media.CHANNEL_COVER_1]: {
    mimetype: "image/png",
    name: "Cover 1",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/channel-cover/Cover 1.png`,
  },
  [Media.CHANNEL_COVER_2]: {
    mimetype: "image/png",
    name: "Cover 2",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/channel-cover/Cover 2.png`,
  },
  [Media.CHANNEL_COVER_3]: {
    mimetype: "image/png",
    name: "Cover 3",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/channel-cover/Cover 3.png`,
  },
  [Media.CHANNEL_COVER_4]: {
    mimetype: "image/png",
    name: "Cover 4",
    source: `s3://${appConfig.altUploadBucket}/new-onboarding/channel-cover/Cover 4.png`,
  },
};
