import { Countries } from "src/billing/constants";
import { getOrgRegionFromUrl } from "src/helpers/urlHelper";
import { appConfig } from "src/appConfig";

const isDevelopment = appConfig.environment === "development";

export interface AppInstanceConfig {
  version: string;
  config: Record<string, unknown>;
}

export interface AppInstancesConfig {
  [key: string]: {
    [key: string]: AppInstanceConfig;
  };
}
export interface AppInfo {
  appName: string;
  appInstancesName: string[];
}

export const Apps = {
  RSS_FEED: "RSS Feed",
  WEATHER: "Weather",
  NOTICEBOARD: "Noticeboard 2.0",
  NATURAL_WORLD_NEWS: "Natural World News",
  HEADLINE_NEWS: "Headline News",
  CLOCK: "Clock",
  COUNTDOWN_TIMER: "Countdown Timer",
  ENTERTAINMENT_AND_LIFESTYLE_NEWS: "Entertainment & Lifestyle News",
  ON_THIS_DAY: "On This Day",
  SOCCER_SCORES: "Soccer Scores",
  COUNT_UP_TIMER: "Count Up Timer",
  CANVAS: "Canvas",
  SPORTS_LIVE_SCORES: "Sports Live Scores",
  STOCKS: isDevelopment ? "Stocks" : "Stocks & Exchanges",
};

export enum AppInstances {
  EXAMPLE_NOTICE_1 = "Example: Notice 1",
  EXAMPLE_NOTICE_2 = "Example: Notice 2",
  EXAMPLE_NOTICE_3 = "Example: Notice 3",
  PREMIER_LEAUGE_APP = "Premier League App",
  SMITHSONIAN = "Smithsonian",
  CNN_NEWS = "CNN News",
  GOOGLE_BUSINESS = "Google Business",
  LOCAL_TIME = "Local Time",
  WEATHER_INSTANCE = "Weather Instance",
  EXAMPLE_COUNTDOWN = "Example Countdown",
  LIFESTYLE_AND_ENTERTAINMENT = "Reuters - Lifestyle & Entertainment",
  PITCHFORK_NEWS = "Pitchfork News",
  BBC_ON_THIS_DAY = "BBC: On this day...",
  MANUFACTURING_TODAY = "News: Manufacturing Today",
  CBS_SPORT_BOXING = "CBS Sport: Boxing",
  CBS_SPORT_BASKETBALL = "CBS Sport: Basketball",
  CBS_SPORT_NBA = "CBS Sport: NBA",
  CBS_SPORT_NFL = "CBS Sport: NFL",
  CBS_SPORT_TENNIS = "CBS Sport: Tennis",
  CBS_SPORT_SOCCER = "CBS Sport: Soccer",
  CBS_SPORT_COLLEGE_FOOTBALL = "CBS Sport: College Football",
  CBS_SPORT_NHL = "CBS Sport: NHL",
  CBS_SPORT_GOLF = "CBS Sport: Golf",
  CBS_SPORT_WWE = "CBS Sport: WWE",
  CBS_SPORT_MMA = "CBS Sport: MMA",
  CBS_SPORT_MLB = "CBS Sport: MLB",
  DAYS_SINCE_ACCIDENTS = "Days since accidents",
  MLB_SCORES = "MLB Scores",
  STOCKS = "Stocks",
}

export const getAppWithInstances = (
  countryCode: string
): AppInstancesConfig => {
  const country = Countries[countryCode] || "United Kingdom";

  return {
    [Apps.RSS_FEED]: {
      [AppInstances.CBS_SPORT_BOXING]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/boxing/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_BASKETBALL]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/college-basketball/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_NBA]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/nba/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_NFL]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/nfl/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_TENNIS]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/tennis/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_SOCCER]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/soccer/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_COLLEGE_FOOTBALL]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/college-football/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_NHL]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/nhl/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_GOLF]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/golf/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_WWE]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/wwe/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_MMA]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/mma/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.CBS_SPORT_MLB]: {
        version: "2.0.8",
        config: {
          url: "https://www.cbssports.com/rss/headlines/mlb/",
          delay: 10,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
      [AppInstances.MANUFACTURING_TODAY]: {
        version: "2.0.8",
        config: {
          url: "https://manufacturing-today.com/feed/",
          delay: 15,
          embedly: true,
          imageFill: true,
          muteVideos: true,
          videoEnabled: true,
          alfieDisabled: false,
          displayImageOnly: false,
          playVideoFullLength: true,
        },
      },
    },
    [Apps.WEATHER]: {
      [AppInstances.WEATHER_INSTANCE]: {
        version: "2.0.8",
        config: {
          lang: "en",
          unit: "c",
          ttlayout: "advanced",
          location1: country,
          screenLocation: false,
        },
      },
    },
    [Apps.NOTICEBOARD]: {
      [AppInstances.EXAMPLE_NOTICE_1]: {
        version: "2.0.8",
        config: {
          bgImage: "",
          iconName: "noIcon",
          bgOverlay: true,
          layoutSide: "Left",
          bgWhiteText: true,
          manualOrAuto: "Auto",
          themeSelected: "SCTheme",
          noticeboardText:
            "<p>SUMMER SOCIAL: August 25th @ 7pm, Ryandale Steakhouse</p>",
          themeColorVariety: "",
          themeColorVarietys: [],
        },
      },

      [AppInstances.EXAMPLE_NOTICE_2]: {
        version: "2.0.8",
        config: {
          bgImage: "",
          iconName: "noIcon",
          bgOverlay: true,
          layoutSide: "Left",
          bgWhiteText: true,
          manualOrAuto: "Auto",
          themeSelected: "SCTheme",
          noticeboardText:
            "<p>FIRE SAFETY TRAINING: September 12th 9.30am - 3.30pm</p><p>Lunch will be provided. </p>",
          themeColorVariety: "",
          themeColorVarietys: [],
        },
      },

      [AppInstances.EXAMPLE_NOTICE_3]: {
        version: "2.0.8",
        config: {
          bgImage: "",
          iconName: "warning",
          bgOverlay: true,
          layoutSide: "Left",
          bgWhiteText: true,
          manualOrAuto: "Auto",
          themeSelected: "SCTheme",
          noticeboardText:
            "<p><strong>REMINDER </strong></p><p>A health &amp; safety inspection of this area will be conducted on July 24th at 3pm.</p><p><br></p><p>A H&amp;S officer will organise a meeting with each team prior to this, followed by an action plan for all.</p><p><br></p><p>Thank you.</p>",
          themeColorVariety: "none",
          themeColorVarietys: [],
        },
      },
    },
    [Apps.NATURAL_WORLD_NEWS]: {
      [AppInstances.SMITHSONIAN]: {
        version: "1.0.0",
        config: {
          url: "https://www.smithsonianmag.com/rss/latest_articles",
          delay: 8,
          imageFill: true,
          displayImageOnly: false,
        },
      },
    },
    [Apps.HEADLINE_NEWS]: {
      [AppInstances.CNN_NEWS]: {
        version: "1.0.0",
        config: {
          delay: 10,
          source: {
            url_cnn: "http://rss.cnn.com/rss/edition.rss",
            source_options: "cnn",
          },
          imageFill: true,
          alfieDisabled: false,
          url_aljazeera: "http://www.aljazeera.com/xml/rss/all.xml",
        },
      },

      [AppInstances.GOOGLE_BUSINESS]: {
        version: "1.0.0",
        config: {
          delay: 20,
          source: {
            url_cnn: "http://rss.cnn.com/rss/edition.rss",
            source_options: "googlenews",
            url_googlenews:
              "https://news.google.com/news/rss/headlines/section/topic/BUSINESS?ned=us&hl=en&gl=US",
          },
          imageFill: true,
          alfieDisabled: false,
          url_aljazeera: "http://www.aljazeera.com/xml/rss/all.xml",
        },
      },
    },
    [Apps.CLOCK]: {
      [AppInstances.LOCAL_TIME]: {
        version: "2.0.8",
        config: {
          ampm: getOrgRegionFromUrl().isUs,
          date: false,
          lang: "en",
          style: "simple",
          second: false,
          address: country,
        },
      },
    },
    [Apps.COUNTDOWN_TIMER]: {
      [AppInstances.EXAMPLE_COUNTDOWN]: {
        version: "1.0.0",
        config: {
          icon: "smile",
          lang: "en",
          title: "End of year inspection. Let's hit our target!",
          date_day: 15,
          date_year: 2023,
          date_month: 12,
          display_type: {
            date_layout: "am",
            show_hours_minutes: "dhm",
            display_type_options: "minimal",
          },
          finished_message:
            "Thank you everyone for preparing for this inspection. Starting tomorrow it will run until Thursday. ",
        },
      },
    },
    [Apps.ENTERTAINMENT_AND_LIFESTYLE_NEWS]: {
      [AppInstances.LIFESTYLE_AND_ENTERTAINMENT]: {
        version: "1.0.0",
        config: {
          url:
            "https://www.reutersagency.com/feed/?best-topics=lifestyle-entertainment&post_type=best",
          delay: 10,
          imageFill: true,
          displayImageOnly: false,
        },
      },
      [AppInstances.PITCHFORK_NEWS]: {
        version: "1.0.0",
        config: {
          url: "https://pitchfork.com/feed/feed-news/rss",
          delay: 8,
          imageFill: true,
          displayImageOnly: false,
        },
      },
    },
    [Apps.ON_THIS_DAY]: {
      [AppInstances.BBC_ON_THIS_DAY]: {
        version: "1.0.0",
        config: {
          url: "http://news.bbc.co.uk/rss/on_this_day/front_page/rss.xml",
          delay: 8,
          imageFill: true,
          displayImageOnly: false,
        },
      },
    },
    [Apps.SOCCER_SCORES]: {
      [AppInstances.PREMIER_LEAUGE_APP]: {
        version: "1.0.0",
        config: {
          lang: "en",
          delay: 5,
          sports: "soccer-epl",
        },
      },
    },
    [Apps.SPORTS_LIVE_SCORES]: {
      [AppInstances.MLB_SCORES]: {
        version: "stable",
        config: {
          lang: "en",
          delay: 10,
          sports: "mlb",
        },
      },
    },
    [Apps.COUNT_UP_TIMER]: {
      [AppInstances.DAYS_SINCE_ACCIDENTS]: {
        version: "2.0.9",
        config: {
          style: "Minimal",
          title: "Days without accidents",
          display_type: {
            nowDateConfig: {
              date_day: 2,
              date_year: 2022,
              time_hour: 17,
              date_month: 2,
              time_minute: 1,
            },
            display_type_options: "now",
          },
        },
      },
    },
    [Apps.STOCKS]: {
      [AppInstances.STOCKS]: {
        version: "2.0.8",
        config: {
          symbol: "googl, ftse, aapl, btc/usd, amzn, tsla, pypl, msft",
        },
      },
    },
  };
};
