import { Icon, Popover } from "@screencloud/screencloud-ui-components";
import { FormattedMessage } from "react-intl";
import { AppContextState } from "../AppContextProvider/type";
import {
  customScreenLocationKey,
  RefType,
  TouchKey,
} from "../constants/constants";
import { FEATURE_FLAGS_ENUM } from "../constants/featureFlag";
import {
  Channel,
  Org,
  Scalars,
  ScreenDetailQuery,
  ScreenStatus,
  Screen,
  Playlist,
} from "../types.g";
import { getChannelContentByOwner, getChannelName } from "./channelHelper";
import { IChannelZone } from "@screencloud/signage-player";
import { ContentItem } from "../components/ScreenContentPicker";
import { ScreenOperatingType } from "../components/OperatingHours";
import { getCurrentUserInterfaceVisibilitiesByContext } from "src/userInterfaceVisibility/useUserInterfaceVisibilities";

export const getScreenStatus = (screen) => {
  let status;
  if ("isConnected" in screen) {
    if (screen.isConnected) {
      status = screen.status;
    } else if (screen.status === ScreenStatus.Paused) {
      status = ScreenStatus.Paused;
    } else {
      status = "offline";
    }
  } else {
    status = "unknown";
  }
  return status.toLowerCase();
};

export const getScreenContentItem = (
  screen: ScreenDetailQuery["screenById"],
  context: AppContextState
): ContentItem | undefined => {
  if (screen?.content?.href) {
    return {
      id: screen?.content?.href,
      name: "External Content",
      type: RefType.HREF,
    };
  }

  if (screen?.content?._ref) {
    let screenContent: ContentItem | undefined;

    switch (screen.content._ref.type) {
      case RefType.CHANNEL:
        const hasChannel = screen?.channelsByScreenId?.nodes?.length > 0;
        const channelContent = screen?.channelsByScreenId?.nodes[0];
        const channecontentForBadgeIcon = getChannelContentByOwner({
          context,
          channel: channelContent,
        });
        screenContent = {
          id: screen.content._ref.id,
          name: hasChannel
            ? getChannelName(channelContent, context.currentOrg as Org)
            : "Channel",
          type: RefType.CHANNEL,
          badgeIcon: getBadgeIconByIdAndType(
            RefType.CHANNEL,
            channecontentForBadgeIcon,
            context
          ),
        };
        break;
      case RefType.APP_INSTANCE:
      case RefType.APP:
        const hasApp = screen?.appInstancesByScreenId?.nodes?.length > 0;
        screenContent = {
          id: screen.content._ref.id,
          name: hasApp
            ? (screen.appInstancesByScreenId.nodes[0].name as string)
            : "App",
          type: RefType.APP,
        };
        break;
      case RefType.FILE:
        const hasFile = screen?.filesByScreenId?.nodes?.length > 0;
        screenContent = {
          id: screen.content._ref.id,
          name: hasFile
            ? (screen.filesByScreenId.nodes[0].name as string)
            : "File",
          type: RefType.FILE,
          mimeType: hasFile
            ? screen.filesByScreenId.nodes[0].mimetype || undefined
            : "application",
        };
        break;
      case RefType.LINK:
        const hasLink = screen?.linksByScreenId?.nodes?.length > 0;
        screenContent = {
          id: screen.content._ref.id,
          name: hasLink
            ? (screen.linksByScreenId.nodes[0].name as string)
            : "Link",
          type: RefType.LINK,
        };
        break;
      case RefType.PLAYLIST:
        const hasPlaylist = screen?.playlistsByScreenId?.nodes?.length > 0;
        const playlistContent = screen?.playlistsByScreenId?.nodes[0];
        screenContent = {
          id: screen.content._ref.id,
          name: hasPlaylist ? (playlistContent.name as string) : "Playlist",
          type: RefType.PLAYLIST,
          badgeIcon: getBadgeIconByIdAndType(
            RefType.PLAYLIST,
            playlistContent,
            context
          ),
        };
        break;
      case RefType.SITE:
        screenContent =
          screen?.sitesByScreenId?.nodes?.length > 0
            ? {
                id: screen.sitesByScreenId.nodes[0].id,
                name: screen.sitesByScreenId.nodes[0].name as string,
                type: RefType.SITE,
              }
            : undefined;
        break;
      default:
        screenContent = undefined;
        break;
    }
    return screenContent;
  } else {
    return undefined;
  }
};

export const getBadgeIconByIdAndType = (
  type: RefType,
  contentData: any,
  context: AppContextState
) => {
  let badgeIcon;
  switch (type) {
    case RefType.PLAYLIST:
      if (!contentData) {
        return null;
      }
      badgeIcon = shouldShowPublishPlaylistWarningMessage(
        contentData,
        context
      ) && (
        <Popover
          inverted
          position="top center"
          content={
            <FormattedMessage
              id="common.text.draft_content_message"
              defaultMessage="Changes have been made. Please publish to play the latest changes on your screens."
            />
          }
          trigger={<Icon name="warning" />}
        />
      );
      break;
    case RefType.CHANNEL:
      if (!contentData) {
        return null;
      }
      badgeIcon = shouldShowPublishChannelWarningMessage(
        contentData,
        context
      ) ? (
        <Popover
          inverted
          position="top center"
          content={
            <span>
              <FormattedMessage
                id="common.text.draft_content_message"
                defaultMessage="Changes have been made. Please publish to play the latest changes on your screens."
              />
            </span>
          }
          trigger={<Icon name="warning" />}
        />
      ) : (
        isEmptyChannel(contentData) &&
        contentData?.content?._ref?.id !== context.currentOrg?.blankChannelId &&
        contentData?.content?._ref?.id !==
          context.currentOrg?.brandChannelId && (
          <Popover
            inverted
            position="top center"
            content={
              <span>
                <FormattedMessage
                  id="ui_component.channels_empty"
                  defaultMessage="Channel is Empty"
                />
              </span>
            }
            trigger={<Icon name="warning" />}
          />
        )
      );
      break;
    default:
      badgeIcon = null;
      break;
  }
  return badgeIcon;
};

export const isEmptyChannel = (channel: Channel) => {
  if (channel?.content?.zones) {
    return (
      (Object.values(channel.content.zones) as IChannelZone[]) ?? []
    ).every((zone) => (zone.list?.length ?? 0) === 0);
  } else {
    return true;
  }
};

export const shouldShowPublishChannelWarningMessage = (
  channel: Channel,
  context: AppContextState
) => {
  // Some old data, blankchannel and brandchannel might have same space as default space so we need to check for exact id
  if (
    channel.id === context.currentOrg!.blankChannelId ||
    channel.id === context.currentOrg!.brandChannelId ||
    context.user.settings.spaceId !== channel.spaceId
  ) {
    return false;
  }
  return (
    !channel.isPublished &&
    context.currentPermissions.validateCurrentSpace("channel", "publish")
  );
};

export const shouldShowPublishPlaylistWarningMessage = (
  playlist: Playlist,
  context: AppContextState
) => {
  if (context.user.settings.spaceId !== playlist.spaceId) {
    return false;
  }

  return (
    !playlist.draft?.isPublished &&
    context.currentPermissions.validateCurrentSpace("playlist", "publish")
  );
};

export const filterVisibleRefTypes = (
  refTypes: RefType[],
  context: AppContextState
): RefType[] => {
  const { currentPermissions } = context;
  const { mediaPicker } = getCurrentUserInterfaceVisibilitiesByContext(context);

  const keepRefTypeDict: Record<RefType, boolean> = {
    [RefType.RECENT]: true,
    [RefType.CHANNEL]: currentPermissions.validateCurrentSpace(
      "channel",
      "read"
    ),
    [RefType.PLAYLIST]: currentPermissions.validateCurrentSpace(
      "playlist",
      "read"
    ),
    [RefType.FILE]: currentPermissions.validateCurrentSpace("media", "read"),
    [RefType.LINK]: mediaPicker.isShowLink,
    [RefType.SITE]: mediaPicker.isShowDashboard,
    [RefType.APP]: currentPermissions.validateCurrentSpace(
      "app_instance",
      "read"
    ),
    [RefType.APP_INSTANCE]: false, // same as app
    [RefType.CANVAS]: currentPermissions.validateCurrentSpace(
      "app_instance",
      "read"
    ),
    [RefType.DOCUMENT]: false, // same as file
    [RefType.HREF]: false,
  };
  return refTypes.filter((rf) => keepRefTypeDict[rf] || false) as RefType[];
};

export const getDataTableFiltered = (env: Scalars["JSON"] = {}) => {
  if (!env) {
    return;
  }
  const filtered = Object.keys(env)
    .filter(
      (key) => !customScreenLocationKey.includes(key) && !TouchKey.includes(key)
    )
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: env[key],
      };
    }, {});
  return filtered;
};

export function canCast(context: AppContextState) {
  return (
    context.currentPermissions.validateCurrentSpace("screen", "cast") &&
    context.shouldShowFeature(FEATURE_FLAGS_ENUM.CASTING)
  );
}

export function canSetContent(context: AppContextState) {
  return context.currentPermissions.validateCurrentSpace("screen", "content");
}

export function updatePreferences(
  preferences: ScreenOperatingType,
  newPreferences: {
    [x: string]: any;
  }
) {
  return {
    ...preferences,
    ...newPreferences,
  };
}

export const getMuteOptionMessage = (isMuted: boolean): JSX.Element => {
  return (
    <>
      <Icon
        data-testid={isMuted ? "audio-on" : "audio-off"}
        name={isMuted ? "audio-on" : "audio-off"}
      />{" "}
      {isMuted ? (
        <FormattedMessage
          id="ui_component.common.label.unmute"
          defaultMessage="Unmute"
        />
      ) : (
        <FormattedMessage
          id="ui_component.common.label.muted"
          defaultMessage="Mute"
        />
      )}
    </>
  );
};

export const getScreenTimeZoneOverride = (screen: Screen): string => {
  const timezoneOverride = screen?.timezoneOverride || screen?.playerTimezone;

  return timezoneOverride;
};
