import { useCallback, useContext, useEffect, useReducer } from "react";
import { FormattedMessage } from "react-intl";
import { Maybe, Site } from "../../../types.g";
import { SiteContext } from "../sitecontext";
import { SiteRecorderAction } from "./actions/models";
import { ActionsControl } from "./actionscontrol";
import Layout from "./layout";
import { NavigationControl } from "./navigationcontrol";
import { InitialRecorderState, RecorderReducer } from "./reducers";
import { SiteFrame } from "./siteframe";

export interface SiteRecorderProps {
  onSiteConfigurationClick: () => void;
  site?: Maybe<Partial<Site>>;
  onRecordingUpdated: (actions: SiteRecorderAction[]) => void;
  setDashboardTitle: (string) => void;
  dashboardTitle: string;
  setExtensionVersion: (string) => void;
}

export const SiteRecorder = (props: SiteRecorderProps) => {
  const [state, dispatch] = useReducer(RecorderReducer, {
    ...InitialRecorderState,
    url: props.site?.url || "",
    dashboardTitle: props.dashboardTitle,
  });
  const siteContext = useContext(SiteContext);

  useEffect(() => {
    props.onRecordingUpdated(state.actions);
  }, [state.actions]);

  useEffect(() => {
    if (props.site) {
      siteContext.setIsPreview();
    }
  }, [props.site]);

  useEffect(() => {
    // Update to take locale into account
    const defaultDashboardTitle = "Dashboard";
    if (props.dashboardTitle === defaultDashboardTitle) {
      props.setDashboardTitle(state.dashboardTitle);
    }
  }, [state.dashboardTitle]);

  useEffect(() => {
    props.setExtensionVersion(state.extensionVersion);
  }, [state.extensionVersion]);

  const navigationHandler = useCallback(() => {
    console.log("navigated");
  }, [state]);

  return (
    <Layout>
      <Layout.Actions>
        <div className="overall-actions">
          <div className="header">
            <FormattedMessage
              id="ui_component.site.recorder.actions"
              defaultMessage="Actions"
            />
          </div>
        </div>
      </Layout.Actions>
      <Layout.Navigator>
        <NavigationControl
          url={state.url}
          recorderUrl={state.recorderUrl}
          mode={state.navigationMode}
          frameWidth={state.frameWidthType}
          dispatch={dispatch}
          onNavigate={navigationHandler}
          isRecording={state.isRecording}
          hasActions={!!state.actions.length}
        />
      </Layout.Navigator>
      <Layout.Controls>
        <ActionsControl
          actions={state.actions}
          isSelectingElement={state.isSelectingElement}
          isRecording={state.isRecording}
          selectedElement={state.selectedElement}
          editorType={state.editorType}
          editorAction={state.editorAction}
          dispatch={dispatch}
        />
      </Layout.Controls>
      <Layout.Pane>
        <SiteFrame
          url={state.url}
          mode={state.navigationMode}
          isRecording={state.isRecording}
          isSelectingElement={state.isSelectingElement}
          selectedElement={state.selectedElement}
          editorType={state.editorType}
          editorAction={state.editorAction}
          dispatch={dispatch}
          actions={state.actions}
          hasOtc={state.hasOtc}
          navigateCount={state.navigateCount}
          site={props.site}
        />
      </Layout.Pane>
    </Layout>
  );
};
