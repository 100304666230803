import { Theme } from "@screencloud/screencloud-ui-components";
import icon1 from "src/images/fspicker/laptop.svg";
import icon2 from "src/images/fspicker/link.svg";
import icon3 from "src/images/fspicker/unsplash.svg";
import icon4 from "src/images/fspicker/gdrive.svg";
import icon5 from "src/images/fspicker/onedrive.svg";
import icon6 from "src/images/fspicker/box.svg";
import icon7 from "src/images/fspicker/dropbox.svg";
import icon8 from "src/images/fspicker/unsplash-white.svg";
import styled, { createGlobalStyle } from "styled-components";
import { WhiteLabelStyles } from "src/helpers/whiteLabel";

// noinspection TsLint
export const GlobalMainStyles = createGlobalStyle<WhiteLabelStyles>`
  :root {
    --color-primary-background: ${(props) =>
      props.activeItemColor ?? `var(--color-yellow-500)`};
    --color-primary-background-active: ${(props) =>
      props.bgColor ?? `var(--color-yellow-400)`};
    --color-primary-foreground: ${(props) =>
      props.activeTextColor ?? `var(--color-gray-700)`};
    --color-primary-foreground-active: ${(props) =>
      props.textColor ?? `var(--color-gray-950)`};
  }

  /********** General **********/
  body {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,'Sora',sans-serif;
    background: var(--color-gray-50);
    height: 100%;
    min-height: 100%;
    min-width: 375px;
  }

  .pac-container {
    position: relative;
    z-index: 9999 !important;
  }

  .item-list-visibility {
    height: 80px;
    display: block;
  }

  .card-item {
    &.app-item.selected {
      background-color: ${Theme.color.grey30};
    }
    &.app-grid-item.selected {
      .grid-board {
        :before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: var(--radius-lg);
          box-shadow: inset 0 0 0 4px var(--color-primary-background);
          z-index: 1;
        }
      }
    }
  }

  .blocker {
    display: none;
  }

  *:focus {
    outline: none;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  #root,
  .App {
    height: 100%;
  }

  .App {
    overflow: hidden;
    display: flex;
    flex-direction: row;
  }

  p {
    strong, b {
      color: ${Theme.color.grey100};
    }
  }

  .main-content {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .flex {
    display: flex;
  }

  hr {
    margin: 20px 0;
    border-top: 1px solid ${Theme.color.lightGrey};
    width: 100%;
    box-sizing: border-box;
  }

  /********** Modal **********/
  .modal-wrapper:empty {
    display: none;
  }

  .modal-wrapper:not(:empty) {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2001;
    width: 100%;
    height: 100%;

    .md-modal {
      .md-container {
        .md-modal.md-show {
          .md-wrapper {
            .md-title {
              h3 {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: var(--spacing-xxl);
              }
            }
            .md-content {
              .md-footer {
                &.flex-end {
                  justify-content: flex-end;
                }
                &.space-around{
                  justify-content: space-around;
                }
                &.center{
                  justify-content: center;
                }
                &.h-100{
                  height: 100px;
                }
              }
            }
          }
        }
        .md-overlay {
        }
      }
    }
  }

  .md-embed-player {
    z-index: 1;
  }

  .onboarding-modal.md-parent-show {
    .md-container .md-modal {
      .md-wrapper .md-content {
        border-radius: 36px;
      }
    }

    &.onboarding-preview-screen {
      .md-container .md-modal {
        width: 100%;
        height: 100%;
        max-width: 1280px;
        max-height: 800px;
      }
    }
  }

  .md-modal.enrollment-token-modal .md-content > div {
    max-height: 740px;
    overflow-y: auto;
  }

  .md-parent-show .md-container .md-modal .md-wrapper .md-content:has(.note) {
    height: auto;
  }

  // Override border-radius for device info modal

  .device-info-modal.md-parent-show .md-container .md-modal .md-wrapper {
    .md-no-title {
      .btn-close {
        z-index: 2;
      }
    }
    .md-content {
      border-radius: var(--radius-lg, 16px);
      overflow: hidden;
    }
  }

  .device-info-modal.md-parent-show .md-container .md-modal {
    height: 828px;
  }

  p.text-warning {
    color: var(--color-orange-600);
  }


  @media (max-width: ${Theme.break.small}) {
    .App {
      flex-direction: column;
    }
    .md-open {
      #root {
        > div {
          height: 100%;
        }

        .modal-wrapper {
          :not(:empty) {
            height: 100%;

            + .App {
              display: none;
            }
          }
        }
      }
    }
  }

  /********** Main pages **********/
  /* #header-main {
    position: fixed;
    width: calc(100% - 240px);
    top: 0;

    + .container {
      overflow: unset;
      padding-top: 90px;
      height: 100%;
    }

    @media screen and (max-width: ${Theme.break.largeHD}) {
      width: calc(100% - 70px);
      .org-draft {
        margin-left: ${Theme.sidebar.minWidth};
      }
    }

    @media screen and (max-width: ${Theme.break.small}) {
      width: 100%;
      top: 48px;

      + .container {
        padding: 20px 15px 85px;
        height: calc(100% - 66px);
      }
    }
  } */

  .key-label {
    color: var(--color-gray-100);
    border: 1px solid var(--color-gray-100);
    padding: 2px 5px;
    ${Theme.default.borderRadius};
    font-size: 0.875em;
    font-weight: bold;
    margin: 0 2px;
    background-color: rgba(255,255,255,0.05);
  }

  .org-draft {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Theme.color.warning};
    padding: 10px;
    color: var(--color-white-100);
    padding-left: ${Theme.sidebar.maxWidth};

    div {
      margin-right:10px;
    }
    .button {
      height:25px;
    }

    &.inspector {
      justify-content: flex-end;
      background: ${Theme.color.red};
      padding: 0;

      span {
        display: block;
        font-size: 0.75em;
        line-height: 1;
      }

      button {
        background: ${Theme.color.redHover};
        border: none;
        font-size: 0.75em;
        height: 20px;
        cursor: pointer;
        margin-left: 5px;
        color: var(--color-white-100);
        font-weight: bold;
        line-height: 1;
        width: 48px;
        padding: 0;

        :hover {
          background: ${Theme.color.black};
        }
      }
    }
  }

  @media screen and (max-width: ${Theme.break.largeHD}) {
    .org-draft {
      padding-left: ${Theme.sidebar.minWidth};
    }
  }

  .group-label {
    padding: 20px 0px 10px 0px;
    border-bottom: 1px solid ${Theme.color.lightGrey};
    color: ${Theme.color.grey};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-top: 0;
  }

  .md-small.permission-modal {
    z-index: 2005;
  }

  .md-small.md-confirmation {
    z-index: 2010;
  }

  .ui.button.button-add-permission {
    width: 18px;
    height: 18px;
    padding: 0;
    border: none;
    box-shadow: inset 0 0 0 2px ${Theme.color.grey};
    border-radius: 10px;
    margin: 3px;

    i {
      position: relative;
      margin: 0;
      width: 8px;
      height: 8px;
      background-color: transparent;

      :after {
        display: block;
        width: 8px;
        height: 2px;
        content: '';
        margin: 0;
        background: ${Theme.color.grey};
        border-radius: 2px;
        position: absolute;
        top: 3px;
      }

      :before {
        content: '';
        width: 2px;
        height: 8px;
        background: ${Theme.color.grey};
        display: block;
        left: 3px;
        position: absolute;
        border-radius: 2px;
      }
    }

    :hover {
      height: 18px;
      width: 18px;
      background-color: ${Theme.color.darkGrey};
      box-shadow: none;

      i {
        :after {
          background: var(--color-white-100);
        }

        :before {
          background: var(--color-white-100);
        }
      }
    }
  }

  .row-checkbox {
    width: 100%;
    margin: 20px 0;

    .label {
      flex-direction: column;
      display: inline-flex;
      width: calc(100% - 60px);

      h3 {
        margin-bottom: 0;
      }
    }

    span {
      font-size: 14px;
      color: ${Theme.color.grey};
    }
  }

  /*** Component: Subheader */
  .subheader {
    ${Theme.subheader.styled}

    i {
      color: ${Theme.color.grey}
    }

    .ui.dropdown {
      .menu {
        .item {
          span {
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0;
            height: auto;
          }
          &.active {
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  /*** Component: File Picker ***/
  .onboarding-upload {
    #droppane {
      #filepicker {
        min-height: 280px;

        .fsp-picker {
          background: transparent;
        }
        .fsp-modal {
          background: #fff;
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          padding: 10px;
          border-radius: 16px;
          transition: none;
          border: 1px solid var(--color-blue30);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

          .fsp-modal__sidebar {
            position: relative;
            width: auto;
            background: #fff;
            display: flex;
            transition: none;
            flex-shrink: 0;
            top: 0;

            :hover {
              box-shadow: none;
            }

            .fsp-source-list {
              display: flex;
              flex-direction: row;
              width: auto;
              justify-content: center;
              background: var(--color-grey20);
              box-shadow: inset 0 0 0 1px var(--color-grey40);
              border-radius: 10px;

              .fsp-source-list__item {
                width: auto;
                padding: 10px 15px;
                border-radius: 10px;
                gap: 10px;

                .fsp-source-list__label {
                  display: none;
                }

                .fsp-source-list__logout {
                  position: relative;
                  top: auto;
                  right: auto;
                  transform: none;
                  color: #19191b;
                }

                .fsp-source-list__icon {
                  margin: 0;
                  width: 24px;
                  height: 24px;

                  &.fsp-icon--local_file_system {
                    background-image: none;
                    background-color: #19191b;
                    mask: url(${icon1}) center center no-repeat;
                    mask-size: contain;
                    width: 24px;
                    height: 24px;
                  }
                  &.fsp-icon--unsplash {
                    background: url(${icon3}) no-repeat;
                    border-radius: 0;
                  }
                  &.fsp-icon--url {
                    background-image: none;
                    background-color: #19191B;
                    mask: url(${icon2}) center center no-repeat;
                    mask-size: contain;
                    width: 24px;
                    height: 24px;
                  }
                  &.fsp-icon--googledrive {
                    background: url(${icon4}) no-repeat;
                  }
                  &.fsp-icon--onedrive {
                    background: url(${icon5}) no-repeat;
                  }
                  &.fsp-icon--box {
                    background: url(${icon6}) no-repeat;
                  }
                  &.fsp-icon--dropbox {
                    background: url(${icon7}) no-repeat;
                  }
                }

                :hover {
                  background: var(--color-grey40);

                  .fsp-source-list__icon {
                    &.fsp-icon--local_file_system,
                    &.fsp-icon--url {
                      background-color: #19191B;
                    }
                  }
                }

                &--active {
                  background: var(--color-blue120);

                  .fsp-source-list__icon {
                    &.fsp-icon--local_file_system,
                    &.fsp-icon--url {
                      background-color: #FFF;
                    }
                    &.fsp-icon--unsplash {
                      background: url(${icon8}) no-repeat;
                    }
                  }

                  .fsp-source-list__logout {
                    color: #fff;
                  }

                  :hover {
                    background: var(--color-grey100);

                    .fsp-source-list__icon {
                      &.fsp-icon--local_file_system,
                      &.fsp-icon--url {
                        background-color: #FFF;
                      }
                      &.fsp-icon--unsplash {
                        background: url(${icon8}) no-repeat;
                      }
                    }
                  }
                }
              }
              @media screen and (max-width: ${Theme.break.small}) {
                .fsp-source-list__item {
                  padding: 10px 12px;
                }
              }
            }
          }

          .fsp-modal__body {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 10px;

            .fsp-content {
              .fsp-drop-area-container {
                padding: 0;

                .fsp-drop-area {
                  border: 3px dashed var(--color-blue50);
                  background: var(--color-blue20);
                }
              }

              .fsp-content-scroll {
                overflow: auto;
              }

              .fsp-summary {
                padding: 0;

                .fsp-summary__header {
                  display: none;
                }

                .fsp-summary__body {
                  height: 214px;

                  &.fsp-summary__has-actions {
                    height: 145px;

                    @media screen and (max-width: ${Theme.break.small}) {
                      height: 221px;
                    }
                  }
                }
              }

              /*** Custom: Unsplash ***/

              .fsp-unsplash-source {
                height: 214px;
              }

              .fsp-unsplash-source__container {
                padding: 20px;

                .fsp-unsplash-title {
                  margin-bottom: 20px;
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: bold;

                  .fsp-unsplash-subtitle {
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--color-grey60);
                    font-weight: normal;
                    margin: 0;
                  }
                }

                .fsp-unsplash-logo {
                  display: none;
                }

                &.fsp-unsplash-is_filled {
                  padding: 10px;
                }
              }

              /*** Custom: Cloud Drive ***/

              .fsp-cloud__container {
                .fsp-icon {
                  display: none;
                }

                .fsp-text__subheader {
                  font-size: 12px;
                  line-height: 16px;
                  color: var(--color-grey60);
                }

                .fsp-source-auth__el {
                  margin-bottom: 10px;
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: bold;

                  &.fsp-text__title {
                    margin: 0;
                  }
                }

                .fsp-button--authgoogle {
                  margin-bottom: 10px;
                }

                .fsp-cloud__files-container {
                  height: 214px;
                }
              }
            }

            .fsp-footer {
              .fsp-footer__nav {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
              .fsp-footer__nav--left,
              .fsp-footer__nav--center,
              .fsp-footer__nav--right {
                line-height: unset;
                position: unset;
                float: none;

                div {
                  display: flex;
                  gap: 5px;
                }
                .fsp-button {
                  border-radius: 8px;
                  font-size: 14px;
                  padding-left: 12px;
                  padding-right: 12px;
                  height: 32px;
                  line-height: 30px;
                  font-family: 'Lato', sans-serif;
                  font-weight: bold;

                  > span {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .fsp-badge {

                    }
                  }
                }
              }
            }

            .fsp-select-labels {
              background-image: none;
              padding: 0;

              .fsp-drop-area__title.fsp-text__title {
                margin: 0;
              }
              .fsp-drop-area__subtitle.fsp-text__subheader {
                margin: 0;
              }

              :after {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  /*** Component: Popup ***/
  .ui.popup.tips {
    .note {
      padding: 10px 5px;
      width: 460px;
      max-width: 100%;

      h3 {
        font-size: 16px;
        margin: 0 0 10px;
      }

      > span {
        line-height: 1.2;
        color: ${Theme.color.lightGrey};
      }
    }
  }

  .ui.popup.schedule-popup {
    padding: 10px 12px;
    &.wide {
      max-width: 300px;
    }
    &.warning-inverted {
      background-color: ${Theme.color.red};
    }
    span {
      display: inline-block;
      font-size: 13px;
      &.warning {
        color: ${Theme.color.red};
        font-style: italic;
      }

      &.italic {
        color: ${Theme.color.grey};
        font-style: italic;
      }

      &.available-date {
        font-size: 16px;
      }
    }
  }

  .ui.popup.tags-popup {
    max-width: 360px;

    .ui.labels {
      flex-wrap: wrap;

      .ui.label:first-child {
        margin-left: 2px;
      }

      .ui.label:last-child {
        margin-right: 2px;
      }
    }
  }

  .ui.popup.menu-item,
  .ui.popup.pop-preview,
  .ui.popup.pop-casting {
    display: none !important;

    @media screen and (max-width: ${Theme.break.largeHD}) {
      display: block !important;
    }

    @media screen and (max-width: ${Theme.break.small}) {
      display: none !important;
    }
  }

  .ui.popup.playlist-item-thumbnail {
    padding: 0;

    img {
      display: block;
    }
  }

  .ui.popup.popover-notification-container {
    padding: 0;
    overflow: hidden;
    border: none;
    box-shadow: none;
  }

  .md-open {
    overflow: hidden;
  }

  /*** Fixed Post for Filter ***/
  .fixed-post {
    background-color: var(--color-gray-50);
    padding: var(--spacing-lg) 0;
    width: 100%;
    margin: 0;
    z-index: 3;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    :empty {
      display: none;
    }
  }

  /*** Component: Filter ***/
  .filter {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .break {
      height: 100%;
      display: inline-block;
      width: 1px;
      background-color: ${Theme.color.lightGrey};
      margin: 0 5px;
      flex-shrink: 0;
    }

    a {
      padding: 0 6px;
      color: var(--color-foreground);
      font-weight: bold;
      height: 32px;
      width: 32px;
      line-height: 32px;
      ${Theme.default.borderRadius};
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center;

      :hover {
        color: var(--color-foreground-active);
        background: var(--color-background-active);
      }
    }

    .ui.button,
    .ui.dropdown {
      &.inactive {
        cursor: not-allowed;
      }

      &.select-count {
        color: var(--color-foreground-active);
        cursor: default;
        height: var(--size-md, 32px);
        background: var(--color-grey30);
        border-radius: var(--radius-8008);
        justify-content: flex-end;
        padding: 0 4px;
        width: var(--size-md, 32px);

        i {
          background-color: var(--color-foreground-active);
        }

        + div {
          margin: 0;
          background: var(--color-grey30);
          border-radius: var(--radius-0880);
        }

        span {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    h2 {
      font-size: 1.25em;
      margin: 0;
    }

    .primary {
      display: flex;
      height: 100%;

      .ui.button {
        &.icon {
          :hover {
            background: var(--color-gray-100);
          }
        }
      }

      .protip {
        i {
          width: 20px;
          height: 20px;
          margin-bottom: 4px;
          margin-left: 0;
          background-color: var(--color-grey100);
        }

        i[name="bulb"] {
          display: none;
        }

        :hover {
          i[name="bulb"] {
            display: block;
          }

          i[name="bulb-off"] {
            display: none;
          }
        }
      }

      .ui.dropdown {
        &.media-dropdown {
          display: inline-flex;
          align-items: center;
          i {
            background-color: ${Theme.color.darkGrey};
          }
          .text {
            font-weight: bold;
            margin-left: 8px;
            order: 2;
          }
        }
      }
    }

    div.toggle-view {
      flex-shrink: 0;
      margin: 0;
    }

    .actions {
      display: flex;
    }

    :empty {
      display: none;
    }

    .no-edit {
      display: none;
    }

    .section-button-container {
      display: flex;
      gap: var(--spacing-xxs, 4px);
      align-items: center;

      .ui.breadcrumb {
        line-height: 24px;
      }

      .section-button {
        display: flex;
        height: var(--size-md, 32px);
        padding: var(--spacing-xs, 6px) var(--spacing-lg, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xxs, 4px);
        flex-shrink: 0;

        border-radius: var(--radius-lg, 16px);
        border: 1px solid var(--color-gray-100, #E2E3E5);
        background: var(--color-white-100, #FFF);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

        min-width: 64px;
        cursor: pointer;

        span {
          color: var(--color-foreground);
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

          &.counter {
            color: var(--color-gray-300, #A1A2A7);
          }
        }

        :hover {
          background: var(--color-background-active);

          span {
            color: var(--color-foreground-active);
          }
        }

        &.active {
          border: 1px solid var(--color-gray-950, #19191B);
          background: var(--color-gray-950, #19191B);
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

          span {
            color: var(--color-white-100);

            &.counter {
              color: var(--color-gray-300, #A1A2A7);
            }
          }

          :hover {
            background: var(--color-gray-950);

            span {
              color: var(--color-white-100);
            }
          }
        }
      }
    }
    .actions-container {
      display: flex;
      align-items: center;
      padding-right: 8px;
    }

    i {
      background-color: var(--color-foreground);
      width: 20px;
      height: 20px;
    }

    span.count  {
      font-size: 1em;
      font-weight: bold;
      margin-left: 7px;
    }

    @media only screen and (max-width: ${Theme.break.mediumAlt}) {
      .primary {
        .ui.dropdown {
          width: 32px;
          display: flex;
          justify-content: center;

          .text {
            display: none;
          }
        }
      }
    }


    @media only screen and (max-width: ${Theme.break.small}) {
      .section-button-container,
      .actions-container {
        width: 100%;
      }

      .actions-container {
        justify-content: flex-end;
      }

      .primary {
        .ui.button {
          width: 32px;
          padding: 0;

          span {
            display: none;
          }

          &.protip {
            display: none;
          }
        }

        .ui.dropdown {
          &.media-dropdown {
            ~ .break {
              display: block;
            }
          }
        }
      }
    }

    @media only screen and (max-width: ${Theme.break.tiny}) {
      .primary,
      .toggle-view-container {
        display: none;
      }

      .actions-container {
        padding-right: 0;
      }

      .actions {
        .ui.button {
          margin: 0;
        }
      }
    }
  }

  .custom-tab {
    padding: 0 60px;

    @media screen and (max-width: ${Theme.break.largeHD}) {
      padding: 0 40px;
    }
    @media screen and (max-width: ${Theme.break.small}) {
      padding: 0 20px;
    }
  }

  /*** Dimmer ***/

  .dimmer {
    background-color: rgba(0,0,0,.5) !important;
  }

  .ui.grid {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .ui.grid>.row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ui.grid>.column:not(.row),
  .ui.grid>.row>.column {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .ui[class*="left icon"].input>input {
    padding-left: 38px;
  }

  .ui.label>.close.icon, .ui.label>.delete.icon {
    width: 10px;
    height: 10px;
    display: inline-block;
    position: relative;
    margin: 1px 0 1px 8px;
  }

  .ui.label>.close.icon, .ui.label>.delete.icon:after,
  .ui.label>.close.icon, .ui.label>.delete.icon:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: #444 !important;
    border-radius: 40px;
    top: 50%;
  }

  .ui.label>.close.icon, .ui.label>.delete.icon:after {
    transform: rotate(45deg);
    right: 0;
  }

  .ui.label>.close.icon, .ui.label>.delete.icon:before {
    transform: rotate(-45deg);
    left: 0;
  }

  .ui.label:hover>.delete.icon {
    opacity: 1;
  }

  .ui.multiple.search.dropdown>input.search {
    margin: 5px 8px;
  }

  .ui.selection.dropdown {
    min-height: 38px;
  }

  .ui.dropdown .menu .selected.item, .ui.dropdown.selected {
    color: var(--color-gray-950);
  }

  .ui.dropdown {
    .menu {
      > .item {
        flex-wrap: nowrap;

        span {
          white-space: nowrap;
        }
      }
    }
  }

  .ui.input input {
    font-size: 1em;
  }

  .wrapper .column-search .ui.input input {
    padding-left: 45px;
  }

  .selected-screen-content {
    h3 {
      color: var(--color-gray-950);
    }
    span.screen-meta__group {
      margin-left: 0;
    }
  }

  .layout-editor-modal.md-fullscreen {
    z-index: 2002;

    .panel-wrapper {
      .panel-config {
        padding: 0;

        > div {
          width: 100%;
        }
      }
      .panel-preview {
        padding: 0;
      }
    }
  }

  .app-config-modal {
    .md-no-title.left-sided {
      .button {
        display: none;
      }
    }


    @media screen and (max-width: ${Theme.break.small}) {
      .md-no-title {
        &.left-sided {
          .button {
            height: 66px;
          }
        }
        .btn-close {
          display: none;
        }
      }
    }
  }

  .channel-cover-modal {
    .md-wrapper {
      height: 522px;
    }
  }

  .modal-wrapper {
    .onboarding-modal.md-show {
      .md-container {
        .md-modal {
          height: 720px;
        }
      }
    }


    .sc-store {
      .md-container {
        .md-modal {
          border-radius: 32px;
          overflow: hidden;

          .md-wrapper {
            .md-no-title {
              .btn-close {
                background: var(--color-white-100);
                top: 20px;
                right: 20px;
                border: 1px solid var(--color-grey70);
                border-radius: 100%;
                z-index: 2;

                :hover {
                  background: var(--color-grey100);
                  border: 1px solid var(--color-grey100);

                  i {
                    background-color: var(--color-white-100);
                  }
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: ${Theme.break.small}) {
        .md-container {
          .md-modal {
            border-radius: 0;
          }
        }
      }
    }
  }

  .md-fullscreen {
    z-index: 11;

    &.app-config-modal {
      &.md-parent-show {
        + .md-modal {
          &.md-themes {
            display: none;
          }
        }
      }
    }
  }

  .expandable-open, .expandable-closed {
    z-index: 2000000; /** This index is needed so it is higher than .modal-wrapper:not(:empty) z-index 2001 so it show over theme container #3884*/ Also it need to be higher than help dialogue
  }

  .shared-wrapper {
    width: 100%;
  }

  .embeddedMessagingConversationButtonWrapper {
    .embeddedMessagingConversationButton {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      bottom: 16px;
      right: 16px;
    }
  }

  /* Loader custom style as we have zenDesk widget */
  .ui.loader.active {
    bottom: -1px;
    right: 120px;
  }
  .ui.loader.active.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .embed-share-modal {
    .md-container{
      .md-wrapper {
        height: auto !important;
      }
    }
  }

  @media screen and (max-width: ${Theme.break.tiny}) {
    h1 {
      font-size: 1.75em;
    }

    h2 {
      font-size: 1.25em;
      line-height: 1.2;
    }

    .modal-wrapper {
      .embed-share-modal.md-parent-show.md-medium {
        .md-container {
          .md-modal {
            height: 720px;
            .space-list {
              display: none;
            }
          }
        }
      }
    }
  }

  /*** Admin Section ***/
  .App.Admin {
    background: var(--color-gray-50);
    flex-direction: column;
    display: flex;

    .container {
      max-width: 960px;
      margin: 0 auto 20px;
      padding: 40px 30px;
      background: var(--color-white-100);
      ${Theme.default.borderRadius};
      border: solid 1px ${Theme.color.lightGrey};
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

      &.ppc {
        padding: 0;
      }
      /* This temporary class for ppc container, to be updated for other admin setting sections */

      &:last-child { margin-bottom: 0 }
      &.relative { position: relative }
      &.danger {
        border-color: ${Theme.color.error};
        .section {
          padding: 12px 30px;
          border-bottom: solid 1px ${Theme.color.lightGrey};
          align-items: flex-start;
          display: flex;
          &:first-child {
            padding-top: 0;
            border-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
          }
          &-desc,
          &-action {
            flex-basis: 0;
          }
          &-desc {
            flex-grow: 1;
            .label {
              margin: 0 0 4px;
              font-size: 16px;
              font-weight: 700;
            }
          }
          &-action {
            margin-left: 12px;
            flex-grow: 0;
          }
        }
      }
      &.no-horizontal-padding {
        padding-top: 0;
        padding-bottom: 0;
      }
      &.no-vertical-padding {
        padding-left: 0;
        padding-right: 0;

        > div > .title {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    //
    // Responsive
    //
    @media (max-width: ${Theme.break.mediumAlt}) {}
    @media (max-width: ${Theme.break.small}) {
      .container {
        border-radius: 0;
        border: 0;
        border-bottom: solid 1px ${Theme.color.lightGrey};
        box-shadow: none;
        padding: 18px ${Theme.admin.mobileVerticalPadding};
        &:last-child {
          border-bottom: 0;
        }
        &.no-vertical-padding > div > .title {
          padding-left: ${Theme.admin.mobileVerticalPadding};
          padding-right: ${Theme.admin.mobileVerticalPadding};
        }
        &.danger {
          .section {
            padding-left: ${Theme.admin.mobileVerticalPadding};
            padding-right: ${Theme.admin.mobileVerticalPadding};
            align-items: normal;
            flex-direction: column;
            &-desc,
            &-action {
              flex-grow: 1;
              flex-basis: auto;
            }
            &-desc {
              margin: 0 0 12px;
            }
            &-action {
              margin-left: 0;
              .button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .option-visible {
    padding-top: 70px;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    .options {
      .selected-screen {
        align-items: center;
        display: inline-flex;
        margin: 0;
        padding: 0 15px;
      }

      .button {
        margin-top: 0;
      }
    }
  }

  .screen-notification-modal.md-modal.md-parent-show {
    .md-container > .md-modal.md-show{
      height:60%;
    }

    @media (max-width: ${Theme.break.mediumAlt}) {
      .md-container > .md-modal.md-show {
        height: 80%;
      }
     }
    @media (max-width: ${Theme.break.small}) {
      .md-container > .md-modal.md-show {
        height: 100%;
      }
    }

  }

  .notification-btn-tooltip{
    .sub-title{
      color: ${Theme.color.grey50}
    }
  }

  #scrollableDiv {
    > div {
      .infinite-scroll-component {
        height: 100%;
      }
    }

    .subheader {
      height: 40px;
    }

    .subheader {
      + div {
        &.with-banner {
          height: calc(100% - 310px);
        }
      }
    }
  }

  @media only screen and (max-width: ${Theme.break.mediumAlt}) {
    #launcher,
    #apt-widget {
      display: none;
    }

    .ui.loader.active {
      bottom: 0;
      right: 0;
    }

    .ui.loader.active.center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .embeddedMessagingConversationButtonWrapper {
      .embeddedMessagingConversationButton {
        bottom: 72px;
      }
    }
  }

  @media only screen and (max-height: 414px) and (max-width: 1025px) {
    .App,
    .modal-wrapper,
    .activate-banner {
      display: none;
    }

    .blocker {
      width: 100%;
      height: 100%;
      background: var(--color-gray-950);
      display: flex;
      align-items: center;

      .message {
        max-width: 680px;
        padding: 0 40px;
        margin: 0 auto;
        display: flex;

        .blocker-note {
          color: var(--color-white-100);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 60px;

          h3 {
            font-size: 1.5em;
            line-height: 1.25;
          }

          p {
            color: ${Theme.color.grey};
            margin: 5px 0 0;
          }

          a {
            color: var(--color-white-100);
            text-decoration: underline;
          }
        }
      }

      .spirit {
        display: flex;
        width: 414px;

        #body {
          animation: spirit 6s ease-in-out infinite;
        }

        #bubble {
          animation: bubble 6s ease-in-out infinite;
        }

        #eyes {
          animation: eyes 4s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
        }
      }
    }

    @keyframes spirit {
      0% { transform: translate(0,44px)}
      25% { transform: translate(0,72px)}
      50% { transform: translate(0,44px)}
      75% { transform: translate(0,64px)}
      100% { transform: translate(0,44px)}
    }

    @keyframes bubble {
      0% { transform: translate(72px,0)}
      25% { transform: translate(72px,24px)}
      50% { transform: translate(72px,0)}
      75% { transform: translate(72px,12px)}
      100% { transform: translate(72px,0)}
    }

    @keyframes eyes {
      20% { transform: translate(0,0)}
      40% { transform: translate(10px,3px)}
      80% { transform: translate(8px,8px)}
      100% { transform: translate(0,0)}
    }

    .md-open {
      #root {
        > div {
          height: 100%;
        }

        .modal-wrapper {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-height: 414px) and (max-width: 600px) {
    .blocker {
      .spirit {
        width: auto;
      }
    }
  }

  @media (orientation: portrait) {
    .blocker {
      display: none;
    }
    .modalwrapper {
      display: block;
    }
  }

  @media only screen and (max-width: ${Theme.break.small}) {
    .page.modals.dimmer {
      background: var(--color-white-100) !important;
      padding: 0;
    }

    .page.modals.dimmer .ui.scrolling.modal,
    .page.modals .ui.modal {
      margin: 0 !important;
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      left: 0 !important;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      overflow-y: scroll;
    }

    .page .ui.modal .ui.header + .scrolling.content,
    .page .ui.modal .ui.header + .content {
      height: calc(100% - 50px);
    }

    .page .ui.modal .scrolling.content,
    .page .ui.modal .content {
      height: 100%;
      max-height: 100%;
    }

    .ui.inline.loader .active {
      width: 20px;
      height: 20px;
    }

    .ui.small.loader:after,
    .ui.small.loader:before {
      width: 20px;
      height: 20px;
      margin: 0 0 0 -10px;
    }

    .cast-confirm {
      background-color: var(--color-gray-50) !important;
      box-shadow: none;
      color: var(--color-gray-100) !important;
    }

    .cast-confirm .loader {
      width: 20px;
      height: 20px;
    }

    .cast-confirm .ui.loader:before {
      border-color: rgba(227, 55, 26,0.2);
    }

    .cast-confirm .ui.loader:after {
      border-top-color: var(--color-gray-100);
    }

    .empty-state .grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: calc(100% - 80px);
    }
  }

  @keyframes mymove {
    from { width: 0px; }
    to { width: calc(100% - 129px);}
  }

  .reset-permissions-modal {

    > span {
      font-family: Lato;
      font-size: 24px;
      font-weight: 800;
      line-height: 36px;
      text-align: left;
    }

  }
`;

export const Styled = styled.div`
  background: var(--color-gray-50);
  height: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .header + .player-preview {
    height: calc(100% - 70px);
  }
`;

/** * Admin ***/
export const AdminStyled = styled.div`
  padding: ${Theme.admin.desktopHorizontalPadding}
    ${Theme.admin.desktopVerticalPadding};

  .banner {
    max-width: 960px;
    margin: 0 auto 20px;
  }

  .content {
    min-height: 500px;
  }

  .board {
    max-width: 1440px;
    margin: 0 auto 20px;
    padding: 40px 30px;
    background: var(--color-white-100);
    ${Theme.default.borderRadius};
    border: solid 1px ${Theme.color.lightGrey};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    position: relative;

    &.padless {
      padding: 0;
    }

    .head {
      border-bottom: 1px solid ${Theme.color.lightGrey};
      padding: 40px 40px 20px;
      margin: 0;

      .title {
        display: inline-flex;
        align-items: center;
        height: 45px;
      }
    }

    > .content {
      background: var(--color-white-100);
      border-radius: 0 0 ${Theme.default.borderRadiusValue}
        ${Theme.default.borderRadiusValue};
      padding: 16px 40px 40px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .prof {
        .avatar {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          line-height: 100px;
          font-size: 60px;
          font-weight: 900;
        }
      }

      .row-section {
        padding: 40px 0;
        border-bottom: 1px solid ${Theme.color.lightGrey};
        flex-wrap: wrap;

        .section-title {
          margin-bottom: 20px;
        }

        .sub-text {
          font-size: 0.875em;
          line-height: 1.357;
          color: ${Theme.color.midGrey};
          margin-top: 5px;
        }

        .col {
          width: 100%;
        }

        .col-50 {
          width: calc(50% - 20px);
        }

        .col-25 {
          width: calc(25% - 20px);
        }

        :last-child {
          border-bottom: none;
        }
      }
    }
  }

  &.credential {
    .content {
      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 280px;

        h3 {
          font-size: 1.25em;
          line-height: 1.2;
        }
      }
    }
  }

  @media (max-width: ${Theme.break.small}) {
    .banner {
      margin-bottom: 10px;
    }

    .board {
      .head {
        padding: 20px;
      }

      .content {
        padding: 10px 20px 40px;
        min-height: 425px;

        .row-section {
          .col-50 {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: ${Theme.break.tiny}) {
    padding: 0;
    height: 100%;

    .banner {
      margin: 0;

      > div {
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid ${Theme.color.lightGrey};
      }
    }

    .board {
      border: none;
      box-shadow: none;
      height: auto;

      .head {
        padding: 20px 15px;
      }

      .content {
        padding: 10px 15px 20px;
      }
    }

    .searchfield {
      margin: 0 0 ${Theme.admin.mobileVerticalMargin};
    }
  }
`;

export const DraggableContainer = styled.div`
  opacity: 1;
  position: relative;
  /* avoid default outline which looks lame with the position: absolute; */
  &:focus {
    outline: none;
    border-color: ${Theme.color.lightGrey};
  }
`;

export const SelectionCount = styled.div`
  right: -14px;
  top: -14px;
  color: var(--color-gray-950);
  background: var(--color-yellow-500);
  border-radius: 50%;
  height: 28px;
  width: 28px;
  line-height: 28px;
  position: absolute;
  text-align: center;
  font-size: 0.875em;
  font-weight: bold;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, 0.1);
`;
