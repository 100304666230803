import { getOrgRegionFromUrl } from "src/helpers/urlHelper";
import { AppInstances, Apps } from "./apps";
import { Canvases } from "./canvases";
import { Media } from "./media";

export enum Playlists {
  BACKGROUND_PACK_DARK = "Background Pack - Dark",
  EMPLOYEE_RECOGNITION = "Employee Recognition",
  HS_MESSAGES = "H&S Messages",
  SPORT_NEWS = "Sports News",
}

enum PlaylistContentType {
  IMAGES = "images",
  CANVASES = "canvases",
  APPS = "apps",
}

export type PlaylistContent = {
  [PlaylistContentType.IMAGES]: Media[];
  [PlaylistContentType.CANVASES]: Canvases[];
  [PlaylistContentType.APPS]: {
    appName: string;
    appInstancesName: AppInstances[];
  }[];
};

export type PlaylistMetaProps = {
  name: Playlists;
  content: PlaylistContent;
  props: {
    play_mode: string;
    total_items: number;
    total_durations: number;
    default_durations: {
      app: number;
      link: number;
      site: number;
      image: number;
      document: number;
    };
  };
};

type PlaylistsWithMetaType = {
  [key in Playlists]: PlaylistMetaProps;
};

export const playlistWithMeta = (
  playlistName: Playlists
): PlaylistMetaProps => {
  const { isUs } = getOrgRegionFromUrl();
  const playlistMeta: PlaylistsWithMetaType = {
    [Playlists.BACKGROUND_PACK_DARK]: {
      name: Playlists.BACKGROUND_PACK_DARK,
      content: {
        images: [
          Media.BACKGROUND_92,
          Media.BACKGROUND_93,
          Media.BACKGROUND_84,
          Media.BACKGROUND_83,
          Media.BACKGROUND_80,
          Media.BACKGROUND_77,
          Media.BACKGROUND_70,
          Media.BACKGROUND_69,
          Media.BACKGROUND_68,
          Media.BACKGROUND_67,
          Media.BACKGROUND_66,
          Media.BACKGROUND_65,
          Media.BACKGROUND_61,
          Media.BACKGROUND_60,
          Media.BACKGROUND_59,
          Media.BACKGROUND_58,
          Media.BACKGROUND_53,
          Media.BACKGROUND_51,
          Media.BACKGROUND_48,
          Media.BACKGROUND_47,
          Media.BACKGROUND_45,
          Media.BACKGROUND_43,
          Media.BACKGROUND_41,
          Media.BACKGROUND_40,
          Media.BACKGROUND_35,
          Media.BACKGROUND_33,
          Media.BACKGROUND_32,
          Media.BACKGROUND_34,
          Media.BACKGROUND_28,
          Media.BACKGROUND_27,
          Media.BACKGROUND_24,
          Media.BACKGROUND_21,
          Media.BACKGROUND_17,
          Media.BACKGROUND_14,
          Media.BACKGROUND_16,
          Media.BACKGROUND_13,
          Media.BACKGROUND_8,
          Media.BACKGROUND_6,
          Media.BACKGROUND_4,
          Media.BACKGROUND_3,
        ],
        canvases: [],
        apps: [],
      },
      props: {
        play_mode: "loop",
        total_items: 0,
        total_durations: 0,
        default_durations: {
          app: 60000,
          link: 60000,
          site: 60000,
          image: 30000,
          document: 10000,
        },
      },
    },
    [Playlists.EMPLOYEE_RECOGNITION]: {
      name: Playlists.EMPLOYEE_RECOGNITION,
      content: {
        images: [],
        canvases: [
          Canvases.CELEBRATION_MESSAGE,
          ...(isUs ? [Canvases.CELEBRATION_MESSAGE_2] : []),
          Canvases.CELEBRATION_MESSAGE_3,
          Canvases.EMPLOYEE_CELEBRATION,
          Canvases.PULSE_SURVEY,
          Canvases.SCAN_CTA,
        ],
        apps: [],
      },
      props: {
        play_mode: "loop",
        total_items: 0,
        total_durations: 0,
        default_durations: {
          app: 20000,
          link: 60000,
          site: 60000,
          image: 10000,
          document: 10000,
        },
      },
    },
    [Playlists.HS_MESSAGES]: {
      name: Playlists.HS_MESSAGES,
      content: {
        images: [],
        canvases: [
          Canvases.HS_MESSAGE,
          Canvases.PULSE_SURVEY,
          Canvases.SCAN_CTA,
        ],
        apps: [
          {
            appName: Apps.COUNT_UP_TIMER,
            appInstancesName: [AppInstances.DAYS_SINCE_ACCIDENTS],
          },
        ],
      },
      props: {
        play_mode: "loop",
        total_items: 4,
        total_durations: 80000,
        default_durations: {
          app: 20000,
          link: 60000,
          site: 60000,
          image: 10000,
          document: 10000,
        },
      },
    },
    [Playlists.SPORT_NEWS]: {
      name: Playlists.SPORT_NEWS,
      content: {
        images: [],
        canvases: [],
        apps: [
          {
            appName: Apps.RSS_FEED,
            appInstancesName: [
              AppInstances.CBS_SPORT_BOXING,
              AppInstances.CBS_SPORT_BASKETBALL,
              AppInstances.CBS_SPORT_NBA,
              AppInstances.CBS_SPORT_NFL,
              AppInstances.CBS_SPORT_TENNIS,
              AppInstances.CBS_SPORT_SOCCER,
              AppInstances.CBS_SPORT_COLLEGE_FOOTBALL,
              AppInstances.CBS_SPORT_NHL,
              AppInstances.CBS_SPORT_GOLF,
              AppInstances.CBS_SPORT_WWE,
              AppInstances.CBS_SPORT_MMA,
              AppInstances.CBS_SPORT_MLB,
            ],
          },
        ],
      },
      props: {
        play_mode: "loop",
        total_items: 13,
        total_durations: 780000,
        default_durations: {
          app: 60000,
          link: 60000,
          site: 60000,
          image: 10000,
          document: 10000,
        },
      },
    },
  };

  return playlistMeta[playlistName];
};
