interface ObserveElementParams {
  nodeId: string;
  callback: () => void;
}

export const observeElement = (params: ObserveElementParams) => {
  const { nodeId, callback } = params;

  // If the element with the given nodeId already exists in the DOM when this function is called,
  // we immediately execute the callback and return from the function.
  // This is because the MutationObserver won't detect nodes that already exist when it starts observing.
  // It only observes changes in the DOM that occur after it starts observing.
  if (document.getElementById(nodeId)) {
    callback();
    return;
  }

  const targetNode = document.body;
  if (targetNode) {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement && node.id === nodeId) {
            callback();
            observer.disconnect();
            return;
          }
        });
      }
    });

    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);
  }
};
