import { useAppContext } from "src/hooks/useAppContext";
import { ssm } from "src/state/session/ssm";
import { Space } from "src/types.g";

export function useOnCurrentUserSettingLastAccessSpaceUpdated() {
  const context = useAppContext();

  const onCurrentUserSettingLastAccessSpaceUpdated = ({
    lastAccessSpace,
  }: {
    lastAccessSpace: Pick<Space, "id" | "name">;
  }) => {
    const defaultRedirect = context.getDefaultRedirectPath(
      context.currentPermissions
    );
    ssm.changeSpace(lastAccessSpace.id, lastAccessSpace.name, defaultRedirect);
  };

  return { onCurrentUserSettingLastAccessSpaceUpdated };
}
