/* eslint-disable @typescript-eslint/indent */
import { ElementData } from "@screencloud/secure-sites-extension-types";
import { DASHBOARDS_DEFAULT_KEEP_ALIVE } from "../../../constants/constants";
import { cloneDeep } from "lodash";
import { getElementDescription } from "./actions/factory";
import {
  ClickAction,
  ClickNewTabAction,
  ElementActionConfig,
  ElementSnapshotConfig,
  HoverAction,
  LoopAction,
  RenderSingleAction,
  SessionKeepAliveAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./actions/models";
import { FrameWidthType, NavigationType } from "./navigationcontrol";
import {
  pairCredentials,
  sanitiseFormerCredentialActions,
} from "../../../helpers/siteRecorderHelper";

export const EnterKeyValue = "\r";
export const SpaceKeyValue = " ";

export const ElementSnapshotActionPrefix = "Take a snapshot of";
export const PageSnapshotActionName = "Take snapshot";

export enum ActionMessageType {
  ToggleRecording = "recording:toggle",
  ClearAllActions = "actions:clear-all",
  UpdateNavigationMode = "navigation:update-mode",
  Navigate = "navigation:navigate",
  AddAction = "actions:add",
  RemoveAction = "actions:remove",
  SetActions = "actions:set",
  UpdateFrameWidthType = "frame:update-width",
  UpdateRecorderUrl = "recorder:update-url",
  ToggleElementSelection = "selecting:toggle",
  UpdateSelectedElement = "recorder:update-selected-element",
  UpdateSnapshotElement = "recorder:update-snapshot-element",
  SaveSelectedElement = "recorder:save-selected-element",
  UpdateActionEditor = "actions:update-editor",
  SaveActionEditor = "actions:save-editor",
  CloseActionEditor = "actions:close-editor",
  MoveAction = "actions:move",
  UpdateTitle = "recorder:update-title",
  UpdateExtensionVersion = "recorder:update-ext-version",
}

export interface ActionMessage {
  type: ActionMessageType;
  payload?: any;
}

export interface RecorderState {
  isRecording: boolean;
  url: string;
  recorderUrl: string;
  navigationMode: NavigationType;
  actions: SiteRecorderAction[];
  frameWidthType: FrameWidthType;
  isSelectingElement: boolean;
  selectedElement?: ElementData;
  editorType?: SiteRecorderActionType;
  editorAction?: SiteRecorderAction;
  hasOtc?: boolean;
  navigateCount: number;
  dashboardTitle: string;
  extensionVersion: string;
}

export const InitialRecorderState = {
  isRecording: false,
  url: "",
  recorderUrl: "",
  navigationMode: NavigationType.Popup,
  actions: [],
  frameWidthType: FrameWidthType.Fit,
  isSelectingElement: false,
  selectedElement: undefined,
  editorType: undefined,
  editorAction: undefined,
  navigateCount: 0,
  dashboardTitle: "Dashboard",
  extensionVersion: "0.0.0",
};

export const createClickNewTabActionFrom = (
  action: ClickAction,
  url: string
): ClickNewTabAction => {
  const newAction = { ...action } as ClickNewTabAction;
  newAction.type = SiteRecorderActionType.ClickNewTab;
  newAction.name = `Open ${(newAction.name || "").replace(
    "Click on ",
    ""
  )} in new tab`;
  newAction.config.url = url;

  return newAction;
};

export const ensureSnapshotAtEnd = (
  actions: SiteRecorderAction[],
  url?: string
): SiteRecorderAction[] => {
  const snapshotActions = actions.filter((action) => {
    return action.name === PageSnapshotActionName;
  });

  // the existing snapshot
  const lastSnapshotAction = snapshotActions[snapshotActions.length - 1];

  actions = actions.filter((action) => {
    return action.name !== PageSnapshotActionName;
  });

  const lastElement = actions[actions.length - 1];
  if (lastElement.type === SiteRecorderActionType.RenderSingle) {
    // Element Snapshot case, don't alter actions
    return actions;
  }

  // work out which url should be used for the snapshot
  const snapshotUrl = url
    ? url
    : lastSnapshotAction && lastSnapshotAction.config.url
    ? lastSnapshotAction.config.url
    : lastElement.config.url;

  const snapshotAction: RenderSingleAction = new RenderSingleAction(
    PageSnapshotActionName,
    {
      url: snapshotUrl,
    }
  );
  actions.push(snapshotAction);

  return actions;
};

export const replaceSnapshotAtEnd = (
  actions: SiteRecorderAction[],
  replacementAction: SiteRecorderAction
): SiteRecorderAction[] => {
  actions = actions.filter((action) => {
    return action.name !== PageSnapshotActionName;
  });
  const lastElement = actions[actions.length - 1];
  if (lastElement.type === SiteRecorderActionType.RenderSingle) {
    actions.pop();
  }
  actions.push(replacementAction);
  return actions;
};

const cancelCustomSnapshot = (
  actions: SiteRecorderAction[],
  url?: string
): SiteRecorderAction[] => {
  actions = actions.filter((action) => {
    return !action.name.startsWith(ElementSnapshotActionPrefix);
  });
  return ensureSnapshotAtEnd(actions, url);
};

/**
 * @description Checks if this action sequence ends with an element snapshot
 * @param actions the action steps for the dashboard journey
 * @returns true if an element snapshot is found, false otherwise.
 */
const hasElementSnapshot = (actions: SiteRecorderAction[]): boolean => {
  if (actions.length) {
    const lastAction = actions[actions.length - 1];

    if (lastAction.name.startsWith(ElementSnapshotActionPrefix)) {
      return true;
    }
  }
  return false;
};

const removeDuplicateActions = (latestAction, newAction, actions) => {
  if (
    (latestAction?.type === newAction.type ||
      latestAction?.type === SiteRecorderActionType.Click) &&
    latestAction.config.selector === newAction.config.selector
  ) {
    actions.splice(actions.length - 2, 1, newAction);
  } else {
    actions.push(newAction);
  }
};

export const RecorderReducer = (
  state: RecorderState,
  action: ActionMessage
): RecorderState => {
  switch (action.type) {
    case ActionMessageType.ToggleRecording:
      const isRecording = !!action.payload;
      let actions = [...state.actions];

      if (!isRecording && !hasElementSnapshot(actions)) {
        // Recording has stopped, ensure page snapshot action has last loaded url
        actions = ensureSnapshotAtEnd(actions, state.recorderUrl);
      }

      return {
        ...state,
        isRecording,
        actions,
      };
    case ActionMessageType.UpdateNavigationMode:
      return {
        ...state,
        navigationMode: action.payload,
      };
    case ActionMessageType.Navigate: {
      return {
        ...state,
        url: action.payload,
        navigateCount: ++state.navigateCount,
      };
    }
    case ActionMessageType.ClearAllActions:
      return {
        ...state,
        actions: [],
      };

    case ActionMessageType.AddAction: {
      let actions = [...state.actions];
      actions = actions.filter((action) => {
        return !action.name.startsWith(ElementSnapshotActionPrefix);
      });
      let hasOtc = false;
      const latestAction =
        actions.length > 0 ? actions[actions.length - 2] : null;
      const newAction = action.payload as SiteRecorderAction;

      switch (newAction.type) {
        case SiteRecorderActionType.SessionKeepAlive:
          actions.splice(0, 0, newAction);
          break;
        case SiteRecorderActionType.Navigate: {
          const isFirstNavigation = !actions.some(
            (existingAction) =>
              existingAction.type === SiteRecorderActionType.Navigate
          );

          if (isFirstNavigation) {
            actions.push(
              new SessionKeepAliveAction("Manage Session", {
                interval: DASHBOARDS_DEFAULT_KEEP_ALIVE,
              })
            );
            const hasSession =
              actions.length > 0 &&
              actions[0].type === SiteRecorderActionType.SessionKeepAlive;
            actions.splice(hasSession ? 1 : 0, 0, newAction);
          } else {
            const isNewTabNavigation = actions.length
              ? actions[actions.length - 2].type ===
                SiteRecorderActionType.Click
              : false;
            if (isNewTabNavigation) {
              const lastClickAction = { ...actions[actions.length - 2] };
              const clickNewTabAction = createClickNewTabActionFrom(
                lastClickAction,
                newAction.config.url
              );
              actions[actions.length - 2] = clickNewTabAction;
            } else {
              actions.push(newAction);
            }
          }
          break;
        }
        case SiteRecorderActionType.Loop: {
          if (
            !latestAction ||
            latestAction.type === SiteRecorderActionType.SessionKeepAlive ||
            latestAction.type === SiteRecorderActionType.Loop ||
            (actions.length === 1 &&
              latestAction.type === SiteRecorderActionType.Navigate) ||
            (actions.length === 2 &&
              actions[1].type === SiteRecorderActionType.Navigate)
          ) {
            actions.push(newAction);
          } else {
            (newAction as LoopAction).config.actions = [latestAction];
            actions.splice(actions.length - 2, 1, newAction);
          }

          break;
        }
        case SiteRecorderActionType.EnterOneTimeCode:
          hasOtc = true;
          removeDuplicateActions(latestAction, newAction, actions);
          break;
        case SiteRecorderActionType.EnterText:
        case SiteRecorderActionType.EnterUsername:
        case SiteRecorderActionType.EnterPassword: {
          if (
            newAction.config.input === EnterKeyValue ||
            newAction.config.input === SpaceKeyValue
          ) {
            actions.push(newAction);
          } else {
            removeDuplicateActions(latestAction, newAction, actions);
          }
          break;
        }
        default:
          actions.push(newAction);
          break;
      }

      actions = pairCredentials(ensureSnapshotAtEnd(actions));

      return {
        ...state,
        actions,
        hasOtc,
      };
    }
    case ActionMessageType.RemoveAction:
      return {
        ...state,
        actions: pairCredentials(
          state.actions.filter((_, index) => index !== action.payload.index)
        ),
      };
    case ActionMessageType.UpdateFrameWidthType:
      return {
        ...state,
        frameWidthType: action.payload,
      };
    case ActionMessageType.UpdateRecorderUrl:
      return {
        ...state,
        recorderUrl: action.payload,
      };
    case ActionMessageType.ToggleElementSelection:
      return {
        ...state,
        isSelectingElement: action.payload,
        selectedElement: undefined,
      };
    case ActionMessageType.UpdateSelectedElement: {
      if (!action.payload) {
        return {
          ...state,
          selectedElement: undefined,
        };
      }

      let editorAction = state.editorAction
        ? cloneDeep(state.editorAction)
        : undefined;
      const selectedElement = action.payload as ElementData;
      const description = getElementDescription(selectedElement);
      const config: ElementActionConfig = {
        url: state.recorderUrl,
        element: selectedElement.tagName,
        selector: selectedElement.selector,
      };

      if (state.editorAction?.type === SiteRecorderActionType.RenderSingle) {
        if (editorAction) {
          editorAction.name = `${ElementSnapshotActionPrefix} ${description}`;
          (editorAction as RenderSingleAction).config = {
            ...editorAction.config,
            ...config,
          };
        } else {
          editorAction = new RenderSingleAction(
            `${ElementSnapshotActionPrefix} ${description}`,
            config
          );
        }

        return {
          ...state,
          editorAction,
        };
      } else {
        const hoverAction = new HoverAction(
          `Hover over ${description}`,
          config
        );

        return {
          ...state,
          actions: ensureSnapshotAtEnd([...state.actions, hoverAction]),
          selectedElement,
        };
      }
    }
    case ActionMessageType.UpdateSnapshotElement: {
      if (!action.payload || !action.payload.element) {
        return {
          ...state,
          actions: cancelCustomSnapshot(
            state.actions,
            action.payload ? action.payload.url : undefined
          ),
          selectedElement: undefined,
        };
      }
      const selectedElement = action.payload.element as ElementData;
      const config: ElementSnapshotConfig = {
        url: action.payload.url,
        element: selectedElement.tagName,
        selector: selectedElement.selector,
        ariaLabel: selectedElement.ariaLabel,
        id: selectedElement.id,
        className: selectedElement.className,
        shortSelector: selectedElement.shortSelector,
        role: selectedElement.role,
        a11yName: selectedElement.a11yName,
        nameAttr: selectedElement.name,
      };

      if (selectedElement.text) {
        config.text = selectedElement.text;
      }

      if (selectedElement.rect) {
        config.rect = selectedElement.rect;
      }

      if (selectedElement.viewport) {
        config.viewport = selectedElement.viewport;
      }

      if (action.payload.iframe) {
        config.iframe = action.payload.iframe;
      }

      const customSnapshotAction = new RenderSingleAction(
        `${ElementSnapshotActionPrefix} ${selectedElement.selector}`,
        {
          ...config,
        }
      );
      return {
        ...state,
        actions: replaceSnapshotAtEnd(state.actions, customSnapshotAction),
        selectedElement,
      };
    }
    case ActionMessageType.UpdateActionEditor: {
      return {
        ...state,
        editorAction: action.payload?.action,
        editorType: action.payload?.type ?? state.editorType,
      };
    }
    case ActionMessageType.SaveActionEditor: {
      let actions = [...state.actions];
      const editorAction = state.editorAction!;

      if (editorAction.config.url === "") {
        editorAction.config.url = state.recorderUrl;
      }

      let didUpdateExistingAction = false;

      for (let i = 0; i < actions.length; i++) {
        if (actions[i].id === editorAction.id) {
          actions[i] = editorAction;
          didUpdateExistingAction = true;
          break;
        }

        if (actions[i].type === SiteRecorderActionType.Loop) {
          const childActions = (actions[i] as LoopAction).config.actions;

          for (let j = 0; j < childActions.length; j++) {
            if (childActions[j].id === editorAction.id) {
              childActions[j] = editorAction;
              didUpdateExistingAction = true;
              break;
            }
          }
        }
      }

      if (!didUpdateExistingAction) {
        switch (editorAction.type) {
          case SiteRecorderActionType.Loop: {
            const latestAction =
              actions.length > 0 ? actions[actions.length - 1] : undefined;

            if (
              !latestAction ||
              latestAction.type === SiteRecorderActionType.SessionKeepAlive ||
              latestAction.type === SiteRecorderActionType.Loop ||
              (actions.length === 1 &&
                latestAction.type === SiteRecorderActionType.Navigate) ||
              (actions.length === 2 &&
                actions[1].type === SiteRecorderActionType.Navigate)
            ) {
              actions.push(editorAction);
            } else {
              (editorAction as LoopAction).config.actions = [latestAction];
              actions.splice(actions.length - 1, 1, editorAction);
            }

            break;
          }
          case SiteRecorderActionType.SessionKeepAlive:
            actions.splice(0, 0, editorAction);
            break;
          default:
            actions.push(editorAction);
            break;
        }
      }

      actions = pairCredentials(ensureSnapshotAtEnd(actions));
      actions = sanitiseFormerCredentialActions(actions);

      return {
        ...state,
        editorType: undefined,
        editorAction: undefined,
        isSelectingElement: false,
        selectedElement: undefined,
        actions,
      };
    }
    case ActionMessageType.CloseActionEditor:
      return {
        ...state,
        editorAction: undefined,
        editorType: undefined,
        isSelectingElement: false,
        selectedElement: undefined,
      };
    case ActionMessageType.MoveAction: {
      const { start, end } = action.payload;

      if (start === end) {
        return state;
      }

      const actions = [...state.actions];
      const movedAction = actions.splice(start, 1)[0];
      actions.splice(end, 0, movedAction);

      return { ...state, actions };
    }
    case ActionMessageType.SetActions:
      return {
        ...state,
        actions: pairCredentials(ensureSnapshotAtEnd(action.payload)),
      };
    case ActionMessageType.UpdateTitle:
      return {
        ...state,
        dashboardTitle: action.payload,
      };
    case ActionMessageType.UpdateExtensionVersion:
      return {
        ...state,
        extensionVersion: action.payload,
      };
    default:
      return state;
  }
};
