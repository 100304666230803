import { ModalSize } from "@screencloud/screencloud-ui-components";
import { SetScreenNotificationPreferences } from "src/components/ScreenNotificationPreferences";
import { useAppContext } from "../useAppContext";
import { UUID } from "@screencloud/uuid";
import {
  GetAllNotificationRulesByScreenIdDocument,
  GetAllNotificationRulesByScreenIdQuery,
  SearchUserBySpaceIdDocument,
  SearchUserBySpaceIdQuery,
  useCreateNotificationRuleMutation,
  useDeleteNotificationRuleByIdMutation,
  useDeleteNotificationSubscriptionByIdMutation,
  useSubscribeNotificationMutation,
  useUpdateNotificationRuleByIdMutation,
} from "src/types.g";
import { DocumentNode } from "graphql";
import client from "src/state/apolloClient";
import cloneDeep from "lodash/cloneDeep";

export default function useScreenNotification(
  screenId: UUID,
  refetchQueries: DocumentNode[] = []
) {
  const [subscribe] = useSubscribeNotificationMutation();
  const [
    deleteSubscriptionById,
    { loading: deleteSubscriptionInProgress },
  ] = useDeleteNotificationSubscriptionByIdMutation();

  const [createNotificationRuleMutation] = useCreateNotificationRuleMutation();
  const [updateNotificationRuleById] = useUpdateNotificationRuleByIdMutation();
  const [deleteNotificationRuleById] = useDeleteNotificationRuleByIdMutation();
  const defaultRefetchQueries = [
    {
      query: GetAllNotificationRulesByScreenIdDocument,
      variables: {
        screenId: { equalTo: screenId },
      },
    },
  ];

  const context = useAppContext();

  /**
   *
   * @param screenId
   * show screen notification preference modal
   */

  const showSetNotificationModal = (
    screenId,
    setIsRuleActive: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    context.modal.openNavigationControlModal(
      <SetScreenNotificationPreferences
        refetchQueries={refetchQueries}
        screenId={screenId}
        setIsRuleActive={setIsRuleActive}
      />,
      `${context.intl.formatMessage({
        id: "ui_component.screen_notification.modal.heading",
        defaultMessage: "Notification Preferences",
      })}`,
      {
        opts: {
          size: ModalSize.SMALL,
          className: "screen-notification-modal",
        },
      }
    );
  };

  const createNotificationRule = async (screenId: UUID, waitTime: number) => {
    try {
      const data = await createNotificationRuleMutation({
        variables: {
          input: {
            screenId,
            spaceId: context.currentSpace?.id,
            waitTimeInSecond: waitTime,
          },
        },
        refetchQueries: [...refetchQueries, ...defaultRefetchQueries],
        optimisticResponse: {
          createNotificationRule: {
            notificationRule: {
              id: "temp-id",
              active: true,
              waitTime,
              __typename: "NotificationRule",
            },
            __typename: "CreateNotificationRulePayload",
          },
          __typename: "Mutation",
        },
      });

      return data.data;
    } catch (e) {
      return null;
    }
  };

  /**
   *
   * @param screenId
   * @param userIds
   * @returns void
   */
  const subscribeNotification = async (params: {
    ruleId: UUID;
    userIds: UUID[];
  }) => {
    const notificationRuleId = "temp-id";
    const { ruleId, userIds } = params;
    const data = await subscribe({
      variables: {
        input: {
          ruleId,
          userIds,
          spaceId: context.currentSpace?.id,
        },
      },
      refetchQueries: [...refetchQueries, ...defaultRefetchQueries],
      optimisticResponse: {
        subscribeNotification: {
          notificationRule: {
            id: notificationRuleId,
            active: true,
            notificationSubscriptionsByNotificationRuleId: {
              nodes: userIds.map((user) => {
                return {
                  id: "temp-id",
                  channel: "email",
                  active: true,
                  userByUserIdAndOrgId: {
                    id: userIds[0],
                    __typename: "User",
                  },
                  __typename: "NotificationSubscription",
                };
              }),
              __typename: "NotificationSubscriptionsConnection",
            },
            __typename: "NotificationRule",
          },
          __typename: "SubscribeNotificationPayload",
        },
        __typename: "Mutation",
      },
      update: (cache, { data }) => {
        // fetch rules for the screen
        const rules = cloneDeep(
          cache.readQuery<GetAllNotificationRulesByScreenIdQuery>({
            query: GetAllNotificationRulesByScreenIdDocument,
            variables: {
              screenId: { equalTo: screenId },
            },
          })
        );

        const sub = cloneDeep(data);

        // read current users list from cache
        const users = cloneDeep(
          cache
            .readQuery<SearchUserBySpaceIdQuery>({
              query: SearchUserBySpaceIdDocument,
              variables: {
                query: "",
              },
            })
            ?.searchUser?.nodes.filter((user) => user.id === userIds[0])
        );

        // do optimistic update only for single users
        if (
          users &&
          sub?.subscribeNotification?.notificationRule
            ?.notificationSubscriptionsByNotificationRuleId.nodes &&
          rules?.allNotificationRules?.nodes.length &&
          rules?.allNotificationRules?.nodes[0]
            .notificationSubscriptionsByNotificationRuleId.nodes &&
          userIds.length === 1
        ) {
          // add current user active to true
          sub.subscribeNotification.notificationRule.notificationSubscriptionsByNotificationRuleId.nodes[0].userByUserIdAndOrgId =
            users[0];
          rules?.allNotificationRules?.nodes[0].notificationSubscriptionsByNotificationRuleId.nodes.push(
            sub?.subscribeNotification?.notificationRule
              ?.notificationSubscriptionsByNotificationRuleId.nodes[0] as any
          );

          client.writeQuery({
            query: GetAllNotificationRulesByScreenIdDocument,
            variables: {
              screenId: { equalTo: screenId },
            },
            data: rules,
          });
        }
      },
    });

    return data.data;
  };

  /**
   *
   * @param subscriptionId
   * @returns DeleteNotificationSubscriptionByIdMutationResult
   */
  const deleteSubscription = async (subscriptionId: UUID) => {
    const data = await deleteSubscriptionById({
      variables: {
        input: {
          notificationSubscriptionId: subscriptionId,
        },
      },
      refetchQueries: [...refetchQueries, ...defaultRefetchQueries],
      optimisticResponse: {
        deleteNotificationSubscriptionById: {
          notificationSubscription: {
            id: subscriptionId,
            __typename: "NotificationSubscription",
          },
          __typename: "DeleteNotificationSubscriptionByIdPayload",
        },
        __typename: "Mutation",
      },
      update: (cache, { data }) => {
        // fetch rules for the screen
        const rules = cloneDeep(
          cache.readQuery<GetAllNotificationRulesByScreenIdQuery>({
            query: GetAllNotificationRulesByScreenIdDocument,
            variables: {
              screenId: { equalTo: screenId },
            },
          })
        );

        if (
          rules?.allNotificationRules?.nodes[0]
            .notificationSubscriptionsByNotificationRuleId.nodes
        ) {
          const subscriptions = rules?.allNotificationRules?.nodes[0].notificationSubscriptionsByNotificationRuleId.nodes.filter(
            (item) => item.id !== subscriptionId
          );
          rules.allNotificationRules.nodes[0].notificationSubscriptionsByNotificationRuleId.nodes = subscriptions;
          // delete using optimistic response
          client.writeQuery({
            query: GetAllNotificationRulesByScreenIdDocument,
            variables: {
              screenId: { equalTo: screenId },
            },
            data: rules,
          });
        }
      },
    });

    return data.data;
  };

  /**
   *
   * @param notificationRuleId
   * @returns
   */
  const deleteNotificationRule = async (notificationRuleId: UUID) => {
    const data = await deleteNotificationRuleById({
      variables: {
        input: {
          notificationRuleId,
        },
      },
      refetchQueries: [...refetchQueries, ...defaultRefetchQueries],
    });

    return data.data;
  };

  const updateNotificationRule = async (
    notificationRuleId: UUID,
    waitTime: number
  ) => {
    try {
      const data = await updateNotificationRuleById({
        variables: {
          input: {
            notificationRuleId,
            waitTimeInSecond: waitTime,
          },
        },
        refetchQueries: [...refetchQueries, ...defaultRefetchQueries],
        optimisticResponse: {
          updateNotificationRuleById: {
            notificationRule: {
              id: notificationRuleId,
              active: true,
              waitTime,
              __typename: "NotificationRule",
            },
            __typename: "UpdateNotificationRuleByIdPayload",
          },
          __typename: "Mutation",
        },
      });

      return data.data;
    } catch (e) {
      return null;
    }
  };

  return {
    showSetNotificationModal,
    createNotificationRule,
    subscribeNotification,
    deleteSubscription,
    deleteNotificationRule,
    updateNotificationRule,
    deleteSubscriptionInProgress,
  };
}
