import apolloClient from "src/state/apolloClient";
import {
  CreateThemeDocument,
  CreateThemeMutation,
  CreateThemeMutationVariables,
  UpdateOnboardingChannelThemeDocument,
  UpdateOnboardingChannelThemeMutation,
  UpdateOnboardingChannelThemeMutationVariables,
} from "src/types.g";
import {
  generateColorVariants,
  generateTextColor,
} from "src/utils/colorConversions";
import { UUID } from "@screencloud/uuid";
import { ThemeConfig } from "../config/theme";

export const createTheme = async (theme: ThemeConfig) => {
  const { primaryColor, secondaryColor, bodyFont, headingFont, name } = theme;
  const pmColor = generateColorVariants(primaryColor);
  const sdColor = generateColorVariants(secondaryColor);
  const variables: CreateThemeMutationVariables = {
    input: {
      config: {
        bodyFont,
        headingFont,
        primaryColor: pmColor,
        secondaryColor: sdColor,
        textOnPrimary: generateTextColor(pmColor),
        textOnSecondary: generateTextColor(sdColor),
      },
      name,
    },
  };
  const { data } = await apolloClient.mutate<
    CreateThemeMutation,
    CreateThemeMutationVariables
  >({
    mutation: CreateThemeDocument,
    variables,
  });
  return data?.createTheme?.theme;
};

export const updateChannelTheme = async (id: UUID, themeId: UUID) => {
  const variables: UpdateOnboardingChannelThemeMutationVariables = {
    input: {
      id,
      themeId,
    },
  };
  const { data } = await apolloClient.mutate<
    UpdateOnboardingChannelThemeMutation,
    UpdateOnboardingChannelThemeMutationVariables
  >({
    mutation: UpdateOnboardingChannelThemeDocument,
    variables,
  });
  return data?.updateChannelTheme?.channel;
};
