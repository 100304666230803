import { PromisePool } from "@supercharge/promise-pool";
import { UUID, genUuid } from "@screencloud/uuid";
import { RefType } from "src/constants/constants";

export type CotentRefObject = {
  content: {
    _ref: {
      id: string;
      type: RefType;
    };
    props: {};
  };
  list_id: string;
  rules: never[];
};

export const createObjectReferenceByType = ({
  id,
  type,
  props,
}: {
  id: UUID;
  type: RefType;
  props?: { duration: number };
}): CotentRefObject => {
  const contentRef = {
    content: {
      _ref: {
        id,
        type,
      },
      props: props ?? {},
    },
    list_id: genUuid(),
    rules: [],
  };
  return contentRef;
};

export const executePromise = async (
  promiseArray: Promise<any>[],
  maxPool?: number
) => {
  const MAX_PROMISE_POOL = maxPool ?? 3;
  const data = await PromisePool.withConcurrency(MAX_PROMISE_POOL)
    .for(promiseArray)
    .handleError((error, _, pool) => {
      if (error) {
        console.error(error);
        return pool.stop();
      }
    })
    .process(async (promise) => {
      const p = await promise;
      return p;
    });

  return data;
};
