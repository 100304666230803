import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const LayoutContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  grid-template-rows: 0.1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .actions-grid-section {
    grid-area: 1 / 1 / 2 / 2;
    border-bottom: 1px solid ${Theme.color.lightGrey};
    border-right: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;

    .overall-actions {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: ${Theme.color.white};
      justify-content: center;
      font-weight: bold;

      &.header {
        font-weight: bold;
      }

      button,
      button:active {
        height: 30px;
        font-size: 14px;

        &.spaced {
          margin-top: 10px;
        }
      }
    }
  }

  .navigator-grid-section {
    grid-area: 1 / 2 / 2 / 3;
    border-bottom: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;

    > div {
      padding: 10px 20px;
    }
  }

  .controls-grid-section {
    grid-area: 2 / 1 / 3 / 2;
    border-right: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;
    overflow: hidden;
  }

  .pane-grid-section {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
  }

  .configuration-grid-section {
    grid-area: 3 / 1 / 3 / 2;
    border-top: 1px solid ${Theme.color.lightGrey};
    border-right: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ConfigurationControlPanel = styled.div`
  padding: 10px;

  .compute-options {
    border: 1px solid ${Theme.color.lightGrey};
    font-weight: bold;
    text-align: center;
    width: 100px !important;
    padding: 10px;
    font-size: 0.875em;
  }
`;

export const RecordingControlPanel = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .recording {
    background-color: ${Theme.color.red};
    color: ${Theme.color.white};

    &:hover {
      background-color: ${Theme.color.redHover};
      color: ${Theme.color.white};
    }

    &:focus {
      background-color: ${Theme.color.redHover};
      color: ${Theme.color.white};
    }

    &-icon {
      color: ${Theme.color.white}!important;
      background-color: ${Theme.color.white}!important;
    }
  }
`;

export const NavigationControlPanel = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .url {
    flex-grow: 1;
  }

  .url-options {
    width: 150px !important;
  }

  .controls {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .width-options {
    margin-right: 20px;
    width: 120px !important;
  }
`;

export const ActionsControlPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${Theme.color.nearWhite};

  .separator {
    width: calc(100% - 40px);
    margin: 20px auto;
    height: 1px;
    background-color: #ccc;
  }

  .controls {
    border-bottom: 1px solid ${Theme.color.lightGrey};
    padding-top: 20px;

    .panel {
      padding-left: 20px;
      padding-right: 20px;
    }

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
    }

    .action-buttons {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .btn {
        margin-right: 8px;
        margin-bottom: 8px;

        &.full-width {
          width: 100%;
        }
      }
    }
  }

  .actions,
  .overall-actions {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    button,
    button:active {
      height: 30px;
      font-size: 14px;

      &.spaced {
        margin-top: 10px;
      }
    }

    .clear-actions {
      padding: 20px;
    }

    .panel {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    .header {
      display: flex;
      justify-content: flex-start;
    }

    .footer {
      display: flex;
      justify-content: center;
      border-top: 1px solid ${Theme.color.lightGrey};
      padding-top: 20px;

      .button {
        background-color: var(--color-primary-background);
      }
    }
  }

  .overall-actions {
    padding: 20px;
    background-color: ${Theme.color.white};
    border-bottom: 1px solid ${Theme.color.lightGrey};
  }
`;

export const SiteFramePanel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  :empty {
    :before {
      content: "Enter a url to start";
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${Theme.color.darkGrey};
    }
  }

  .frame {
    flex-grow: 1;
    border: none;
  }

  .popupMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 16px;
    }
  }

  .url-blocked-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .url-blocked-message {
    margin-bottom: 16px;
  }

  .sidePanel > span {
    padding: 24px 15px;
    ${Theme.default.borderRadius};
    background-color: ${Theme.color.silver};
  }
`;

export const ActionListContainer = styled.div`
  overflow-y: auto;
`;

export const ActionContainer = styled.div`
  display: flex;
  background-color: ${Theme.color.white};
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${Theme.color.lightGrey};

  .index {
    flex: 0 40px;
    text-align: center;
    color: ${Theme.color.grey};
  }

  .container {
    background-color: ${Theme.color.white};
    overflow: hidden;

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    word-break: break-word;

    .icon {
      background-color: ${Theme.color.lightGrey};
      width: 12px;
      height: 12px;
    }

    .drag-control {
      background-color: ${Theme.color.silver};
      cursor: pointer;

      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 4px;
      }

      &:hover {
        cursor: grab;
      }
    }

    .details {
      flex: 1;
    }

    .settings-control,
    .delete-control {
      .icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
        &:hover {
          background-color: ${Theme.color.grey};
        }
      }
    }

    .delete-control {
      cursor: pointer;
      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 3px;
      }

      &:hover {
        background-color: ${Theme.color.redHover};

        .icon {
          background-color: ${Theme.color.white};
        }
      }
    }

    .action-end {
      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ActionEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  h2 {
    font-size: 16px;
  }

  .action-info-text {
    padding-bottom: 16px;
  }

  .editor-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .field-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      .field-name {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.36;
      }
    }

    .label {
      width: 100px !important;
    }
  }

  .editor-button,
  .editor-inline-button {
    font-size: 14px;
    line-height: 1.5;
  }

  .editor-button {
    margin: 0 0 8px 0 !important;
  }

  .editor-button.help-button {
    margin: 8px 0 8px 0 !important;
  }

  .help-strip {
    border: 1px solid ${Theme.color.lightGrey};
    width: 99%;
  }
`;

export const LoopControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  .index {
    flex: 0 40px;
    font-weight: bold;
    text-align: center;
  }

  .container {
    background-color: var(--color-primary-background);
    border: 1px solid var(--color-primary-background);
    ${Theme.default.borderRadius};
    overflow: hidden;

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    word-break: break-word;

    .drag-control {
      background-color: var(--color-primary-background);
      cursor: pointer;

      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 4px;
        background-color: #444;
      }

      &:hover {
        cursor: grab;
      }
    }

    .details {
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;

      font-size: 14px;
      line-height: 1.4;
      font-weight: bold;
      cursor: pointer;

      .detail {
        font-weight: normal;
        margin-top: 2px;
      }
    }

    .delete-control {
      background-color: var(--color-primary-background);
      cursor: pointer;

      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 3px;
        background-color: #444;
      }

      &:hover {
        background-color: ${Theme.color.redHover};

        .icon {
          background-color: ${Theme.color.white};
        }
      }
    }
  }
`;

export const ActionDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 8px;
  align-items: center;

  line-height: 1.4;

  .icon {
    margin-right: 8px;
  }

  .details {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 16px;
    }

    .description {
      color: #888;
      margin-top: 2px;
      font-size: 12px;
    }
  }
`;

interface DescriptionProps {
  isErrorTypeAndDescription: boolean;
}

export const Description = styled.span<DescriptionProps>`
  color: ${(props) =>
    props.isErrorTypeAndDescription ? Theme.color.error : "initial"};
`;

export const PreviewSubMessage = styled.div`
  position: absolute;
  bottom: 21px;
  padding: 13px 8px;
  border: 1px solid ${Theme.color.borderSection};
  ${Theme.default.borderRadius};
  display: flex;
  align-items: center;
  background-color: ${Theme.color.nearWhite};

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    bottom: 86px;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    margin: 0 20px;
  }
`;

export const DashboardPopoverHeader = styled.div`
  margin: 14px 8px;
`;

export const DashboardPopoverContent = styled.div`
  margin: 14px 8px 10px;

  div.dismiss {
    margin-top: 14px;
    text-align: right;

    a {
      color: ${Theme.color.lightGrey};
      cursor: pointer;

      &:hover {
        color: ${Theme.color.white};
      }
    }
  }
`;
