import { UUID } from "@screencloud/uuid";
import {
  Button,
  Icon,
  LoaderBar,
  Search,
} from "@screencloud/screencloud-ui-components";
import classNames from "clsx";
import { isEqual, orderBy } from "lodash";
import * as React from "react";
import { DragDropContext as DragDropBeautifulContext } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../../AppContextProvider/AppContext";
import {
  AppInstance,
  File,
  FilesOrderBy,
  Folder,
  FolderByIdDocument,
  FolderByIdQuery,
  FolderByIdQueryVariables,
  FoldersOrderBy,
  Link,
  Maybe,
  Playlist,
} from "../../types.g";

import MediaBreadCrumb from "../../components/Media/mediaBreadCrumb";
import {
  MediaOrderBy,
  RefType,
  SortingActions,
  Typenames,
} from "../../constants/constants";

import { MediaSharedFolder } from "../../components/Media/mediaSharedFolder";
import EmptyState from "../EmptyState";
import { MediaFileActions } from "../Media/mediaFile";
import { MediaFolderActions } from "../Media/mediaFolder";
import MediaItems from "../Media/mediaItems";

import store from "store";
import { SHARE_BY_OTHER_FOLDER_ID } from "../../pages/Media/MediasList/mediaListHelper";
import { FolderByIdOwnProps, withFolderData } from "./apollo";
import client from "../../state/apolloClient";
import { ScreenPickerActions } from "../ScreenPicker";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { AppContextType } from "src/AppContextProvider/type";
import MediaUploadDnD from "../MediaUpload/mediaUploadDnD";

export interface State {
  customContainer: React.ReactNode | null;
  isFetchMore: boolean;
  mediaView: string;
  section: RefType;
  sortBy: SortingActions;
  isOrderByAscending: boolean;
  selectedPickerItems:
    | Partial<File>[]
    | Partial<Playlist>[]
    | Maybe<Partial<Link>>[]
    | Partial<AppInstance>[];
}

export interface MediaPickerComponentProps {
  handleSelectedPickerItem: (screeenPickerAction: ScreenPickerActions) => void;
  callBack: (
    mediaId: string[],
    mediaType: RefType,
    data:
      | Partial<File>[]
      | Partial<Playlist>[]
      | Maybe<Partial<Link>>[]
      | Partial<AppInstance>[]
  ) => void;
  action: MediaPickerActionMode;
  allowMediaMimeType?: string[];
  multiple?: boolean;
  menu?: RefType[];
  selected?: string;
  section?: RefType;
  router?: any;
  isSelectionActive?: boolean;
  checkboxStyle?: CheckBoxStyle;
  spaceId?: UUID;
}

export enum CheckBoxStyle {
  CIRCLE = "CIRCLE",
  SQUARE = "SQUARE",
}

export enum MediaPickerActionMode {
  ADD = "ADD",
  CAST = "CAST",
  CHOOSE = "CHOOSE",
  VIEW = "VIEW",
  SET_CONTENT = "SET_CONTENT",
  REPLACE = "REPLACE",
}
class MediaPickerComponent extends React.PureComponent<
  MediaPickerComponentProps & FolderByIdOwnProps,
  State
> {
  public static contextType = AppContext;
  public static defaultProps: Partial<MediaPickerComponentProps> = {
    menu: [RefType.PLAYLIST, RefType.FILE, RefType.LINK, RefType.APP],
    // by deault media picker should have 4 sections
  };
  public context: AppContextType;
  constructor(props: MediaPickerComponentProps & FolderByIdOwnProps) {
    super(props);
    const selectedSection =
      props.selectedSection === store.get("selectedSection", RefType.PLAYLIST)
        ? props.selectedSection
        : store.get("selectedSection", RefType.PLAYLIST);
    this.state = {
      customContainer: null,
      isFetchMore: false,
      isOrderByAscending: true,
      mediaView: "list",
      section: this.props.section || selectedSection,
      selectedPickerItems: [],
      sortBy: SortingActions.SORT_BY_DATE,
    };
  }

  public componentDidUpdate() {
    if (this.props.selectedMediaFiles) {
      const files = this.props.selectedMediaFiles as File[];
      if (
        files.length > 0 &&
        !isEqual(this.state.selectedPickerItems, files) &&
        this.props.data?.folderById &&
        !this.props.loading
      ) {
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              selectedPickerItems: files,
            };
          },
          () => {
            this.props.updateSelectedMediaFiles([]);
            this.props.callBack(
              files.map((file) => file.id),
              RefType.FILE,
              files
            );
          }
        );
      }
    }
  }

  public componentWillUnmount() {
    this.props.updateSelectedMediaFiles([]);
  }

  public onFolderClick = async (
    folderId: string,
    action: MediaFolderActions
  ) => {
    const { updateFolderId } = this.props;
    if (action !== MediaFolderActions.REFETCH) {
      updateFolderId(folderId);
    }
  };

  public onMediaClick = (
    mediaId: string[],
    action: MediaFileActions,
    data: File[]
  ) => {
    if (action === MediaFileActions.CHECKED) {
      this.props.callBack(mediaId, RefType.FILE, data);
    }
  };

  public updateSelectedMediaFiles = async (files) => {
    return this.props.updateSelectedMediaFiles(files);
  };
  public onSelectMediaItem = async (mediaItems: (File | Folder)[]) => {
    const fileItems = mediaItems.filter(
      (media) => media.__typename === Typenames.File
    );
    const fileIds = fileItems.map((item) => item.id);
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedPickerItems: mediaItems as File[],
        };
      },
      () => {
        this.props.callBack(fileIds, RefType.FILE, mediaItems as File[]);
      }
    );
  };

  public onSelectPickerItems = (
    ids: string[],
    refType: RefType,
    pickerItems:
      | File[]
      | Partial<Playlist>[]
      | Maybe<Partial<Link>>[]
      | AppInstance[]
  ) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedPickerItems: pickerItems,
        };
      },
      () => {
        this.props.callBack(ids, refType, pickerItems);
      }
    );
  };

  public onBreadCrumbUpdate = async (folderId: string): Promise<void> => {
    const { updateFolderId } = this.props;
    updateFolderId(folderId);
  };

  public onMediaUploadSuccess = () => {
    // todo
  };

  public onSuccess = async (_files: File[], folderId: string) => {
    const queryVar: FolderByIdQueryVariables = {
      fileOrderBy: [MediaOrderBy.FILE_CREATED_AT_DESC],
      folderId,
      folderOrderBy: [MediaOrderBy.FOLDER_CREATED_AT_DESC],
      endCursor: null,
    };
    await client.query<FolderByIdQuery>({
      fetchPolicy: "network-only",
      query: FolderByIdDocument,
      variables: queryVar,
    });
  };

  public onUploadClick = () => {
    const { folderId, folderPathItems } = this.props;
    const pickerTitle =
      folderPathItems.length === 0
        ? "Library"
        : folderPathItems[folderPathItems.length - 1].name + " folder";
    this.props.router.push({
      component: (
        <MediaUploadDnD
          options={{
            folderId,
            fullwidth: true,
          }}
          onSuccess={(files: File[]) => {
            this.onSuccess(files, folderId);
          }}
        />
      ),
      props: {},
      title: "Uploading to " + pickerTitle,
    });
  };

  public toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (this.state.sortBy === action) {
      this.setState((prevState: State) => ({
        ...prevState,
        isOrderByAscending: !prevState.isOrderByAscending,
        sortBy: action,
      }));
    } else {
      this.setState({ isOrderByAscending: true, sortBy: action });
    }
    let fileOrderBy = FilesOrderBy.CreatedAtDesc;
    let folderOrderBy = FoldersOrderBy.CreatedAtDesc;

    switch (this.state.sortBy) {
      case SortingActions.SORT_BY_NAME:
        fileOrderBy = this.state.isOrderByAscending
          ? FilesOrderBy.NameAsc
          : FilesOrderBy.NameDesc;
        folderOrderBy = this.state.isOrderByAscending
          ? FoldersOrderBy.NameAsc
          : FoldersOrderBy.NameDesc;
        break;
      case SortingActions.SORT_BY_STARRED:

      /** Note
         No serverside supoort for order by star yet if we implement this will cause the ordering problem when fetch more data from server
       **/

      // FoldersOrderBy.
      // if (this.state.isOrderByAscending) {
      //   return [orderBy(folders, ['isFavorite'], ['desc']), orderBy(files, ['isFavorite'], ['desc'])]
      // } else {
      //   return [orderBy(folders, ['isFavorite'], ['asc']), orderBy(files, ['isFavorite'], ['asc'])]
      // }
      case SortingActions.SORT_BY_DATE:
      default:
        // in any case other than name fall back to default

        fileOrderBy = FilesOrderBy.CreatedAtDesc;
        folderOrderBy = FoldersOrderBy.CreatedAtDesc;
        break;
    }
    this.props.refetch({
      fileOrderBy: [fileOrderBy],
      folderOrderBy: [folderOrderBy],
    });
  };

  public sortAndOrderMediaItems = (folders: Folder[], files: File[]) => {
    switch (this.state.sortBy) {
      case SortingActions.SORT_BY_NAME:
        if (this.state.isOrderByAscending) {
          return [
            orderBy(
              folders,
              [(folder) => folder!.name!.toLowerCase()],
              ["asc"]
            ),
            orderBy(files, [(file) => file!.name!.toLowerCase()], ["asc"]),
          ];
        } else {
          return [
            orderBy(
              folders,
              [(folder) => folder!.name!.toLowerCase()],
              ["desc"]
            ),
            orderBy(files, [(file) => file!.name!.toLowerCase()], ["desc"]),
          ];
        }
      case SortingActions.SORT_BY_STARRED:

      /** Note
         No serverside supoort for order by star yet if we implement this will cause the ordering problem when fetch more data from server
       **/
      // if (this.state.isOrderByAscending) {
      //   return [orderBy(folders, ['isFavorite'], ['desc']), orderBy(files, ['isFavorite'], ['desc'])]
      // } else {
      //   return [orderBy(folders, ['isFavorite'], ['asc']), orderBy(files, ['isFavorite'], ['asc'])]
      // }
      case SortingActions.SORT_BY_DATE:
      default:
        // any case other than name fallback to default
        const sortedFolders = orderBy(
          folders,
          [(folder) => folder.createdAt?.toLowerCase()],
          ["desc"]
        );
        const sortedFiles = orderBy(
          files,
          [(file) => file.createdAt?.toLowerCase()],
          ["desc"]
        );
        return [sortedFolders, sortedFiles];
    }
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public renderMediaContent = (): JSX.Element => {
    const {
      mediaSearch,
      isSearch,
      setMediaSearch,
      folderPathItems,
      updateFolderId,
      shouldShowSharedFolder,
      isInSharedFolderFromOtherSpaces,
      isLoading,
      mediaFiles,
      mediaFolders,
      showAddToPlaylists,
    } = this.props;

    const [orderedFolderItems, orderedFileItems] = this.sortAndOrderMediaItems(
      mediaFolders,
      mediaFiles
    );
    const isLoaded = !isLoading;
    const showMediaBreadCrumb = !isSearch;
    const canCreateMedia =
      !isInSharedFolderFromOtherSpaces &&
      this.context.currentPermissions.validateCurrentSpace("media", "create");
    const hasItems =
      mediaFiles.length > 0 ||
      mediaFolders.length > 0 ||
      shouldShowSharedFolder;
    return (
      <>
        <div className="media-content media">
          <div className="content-header">
            <Search
              className={classNames({ "media-search-box": canCreateMedia })}
              placeholder={this.context.intl.formatMessage({
                defaultMessage: "Search Media",
                id: "media.search_media",
              })}
              showNoResults={false}
              onChange={(e, data) =>
                setMediaSearch(data.value, this.props.spaceId)
              }
              value={mediaSearch}
              onClear={this.props.clearSearch}
            />
            {!isOrgWidePath() && canCreateMedia && (
              <Button
                primary
                onClick={this.onUploadClick}
                data-testid="upload-media-button"
              >
                Upload
              </Button>
            )}
          </div>
          <div className="container" id="media-picker-container">
            <div className="breadcrumb">
              {showMediaBreadCrumb && (
                <MediaBreadCrumb
                  libraryName="Library"
                  action={false}
                  folderPathItems={folderPathItems}
                  onUpdate={this.onBreadCrumbUpdate}
                />
              )}
            </div>
            {hasItems && (
              <div className="media-item-header subheader">
                <div className="media-core">
                  <div
                    className="media-alpha"
                    onClick={() =>
                      this.toggleSortAndOrderMediaItem(
                        SortingActions.SORT_BY_NAME
                      )
                    }
                  >
                    <FormattedMessage
                      id="subheader.label.name"
                      defaultMessage="Name"
                    />
                    {this.state.sortBy === SortingActions.SORT_BY_NAME &&
                      this.renderOrderCaretIcon()}
                  </div>

                  <div className="media-scheduled" />
                  <div className="media-casting" />
                  <div className="media-starred">
                    <FormattedMessage
                      id="subheader.label.starred"
                      defaultMessage="Starred"
                    />
                    {this.state.sortBy === SortingActions.SORT_BY_STARRED ? (
                      this.renderOrderCaretIcon()
                    ) : (
                      <div className="empty-icon" />
                    )}
                  </div>
                  <div className="media-duration">
                    <FormattedMessage
                      id="subheader.label.duration"
                      defaultMessage="Duration"
                    />
                  </div>
                  <div className="media-kind">
                    <FormattedMessage
                      id="subheader.label.kind"
                      defaultMessage="Kind"
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className={classNames("layout-list", { empty: !hasItems })}
              id="custom-lazy-container"
              ref={(el) => {
                if (el && !this.state.customContainer) {
                  this.setState({ customContainer: el });
                }
              }}
            >
              {hasItems ? (
                <>
                  <DragDropBeautifulContext onDragEnd={this.onDragEnd}>
                    {shouldShowSharedFolder && (
                      <MediaSharedFolder
                        isMediaPicker={true}
                        onClick={() => updateFolderId(SHARE_BY_OTHER_FOLDER_ID)}
                      />
                    )}
                    <MediaItems
                      customContainer={this.state.customContainer}
                      selectedMediaItems={
                        this.state.selectedPickerItems as (File | Folder)[]
                      }
                      files={orderedFileItems as File[]}
                      folders={orderedFolderItems as Folder[]}
                      isInPicker={true}
                      isMultiSelect={this.props.multiple ?? false}
                      isAutoMultiSelect={this.props.multiple ?? false}
                      isReadonly={true}
                      showFavorite={true}
                      canSelectFile={true}
                      canSelectFolder={false}
                      showAddToPlaylists={showAddToPlaylists}
                      onMediaFolderCallback={this.onFolderClick}
                      onMediaFileCallback={this.onMediaClick}
                      onSelectMediaItems={this.onSelectMediaItem}
                      isSelectionActive={this.props.isSelectionActive ?? true}
                      checkboxStyle={
                        this.props.checkboxStyle ?? CheckBoxStyle.SQUARE
                      }
                      targetContainerId="media-picker-container"
                    />
                  </DragDropBeautifulContext>
                  {this.props.renderFetchMorePickerButton}
                </>
              ) : (
                !hasItems &&
                isLoaded &&
                (isSearch ? (
                  <EmptyState section="search-media" className="empty">
                    <h3>
                      <FormattedMessage
                        id="common.search.no_results"
                        defaultMessage="We couldn’t find any matches"
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id="common.search.no_results_description"
                        defaultMessage="Try adjusting or using different search terms."
                      />
                    </p>
                  </EmptyState>
                ) : (
                  <EmptyState section="media" className="empty">
                    <h3>
                      <FormattedMessage
                        id="media.media_empty.empty_message"
                        defaultMessage="You're ready for the media"
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id="media.media_empty.help_message"
                        defaultMessage="This is your place to upload images, videos, presentations and other cool stuff that you want to share on your screens. "
                      />
                    </p>
                  </EmptyState>
                ))
              )}
            </div>
          </div>
        </div>
        {isLoading &&
          orderedFileItems.length === 0 &&
          orderedFolderItems.length === 0 && <LoaderBar />}
      </>
    );
  };

  public render() {
    return this.renderMediaContent();
  }

  private onDragEnd = () => {
    // do nothing
  };
}

export default withFolderData(
  MediaPickerComponent
) as React.ComponentType<MediaPickerComponentProps>;
