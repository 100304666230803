import { Button } from "@screencloud/screencloud-ui-components";
import { ElementData } from "@screencloud/secure-sites-extension-types";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { SiteContext } from "../sitecontext";
import { renderActionEditor } from "./actions/editor";
import { ActionList } from "./actions/list";
import { SiteRecorderAction, SiteRecorderActionType } from "./actions/models";
import { ActionMessage, ActionMessageType } from "./reducers";
import { ActionsControlPanel } from "./styles";

export interface ActionsControlProps {
  actions: SiteRecorderAction[];
  isSelectingElement: boolean;
  isRecording: boolean;
  selectedElement?: ElementData;
  editorType?: SiteRecorderActionType;
  editorAction?: SiteRecorderAction;
  dispatch: (action: ActionMessage) => void;
}

export const ActionsControl = (
  props: React.PropsWithChildren<ActionsControlProps>
) => {
  const siteContext = useContext(SiteContext);

  return (
    <ActionsControlPanel>
      {!props.editorType ? (
        <>
          {!!props.actions && (
            <div className="actions">
              <ActionList actions={props.actions} dispatch={props.dispatch} />
              <div className="clear-actions">
                <Button
                  disabled={siteContext.isPreview}
                  onClick={() =>
                    props.dispatch({
                      type: ActionMessageType.ClearAllActions,
                    })
                  }
                >
                  <FormattedMessage
                    id="ui_component.site.recorder.clear_actions"
                    defaultMessage="Clear Actions"
                  />
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        renderActionEditor(props.editorType, props.dispatch, props.editorAction)
      )}
    </ActionsControlPanel>
  );
};
