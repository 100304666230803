import { UUID } from "@screencloud/uuid";
import apolloClient from "src/state/apolloClient";
import {
  CreateOnboardingFileDocument,
  CreateOnboardingFileMutation,
  CreateOnboardingFileMutationVariables,
  CreateFolderDocument,
  CreateFolderMutation,
  CreateFolderMutationVariables,
  Folder,
  FilesOrderBy,
  FolderByIdDocument,
  FolderByIdQuery,
  FoldersOrderBy,
  FolderByIdQueryVariables,
  UpdateOnboardingFileByIdDocument,
  UpdateOnboardingFileByIdMutation,
  UpdateOnboardingFileByIdMutationVariables,
} from "src/types.g";
import { FileConfig } from "../config/media";
import { executePromise } from "../execute";

type CreateFilesInput = {
  spaceId: UUID | null;
  folderId?: UUID;
  files: FileConfig[];
};

export type OnboardingFile = {
  id: UUID;
  name: string;
  source: string;
};

export const createFiles = async ({
  spaceId,
  folderId,
  files,
}: CreateFilesInput): Promise<OnboardingFile[]> => {
  const createdFilesPromises = files.map(async (file) => {
    const { name, mimetype, source } = file;
    const fileInput: CreateOnboardingFileMutationVariables = {
      input: {
        folderId: folderId || null,
        mimetype,
        name,
        source,
        spaceId,
        tags: [],
      },
    };
    const { data } = await apolloClient.mutate<
      CreateOnboardingFileMutation,
      CreateOnboardingFileMutationVariables
    >({
      mutation: CreateOnboardingFileDocument,
      variables: fileInput,
    });
    return data?.createFile?.file as OnboardingFile;
  });

  const createdFiles = await executePromise(createdFilesPromises);

  return createdFiles.results as OnboardingFile[];
};

type UpdateFilesInput = {
  fileId: UUID;
  folderId?: UUID;
  name?: string;
};

export const updateFiles = async (
  fileInputs: UpdateFilesInput[]
): Promise<OnboardingFile[]> => {
  const updatedFilesPromises = fileInputs.map(async (file) => {
    const { name, folderId, fileId } = file;
    const fileInput: UpdateOnboardingFileByIdMutationVariables = {
      input: {
        fileId,
        folderId: folderId || null,
        name,
      },
    };
    const { data } = await apolloClient.mutate<
      UpdateOnboardingFileByIdMutation,
      UpdateOnboardingFileByIdMutationVariables
    >({
      mutation: UpdateOnboardingFileByIdDocument,
      variables: fileInput,
    });
    return data?.updateFileById?.file as OnboardingFile;
  });

  const updatedFiles = await executePromise(updatedFilesPromises);

  return updatedFiles.results as OnboardingFile[];
};

type CreateFolderParams = {
  spaceId: UUID;
  parentId?: UUID;
  name?: string;
};
export const createFolder = async ({
  spaceId,
  parentId,
  name = "Backgrounds",
}: CreateFolderParams) => {
  const createFolderInputs: CreateFolderMutationVariables = {
    input: { spaceId, parentId, name },
  };

  const { data } = await apolloClient.mutate<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >({
    mutation: CreateFolderDocument,
    variables: createFolderInputs,
  });

  return data?.createFolder?.folder as Folder;
};

export const getFolders = async (rootFolderId: UUID) => {
  const getFolderInput: FolderByIdQueryVariables = {
    folderId: rootFolderId,
    endCursor: null,
    fileOrderBy: [FilesOrderBy.CreatedAtDesc],
    folderOrderBy: [FoldersOrderBy.CreatedAtDesc],
  };

  const { data } = await apolloClient.query<
    FolderByIdQuery,
    FolderByIdQueryVariables
  >({
    query: FolderByIdDocument,
    variables: getFolderInput,
  });

  return data.folderById as Folder;
};
