import { genUuid } from "@screencloud/uuid";
import { AppInfo, AppInstances, Apps } from "../config/apps";
import { ChannelCoverImages, FileConfig, Media } from "../config/media";
import { Canvases } from "../config/canvases";
import { Playlists } from "../config/playlists";
import { ThemeConfig, themeMeta } from "./theme";
import { BrandInfo } from "../entities/brand";
import { getOrgRegionFromUrl } from "src/helpers/urlHelper";

export enum Channels {
  COMMUNICATE_WITH_EMPLOYEES = "Communicate with employees",
  IMPROVE_PRODUCTIVITY = "Improve Productivity",
  INCREASE_EMPLOYEE_ENGAGEMENT = "Increase Employee Engagement",
  PERFORMANCE_DASHBOARD = "Performance Dashboards",
}

enum ChannelContentType {
  PLAYLISTS = "playlists",
  IMAGES = "images",
  CANVASES = "canvases",
  APPS = "apps",
}

export type ChannelContent = {
  [ChannelContentType.IMAGES]: Media[];
  [ChannelContentType.CANVASES]: Canvases[];
  [ChannelContentType.APPS]: AppInfo[];
  [ChannelContentType.PLAYLISTS]: Playlists[];
};

export type ZoneContentList = {
  rules: Record<string, unknown>[];
  content: {
    _ref: {
      id: string;
      type: string;
    };
  };
  list_id: string;
};

export type Zone = {
  list: ZoneContentList[];
  props: {
    transition: Record<string, unknown>;
    sizing_type: Record<string, unknown>;
  };
};

export type ChannelMeta = {
  name: string;
  content: {
    [key: string]: ChannelContent;
  };
  layoutName: string;
  coverImage: FileConfig;
  zones: Record<string, unknown>;
  theme: ThemeConfig;
};

export type ChannelConfig = {
  [key in Channels]: ChannelMeta;
};

export const channelWithMeta = ({
  channelName,
  orgName,
  brandInfo,
}: {
  channelName: Channels;
  orgName: string;
  brandInfo: BrandInfo | undefined;
}): ChannelMeta => {
  const { isUs } = getOrgRegionFromUrl();
  const channelConfig: ChannelConfig = {
    [Channels.COMMUNICATE_WITH_EMPLOYEES]: {
      name: Channels.COMMUNICATE_WITH_EMPLOYEES,
      content: {
        zone1: {
          playlists: [Playlists.EMPLOYEE_RECOGNITION, Playlists.HS_MESSAGES],
          apps: [],
          images: [],
          canvases: [],
        },
        zone2: {
          playlists: [],
          apps: [
            {
              appName: Apps.WEATHER,
              appInstancesName: [AppInstances.WEATHER_INSTANCE],
            },
            {
              appName: Apps.NOTICEBOARD,
              appInstancesName: [AppInstances.EXAMPLE_NOTICE_3],
            },
            {
              appName: isUs ? Apps.SPORTS_LIVE_SCORES : "",
              appInstancesName: [isUs ? AppInstances.MLB_SCORES : ""],
            },
          ],
          images: [],
          canvases: [],
        },
        zone3: {
          playlists: [],
          apps: [
            {
              appName: Apps.NOTICEBOARD,
              appInstancesName: [
                AppInstances.EXAMPLE_NOTICE_1,
                AppInstances.EXAMPLE_NOTICE_2,
                AppInstances.EXAMPLE_NOTICE_3,
              ],
            },
          ],
          images: [],
          canvases: [Canvases.LOGO_HEADER],
        },
        zone4: {
          playlists: [],
          apps: [
            {
              appName: Apps.STOCKS,
              appInstancesName: [AppInstances.STOCKS],
            },
          ],
          images: [],
          canvases: [],
        },
        zone5: {
          playlists: [],
          apps: [
            {
              appName: Apps.CLOCK,
              appInstancesName: [AppInstances.LOCAL_TIME],
            },
          ],
          images: [],
          canvases: [],
        },
        zone6: {
          playlists: [],
          apps: [],
          images: [
            Media.MOTION_BACKGROUND_10,
            Media.BACKGROUND_107,
            Media.BACKGROUND_103,
            Media.BACKGROUND_104,
            Media.BACKGROUND_30,
            Media.BACKGROUND_100,
            Media.MOTION_BACKGROUND_9,
            Media.MOTION_BACKGROUND_6,
            Media.MOTION_BACKGROUND_1,
            Media.MOTION_BACKGROUND_2,
            Media.MOTION_BACKGROUND_5,
            Media.MOTION_BACKGROUND_7,
            Media.MOTION_BACKGROUND_3,
            Media.MOTION_BACKGROUND_4,
            Media.MOTION_BACKGROUND_8,
            Media.MOTION_BACKGROUND_9,
            Media.MOTION_BACKGROUND_10,
          ],
          canvases: [],
        },
      },
      layoutName: "common_layout_vanguard_landscape_5_zone_type_d",
      coverImage: ChannelCoverImages[Media.CHANNEL_COVER_1],
      zones: {
        zone1: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: false,
                    sun: false,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: AppInstances.MANUFACTURING_TODAY,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.EMPLOYEE_RECOGNITION,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#ffffff",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fit",
              video: "fit",
              document: "fit",
            },
          },
        },
        zone2: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: AppInstances.WEATHER_INSTANCE,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_3,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: isUs
                    ? AppInstances.MLB_SCORES
                    : AppInstances.PREMIER_LEAUGE_APP,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "slide",
              color: "#000000",
              duration: 500,
              direction: "left",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone3: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: Canvases.LOGO_HEADER,
                  type: "app",
                },
                props: {
                  duration: 10000,
                },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_1,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_2,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_3,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "slide",
              color: "#000000",
              duration: 500,
              direction: "up",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone4: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.STOCKS,
                  type: "app",
                },
                props: {
                  duration: 90000,
                },
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#000000",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone5: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.LOCAL_TIME,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone6: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_10,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_107,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_103,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_104,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_30,
                  type: "file",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_100,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_9,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_6,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_1,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_2,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_5,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_7,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_3,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_4,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_8,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_9,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_10,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#000000",
              duration: 2000,
              direction: "right",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
      },
      theme: { ...themeMeta(brandInfo, orgName) },
    },
    [Channels.IMPROVE_PRODUCTIVITY]: {
      name: Channels.IMPROVE_PRODUCTIVITY,
      content: {
        zone1: {
          apps: [],
          playlists: [Playlists.HS_MESSAGES, Playlists.EMPLOYEE_RECOGNITION],
          canvases: [],
          images: [],
        },
        zone2: {
          apps: [
            {
              appName: Apps.WEATHER,
              appInstancesName: [AppInstances.WEATHER_INSTANCE],
            },
            {
              appName: Apps.NOTICEBOARD,
              appInstancesName: [AppInstances.EXAMPLE_NOTICE_3],
            },
            {
              appName: Apps.COUNTDOWN_TIMER,
              appInstancesName: [AppInstances.EXAMPLE_COUNTDOWN],
            },
          ],
          playlists: [Playlists.SPORT_NEWS],
          canvases: [],
          images: [],
        },
        zone3: {
          apps: [
            {
              appName: Apps.NOTICEBOARD,
              appInstancesName: [
                AppInstances.EXAMPLE_NOTICE_1,
                AppInstances.EXAMPLE_NOTICE_2,
                AppInstances.EXAMPLE_NOTICE_3,
              ],
            },
          ],
          playlists: [],
          canvases: [Canvases.LOGO_HEADER],
          images: [],
        },
        zone4: {
          apps: [],
          playlists: [Playlists.BACKGROUND_PACK_DARK],
          canvases: [],
          images: [],
        },
      },
      layoutName: "common_layout_vanguard_landscape_4_zone_type_a",
      coverImage: ChannelCoverImages[Media.CHANNEL_COVER_2],
      zones: {
        zone1: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: Playlists.EMPLOYEE_RECOGNITION,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.HS_MESSAGES,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#ffffff",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fit",
              video: "fit",
              document: "fit",
            },
          },
        },
        zone2: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: AppInstances.WEATHER_INSTANCE,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.SPORT_NEWS,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_3,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_COUNTDOWN,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "slide",
              color: "#000000",
              duration: 500,
              direction: "left",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone3: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: Canvases.LOGO_HEADER,
                  type: "app",
                },
                props: {
                  duration: 10000,
                },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_1,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_2,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_3,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "slide",
              color: "#000000",
              duration: 500,
              direction: "up",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone4: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.BACKGROUND_PACK_DARK,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#000000",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
      },
      theme: { ...themeMeta(brandInfo, orgName) },
    },
    [Channels.INCREASE_EMPLOYEE_ENGAGEMENT]: {
      name: Channels.INCREASE_EMPLOYEE_ENGAGEMENT,
      content: {
        zone1: {
          apps: [],
          playlists: [Playlists.EMPLOYEE_RECOGNITION],
          canvases: [],
          images: [],
        },
        zone2: {
          apps: [
            {
              appName: Apps.WEATHER,
              appInstancesName: [AppInstances.WEATHER_INSTANCE],
            },
            {
              appName: Apps.NOTICEBOARD,
              appInstancesName: [
                AppInstances.EXAMPLE_NOTICE_3,
                AppInstances.EXAMPLE_NOTICE_2,
                AppInstances.EXAMPLE_NOTICE_1,
              ],
            },
            {
              appName: Apps.ENTERTAINMENT_AND_LIFESTYLE_NEWS,
              appInstancesName: [
                AppInstances.LIFESTYLE_AND_ENTERTAINMENT,
                AppInstances.PITCHFORK_NEWS,
              ],
            },
            {
              appName: Apps.NATURAL_WORLD_NEWS,
              appInstancesName: [AppInstances.SMITHSONIAN],
            },
            {
              appName: Apps.ON_THIS_DAY,
              appInstancesName: [AppInstances.BBC_ON_THIS_DAY],
            },
          ],
          playlists: [Playlists.SPORT_NEWS],
          canvases: [],
          images: [],
        },
        zone3: {
          apps: [
            {
              appName: Apps.NOTICEBOARD,
              appInstancesName: [
                AppInstances.EXAMPLE_NOTICE_3,
                AppInstances.EXAMPLE_NOTICE_2,
                AppInstances.EXAMPLE_NOTICE_1,
              ],
            },
          ],
          playlists: [],
          canvases: [Canvases.LOGO_HEADER],
          images: [],
        },
        zone4: {
          apps: [
            {
              appName: Apps.NATURAL_WORLD_NEWS,
              appInstancesName: [AppInstances.SMITHSONIAN],
            },
            {
              appName: Apps.HEADLINE_NEWS,
              appInstancesName: [
                AppInstances.GOOGLE_BUSINESS,
                AppInstances.CNN_NEWS,
              ],
            },
          ],
          playlists: [],
          canvases: [],
          images: [],
        },
        zone5: {
          apps: [
            {
              appName: Apps.CLOCK,
              appInstancesName: [AppInstances.LOCAL_TIME],
            },
          ],
          playlists: [],
          canvases: [],
          images: [],
        },
        zone6: {
          apps: [],
          playlists: [],
          canvases: [],
          images: [
            Media.MOTION_BACKGROUND_10,
            Media.BACKGROUND_101,
            Media.BACKGROUND_107,
            Media.BACKGROUND_103,
            Media.BACKGROUND_104,
            Media.BACKGROUND_30,
            Media.BACKGROUND_100,
            Media.MOTION_BACKGROUND_9,
          ],
        },
      },
      layoutName: "common_layout_vanguard_landscape_5_zone_type_d",
      coverImage: ChannelCoverImages[Media.CHANNEL_COVER_3],
      zones: {
        zone1: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.EMPLOYEE_RECOGNITION,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#ffffff",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fit",
              video: "fit",
              document: "fit",
            },
          },
        },
        zone2: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: AppInstances.WEATHER_INSTANCE,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_3,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.SPORT_NEWS,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.LIFESTYLE_AND_ENTERTAINMENT,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_2,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.PITCHFORK_NEWS,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_1,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.SMITHSONIAN,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.BBC_ON_THIS_DAY,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "slide",
              color: "#000000",
              duration: 500,
              direction: "left",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone3: {
          list: [
            {
              rules: [
                {
                  _key: 0,
                  date: [],
                  time: [],
                  exclusive: false,
                  day_of_week: {
                    fri: true,
                    mon: true,
                    sat: true,
                    sun: true,
                    thu: true,
                    tue: true,
                    wed: true,
                  },
                  full_screen: false,
                  primary_audio: false,
                  specific_date: false,
                },
              ],
              content: {
                _ref: {
                  id: Canvases.LOGO_HEADER,
                  type: "app",
                },
                props: {
                  duration: 10000,
                },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_1,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Canvases.LOGO_HEADER,
                  type: "app",
                },
                props: {
                  duration: 10000,
                },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_2,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Canvases.LOGO_HEADER,
                  type: "app",
                },
                props: {
                  duration: 10000,
                },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.EXAMPLE_NOTICE_3,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "slide",
              color: "#000000",
              duration: 500,
              direction: "up",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone4: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.SMITHSONIAN,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.GOOGLE_BUSINESS,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.CNN_NEWS,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#000000",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone5: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: AppInstances.LOCAL_TIME,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone6: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_10,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_101,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_107,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_103,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_104,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_30,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.BACKGROUND_100,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
            {
              rules: [],
              content: {
                _ref: {
                  id: Media.MOTION_BACKGROUND_9,
                  type: "file",
                },
                props: { duration: 30000 },
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#000000",
              duration: 2000,
              direction: "right",
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
      },
      theme: { ...themeMeta(brandInfo, orgName) },
    },
    [Channels.PERFORMANCE_DASHBOARD]: {
      name: Channels.PERFORMANCE_DASHBOARD,
      content: {
        zone1: {
          apps: [],
          playlists: [],
          canvases: [Canvases.DISCOVER_DASHBOARDS],
          images: [],
        },
        zone2: {
          apps: [],
          playlists: [Playlists.BACKGROUND_PACK_DARK],
          canvases: [],
          images: [],
        },
        zone3: {
          apps: [],
          playlists: [Playlists.BACKGROUND_PACK_DARK],
          canvases: [],
          images: [],
        },
      },
      layoutName: "common_layout_vanguard_landscape_3_zone_type_c",
      coverImage: ChannelCoverImages[Media.CHANNEL_COVER_4],
      zones: {
        zone1: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Canvases.DISCOVER_DASHBOARDS,
                  type: "app",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#ffffff",
              duration: 500,
              direction: "right",
            },
            sizing_type: {
              image: "fit",
              video: "fit",
              document: "fit",
            },
          },
        },
        zone2: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.BACKGROUND_PACK_DARK,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "dissolve",
              color: "#000000",
              duration: 2000,
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
        zone3: {
          list: [
            {
              rules: [],
              content: {
                _ref: {
                  id: Playlists.BACKGROUND_PACK_DARK,
                  type: "playlist",
                },
                props: {},
              },
              list_id: genUuid(),
            },
          ],
          props: {
            transition: {
              type: "fade",
              color: "#000000",
              duration: 2000,
            },
            sizing_type: {
              image: "fill",
              video: "fill",
              document: "fill",
            },
          },
        },
      },
      theme: { ...themeMeta(brandInfo, orgName) },
    },
  };

  return channelConfig[channelName];
};
