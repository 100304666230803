import { RefType } from "src/constants/constants";
import {
  OnboardingAppInstance,
  createAppInstance,
  getAllApps,
  updateAppInstance,
} from "./app";
import { Apps } from "../config/apps";
import {
  CanvasConfig,
  Canvases,
  CanvasesConfig,
  canvasesWithMeta,
} from "../config/canvases";
import { find, uniqBy } from "lodash";
import { OnboardingFile, createFiles } from "./file";
import { UUID } from "@screencloud/uuid";
import {
  UpdateOnboardingAppInstanceMutationVariables,
  Maybe,
} from "src/types.g";
import { FileConfig } from "../config/media";
import { BrandInfo } from "./brand";
import { executePromise } from "../execute";

export const getUniqueCanvasThumbnail = (
  canvasesMeta: CanvasesConfig,
  canvasInstances: OnboardingAppInstance[]
): FileConfig[] => {
  const thumbnails: FileConfig[] = [];

  canvasInstances.forEach((canvas) => {
    const canvasMeta = canvasesMeta[canvas.name!];
    thumbnails.push(canvasMeta.thumbnail);
  });

  const uniqueThumbnails = uniqBy(thumbnails, "source");

  return uniqueThumbnails;
};

export const getThumbnailIdFromSource = (
  source: string,
  thumbnailFiles: OnboardingFile[]
): UUID => {
  const thumbnail = thumbnailFiles.find(
    (thumbnail) => thumbnail.source === source
  );
  return thumbnail?.id!;
};

const updateCanvasThumbnail = async ({
  canvasesMeta,
  canvasInstances,
  spaceId,
  folderId,
}: {
  canvasesMeta: CanvasesConfig;
  canvasInstances: OnboardingAppInstance[];
  spaceId: UUID;
  folderId?: UUID;
}) => {
  const thumbnails = getUniqueCanvasThumbnail(canvasesMeta, canvasInstances);
  const thumbnailFiles = await createFiles({
    spaceId,
    folderId,
    files: thumbnails,
  });

  const updatedCanvasesPromises = canvasInstances.map(async (createdCanvas) => {
    const canvasMeta = canvasesMeta[createdCanvas.name!];
    const thumbnailFileId = getThumbnailIdFromSource(
      canvasMeta.thumbnail.source,
      thumbnailFiles
    );
    const updateAppInstanceVar: UpdateOnboardingAppInstanceMutationVariables = {
      input: {
        config: createdCanvas.config,
        id: createdCanvas.id,
        name: createdCanvas.name,
        spaceId,
        state: {},
        version: canvasMeta.version,
        thumbnailFileId,
      },
    };

    return updateAppInstance(updateAppInstanceVar);
  });

  const updatedCanvases = await executePromise(updatedCanvasesPromises);
  return updatedCanvases.results as OnboardingAppInstance[];
};

const updateFileIdInCanvasConfig = async ({
  spaceId,
  canvasMeta,
}: {
  spaceId: UUID;
  canvasMeta: CanvasConfig;
}) => {
  const canvasImages = await createFileWithinCanvas({
    spaceId,
    images: canvasMeta.images,
  });
  const canvasInstance = mappingCanvasWithNewFileId(
    // replace the file id with the new created file id in canvas config
    canvasMeta,
    canvasImages
  );

  return canvasInstance;
};

export const mappingCanvasWithNewFileId = (
  canvas: CanvasConfig,
  files: { id: string; src: Maybe<string> }[]
) => {
  const newCanvases = {
    ...canvas,
    config: {
      ...canvas.config,
      artboards: canvas.config.artboards.map((artboard) => {
        let backgroundImage;
        if (artboard.backgroundImage) {
          backgroundImage = {
            ...artboard.backgroundImage,
            _ref: {
              id: files.find(
                (file) => file.src === artboard.backgroundImage?.src
              )?.id,
              type: RefType.FILE,
            },
          };
        }
        return {
          ...artboard,
          objects: artboard.objects.map((ob) => {
            if (ob._ref && ob.src?.includes("s3")) {
              return {
                ...ob,
                _ref: {
                  id:
                    files.find((file) => file.src === ob.src)?.id ?? ob._ref.id,
                  type: RefType.FILE,
                },
              };
            }
            return { ...ob };
          }),
          backgroundImage,
        };
      }),
    },
  };

  return newCanvases;
};

export const createFileWithinCanvas = async ({
  spaceId,
  images,
  folderId,
}: {
  spaceId: UUID;
  images: FileConfig[];
  folderId?: UUID;
}): Promise<{ id: UUID; src: Maybe<string> }[]> => {
  const createdFiles = await createFiles({
    spaceId,
    folderId,
    files: images,
  });
  return createdFiles.map((file) => ({ id: file.id, src: file.source }));
};

export const createCanvases = async ({
  canvases,
  spaceId,
  orgName,
  brandInfo,
}: {
  canvases: Canvases[];
  spaceId: UUID;
  orgName: string;
  brandInfo: BrandInfo | undefined;
}) => {
  const appName = Apps.CANVAS;
  const allApps = await getAllApps();
  const app = find(allApps, { name: appName }); // to get canvas install id
  const canvasesMeta = canvasesWithMeta(brandInfo, orgName);

  if (app) {
    const canvasInstallId = allApps.find((app) => app.name === Apps.CANVAS)
      ?.appInstallsByAppId.nodes[0].id;

    const createdCanvasesPromises = canvases
      .filter((canvasName) => {
        const canvasMeta = canvasesMeta[canvasName];
        return canvasMeta;
      })
      .map(async (canvasName) => {
        const canvasMeta = canvasesMeta[canvasName];
        let canvasInstance = canvasMeta;
        if (canvasMeta.images.length > 0) {
          // if canvas includes the images, the files should be created first
          canvasInstance = await updateFileIdInCanvasConfig({
            spaceId,
            canvasMeta,
          });
        }
        return createAppInstance(
          canvasInstallId,
          spaceId,
          canvasInstance.config,
          canvasName,
          canvasInstance.version
        );
      });

    const createdCanvases = await executePromise(createdCanvasesPromises);

    const updatedCanvases = await updateCanvasThumbnail({
      canvasesMeta,
      canvasInstances: createdCanvases.results.filter(
        Boolean
      ) as OnboardingAppInstance[],
      spaceId,
    });

    return updatedCanvases.filter(Boolean);
  } else {
    return undefined;
  }
};
