import { UUID } from "@screencloud/uuid";
import { DocumentNode } from "graphql";
import useScreenNotification from "src/hooks/ScreenNotification/useScreenNotification";
import {
  NotificationRule,
  useGetAllNotificationRulesByScreenIdQuery,
} from "src/types.g";
import AdminSettings from "./AdminSettings/adminSettings";
import PersonalNotification from "./PersonalNotification/personalNotification";
import SectionLabel from "./sectionLabel";
import { Styled } from "./styles";

interface Props {
  screenId: UUID;
  refetchQueries?: DocumentNode[];
  setIsRuleActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SetScreenNotificationPreferences(props: Props) {
  const {
    subscribeNotification,
    createNotificationRule,
  } = useScreenNotification(props.screenId, props.refetchQueries);
  const { data } = useGetAllNotificationRulesByScreenIdQuery({
    variables: {
      screenId: { equalTo: props.screenId },
    },
    fetchPolicy: "cache-first",
  });

  const notificationRule = data?.allNotificationRules
    ?.nodes[0] as NotificationRule;
  let notificationRuleId = notificationRule ? notificationRule.id : undefined;

  const onSubscribeNotification = async (userIds: UUID[]) => {
    if (!notificationRule) {
      const rule = await createNotificationRule(props.screenId, 0);
      notificationRuleId = rule?.createNotificationRule?.notificationRule?.id;
    }
    await subscribeNotification({ ruleId: notificationRuleId, userIds });
    props.setIsRuleActive(true);
  };

  return (
    <Styled className="set-screen-notification">
      <PersonalNotification
        onSubscribe={onSubscribeNotification}
        notificationRule={notificationRule}
        setIsRuleActive={props.setIsRuleActive}
      />
      <SectionLabel />
      <AdminSettings
        notificationRule={notificationRule}
        subscribeNotification={onSubscribeNotification}
        screenId={props.screenId}
      />
    </Styled>
  );
}
