import { useEffect, useState } from "react";
import { Device, PreviewDeviceList } from "../constants/constants";
import devicesResolutions from "../constants/deviceResolutions";
import { normalizeTimeFormat } from "../helpers/datetimeHelper";
import spacetime from "spacetime";

export interface DeviceInfoItemProps {
  key: string;
  label: string;
  value: string | null | undefined;
}

export const getDeviceDimension = (
  deviceKey: string
): { width: number; height: number } => {
  return devicesResolutions[deviceKey]
    ? devicesResolutions[deviceKey]
    : { width: 0, height: 0 };
};

export const getDeviceKeyWidthAndHeight = (
  width: number,
  height: number
): string => {
  const landscape: boolean = width > height;
  let key: string;
  for (key of Object.keys(devicesResolutions)) {
    const item: { width: number; height: number } = devicesResolutions[key];
    if (landscape) {
      if (item.width === width && item.height === height) {
        return Device[key];
      }
    } else {
      if (item.width === height && item.height === width) {
        return Device[key];
      }
    }
  }
  return Device.CUSTOM;
};

export const getDevice = (
  deviceKey: string
): { key: Device; text: string; value: string } | undefined => {
  return PreviewDeviceList.find((item) => item.key === deviceKey);
};

export const isCustomDevice = (deviceName) => deviceName === Device.CUSTOM;

export const calculatePercentUsage = (available: number, total: number) => {
  if (total <= 0) {
    return null;
  }

  const percent = Math.round(((total - available) / total) * 100);

  return Math.min(100, Math.max(0, percent));
};

export const formatDailyRestartTime = (time: string) => {
  // normalize time on some device that return time in 0 - 24
  if (!time.includes("-")) {
    const date = new Date();
    date.setHours(parseInt(time), 0, 0, 0);
    const ampm = date.toLocaleTimeString().split(" ")[1];
    const hour = date.toTimeString().split(" ")[0].slice(0, 2);
    return `${hour}:00${ampm}-${hour}:45${ampm}`;
  }

  // normalize time on most device
  const timeArray = time.split("-");
  const startTime = timeArray[0];
  const endTime = timeArray[1];

  const startTimeFormatted = normalizeTimeFormat(startTime);
  const endTimeFormatted = normalizeTimeFormat(endTime);

  return `${startTimeFormatted}-${endTimeFormatted}`;
};

export const formatTimeDuration = (startup_datetime: string) => {
  // normalize time from '0 year 0 month 0 day 03:29 hour 22 second' format
  if (startup_datetime.includes("year")) {
    const timeArr = startup_datetime.split(" ");
    const day = timeArr[4];
    const hour = timeArr[6].split(":")[0];
    const minute = timeArr[6].split(":")[1];
    const second = timeArr[8];

    if (hour === "00" && minute === "00") {
      return `${second} second${parseInt(second) > 1 ? "s" : ""}`;
    }

    return `${day}d ${hour}h ${minute}m`;
  }

  // normalize time from '2023-10-26T05:43:10.218Z' format
  const startupDate = new Date(startup_datetime);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - startupDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  const hoursDifference = timeDifference / (1000 * 3600);
  const minutesDifference = timeDifference / (1000 * 60);
  const secondsDifference = timeDifference / 1000;

  if (
    daysDifference === 0 &&
    hoursDifference === 0 &&
    minutesDifference === 0
  ) {
    return `${Math.floor(secondsDifference)} second${
      secondsDifference > 1 ? "s" : ""
    }`;
  }

  return `${Math.floor(daysDifference)}d ${Math.floor(
    hoursDifference % 24
  )}h ${Math.floor(minutesDifference % 60)}m`;
};

export const formatTemperature = (celsius: number) => {
  // check if celcius is not a number or unavailable
  if (isNaN(celsius) || celsius < 0) {
    return "N/A";
  }

  // default temperature format is celsius
  // convert celsius to fahrenheit
  const celsiusFormated = parseInt(celsius.toFixed(2));
  const fahrenheit = celsiusFormated * (9 / 5) + 32;

  if (navigator.language === "en-US") {
    return `${Math.round(fahrenheit)}°F`;
  }

  return `${Math.round(celsiusFormated)}°C`;
};

export const getDailyRestart = (deviceInfo) => {
  const dailyRestart = deviceInfo?.native_player?.settings?.daily_restart?.toString();
  const dailyRetartTime =
    deviceInfo?.native_player?.settings?.daily_restart_time;
  const dailyRestartValue =
    dailyRestart === "on" || dailyRestart === "true"
      ? `ON, at ${dailyRetartTime}`
      : dailyRestart === "off" || dailyRestart === "false"
      ? "OFF"
      : undefined;
  return dailyRestartValue;
};

export const isValidDeviceInfoItem = (props: DeviceInfoItemProps) => {
  if (!props) {
    return false;
  }

  const incorrectValues = [
    null,
    undefined,
    "",
    "N/A",
    "null",
    "undefined",
    "unavailable",
  ];
  function containsIncorrectKeyword(str: string) {
    return incorrectValues.some(
      (value) => String(value) && str.includes(String(value))
    );
  }

  const { value } = props;
  if (
    !value ||
    incorrectValues.includes(value) ||
    containsIncorrectKeyword(value)
  ) {
    return false;
  }

  return true;
};

export function parseStorageFromStringToKB(storageString?: string): number {
  if (!storageString) return 0;

  const storageValue = parseFloat(storageString);
  if (storageString.includes("GB")) {
    return storageValue * 1024 * 1024;
  } else if (storageString.includes("MB")) {
    return storageValue * 1024;
  } else if (storageString.includes("KB")) {
    return storageValue;
  } else if (storageString.includes("B")) {
    return storageValue / 1024;
  } else {
    return storageValue;
  }
}

export function convertKBToGB(kilobytes: number): number {
  return parseFloat((kilobytes / (1024 * 1024)).toFixed(2));
}

export const renderCurrentTimeFromTimezone = (timezone: string): string => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      return spacetime.now(timezone).format("time");
    };

    const interval = setInterval(() => {
      setCurrentTime(updateTime());
    }, 60000);

    setCurrentTime(updateTime()); // Initial update

    return () => clearInterval(interval);
  }, [timezone]);

  return currentTime;
};

export enum DEVICE_PLATFORM {
  AMAZON = "amazon",
  ANDROID = "android",
  CHROME_OS = "chromeos",
  MAC = "mac",
  OSX = "osx",
  IOS = "ios",
  LINUX = "linux",
  WINDOWS = "windows",
  BRIGHTSIGN = "brightsign",
  BROWSER = "browser",
  SCREENCLOUD_OS = "screencloudos",
  SAMSUNG_SSP = "samsungssp",
  LG_WEBOS = "lgwebos",
  OTHER = "other",
}
