import {
  Button,
  ButtonDropdown,
  DropdownItem,
  ModalSize,
} from "@screencloud/screencloud-ui-components";
import { UUID } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AppContextType } from "src/AppContextProvider/type";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import client, {
  filestackApiURL,
  resolveFileKey,
} from "../../helpers/filestackHelper";
import {
  getImageDimensions,
  trimToLessThan80Char,
} from "../../helpers/mediaHelper";
import { hasMismatchedCredentials } from "../../helpers/siteRecorderHelper";
import { PrimaryButton } from "../../helpers/whiteLabel";
import {
  CreateFileMutationVariables,
  CreateSiteMutationVariables,
  Maybe,
  Site,
  SiteListDocument,
  SiteType,
  SitesOrderBy,
  UpdateSiteByIdMutationVariables,
} from "../../types.g";
import FullScreenModalContent from "../FullScreenModal/FullScreenModalContent";
import FullScreenModalHeader from "../FullScreenModal/FullScreenModalHeader";
import FullScreenModalWrapper from "../FullScreenModal/FullScreenModalWrapper";
import SiteConfiguration from "../SiteConfigurationModal";
import { ApolloProps, withData } from "./apollo";
import DashboardIcon from "./dashboards_tile.svg";
import SiteContextProvider from "./sitecontext";
import { SiteRecorder } from "./SiteRecorder";
import {
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./SiteRecorder/actions/models";
import {
  ConfirmDashboardSetup,
  SiteRecorderErrorMessage,
  StyledWrapper,
} from "./styles";
import { FIRST_FETCH_ITEMS } from "src/constants/constants";

interface IState {
  name: string;
  actionMode: SiteRecorderActionMode;
  isDirty: boolean;
  actions: SiteRecorderAction[];
  url: string;
  thumbnailData?: string;
  computeType: string;
  computeSize: string;
  advancedSettingsConfig: any;
  setupConfirmed: boolean;
  site: Partial<Site> | undefined | null;
  extensionVersion: string;
}

enum SiteRecorderActionMode {
  Create = 0,
  Update = 1,
}

enum SaveType {
  SaveAndClose = 0,
  SaveAndPreview = 1,
}

export interface ISecureSiteModalProps extends ApolloProps {
  site: Maybe<Partial<Site>>;
}

export const isMissingSecretKey = (actions: SiteRecorderAction[]): boolean => {
  const otcActions = actions.filter(
    (a) => a.type === SiteRecorderActionType.EnterOneTimeCode
  );

  // both secret key and credential name values are required for the secret key to be valid
  const missingSecretKey = otcActions.filter(
    (a) => !a.config.secretKey || !a.config.credentialName
  );

  return !!missingSecretKey.length;
};

export const findDuplicateCredentialNames = (
  actions: SiteRecorderAction[]
): string => {
  const otcActions = actions.filter(
    (a) => a.type === SiteRecorderActionType.EnterOneTimeCode
  );

  // treat credential names as case-insensitive
  const otcCredentialNames = otcActions.map((a) =>
    a.config.credentialName.toLowerCase()
  );

  const duplicates = otcCredentialNames.filter(
    (value, index, self) => self.indexOf(value) !== index
  );

  // return the first duplicate name (if any) for the error
  return duplicates.length ? duplicates[0] : null;
};

class SiteRecorderModal extends React.Component<ISecureSiteModalProps, IState> {
  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: ISecureSiteModalProps) {
    super(props);

    this.state = {
      name: props.site?.name || "Dashboard",
      actionMode: props.site
        ? SiteRecorderActionMode.Update
        : SiteRecorderActionMode.Create,
      isDirty: false,
      actions: [],
      url: props.site?.url || "",
      thumbnailData: "",
      advancedSettingsConfig: {},
      computeType: "",
      computeSize: "medium",
      setupConfirmed: props.site ? true : false, // if site exists, don't repeat confirmation
      site: props.site,
      extensionVersion: "",
    };
  }

  public render() {
    const createButton = () => {
      let button;
      if (!!!this.state.site) {
        button = (
          <ButtonDropdown
            primary
            disabled={this.isSaveDisabled()}
            className="button-save"
            callBack={() => this.handleSave(SaveType.SaveAndPreview)}
            content={this.context.intl.formatMessage({
              defaultMessage: "Save & Preview",
              id: "common.text.save_and_preview",
            })}
          >
            <DropdownItem
              disabled={this.isSaveDisabled()}
              onClick={() => this.handleSave(SaveType.SaveAndClose)}
            >
              <span className="button-save">
                <FormattedMessage
                  id="common.text.save_and_close"
                  defaultMessage="Save &amp; Close"
                />
              </span>
            </DropdownItem>
          </ButtonDropdown>
        );
      } else {
        button = (
          <PrimaryButton className="button-close" onClick={this.handleClose}>
            <FormattedMessage id="common.text.close" defaultMessage="Close" />
          </PrimaryButton>
        );
      }
      return button;
    };

    return (
      <StyledWrapper>
        <FullScreenModalWrapper isPreview={true}>
          <FullScreenModalHeader
            disabledInline={this.state.site !== undefined}
            title="Site Recorder"
            appIcon={DashboardIcon}
            instanceName={this.state.name}
            textPlaceholder="Dashboard name"
            isEdit={this.state.actionMode !== SiteRecorderActionMode.Create}
            handleClose={this.handleClose}
            handleNameSaved={this.handleNameSaved}
            className="sites"
          >
            {createButton()}
          </FullScreenModalHeader>

          <FullScreenModalContent>
            <SiteContextProvider>
              <SiteRecorder
                site={this.state.site}
                onRecordingUpdated={this.onRecordingUpdated}
                onSiteConfigurationClick={this.onSiteConfigurationClick}
                setDashboardTitle={this.setDashboardTitle}
                dashboardTitle={this.state.name}
                setExtensionVersion={this.setExtensionVersion}
              />
            </SiteContextProvider>
          </FullScreenModalContent>
        </FullScreenModalWrapper>
      </StyledWrapper>
    );
  }

  private setDashboardTitle = (name) => this.setState({ name });

  private setExtensionVersion = (extensionVersion) =>
    this.setState({ extensionVersion });

  private onRecordingUpdated = (actions: SiteRecorderAction[]) => {
    let url = "";
    let isDirty = false;
    const navigateIndex = actions.findIndex(
      (action) => action.type === SiteRecorderActionType.Navigate
    );

    if (navigateIndex === 0 || navigateIndex === 1) {
      url = actions[navigateIndex].config.url;
      isDirty = url !== "";
    }

    this.setState({ isDirty, actions, url });
  };

  private onSiteConfigurationClick = () => {
    this.context.modal.openModal(
      <SiteConfiguration
        onSubmitConfiguration={this.onSubmitConfiguration}
        computeType={this.state.computeType}
        computeSize={this.state.computeSize}
        config={this.props.site ? this.props.site.config : null}
      />,
      "Advanced Settings",
      {
        opts: {
          closeOnDimmerClick: true,
          disableTitle: false,
          size: ModalSize.LARGE,
        },
      }
    );
  };

  private onSubmitConfiguration = (
    advancedSettingsConfig: any,
    computeType: string,
    computeSize: string
  ) => {
    this.setState({
      advancedSettingsConfig,
      computeSize,
      computeType,
    });
  };

  private handleClose = async () => {
    this.context.modal.closeFullscreenModal();
  };

  private handleNameSaved = (
    event: React.SyntheticEvent<any>,
    value: string
  ) => {
    this.setState({
      name: value,
    });
  };

  private validateActions = async (
    actions: SiteRecorderAction[]
  ): Promise<boolean> => {
    if (hasMismatchedCredentials(actions)) {
      await this.context.modal.confirm(
        <SiteRecorderErrorMessage>
          <h3>
            <FormattedMessage
              id="ui_component.site.recorder.mismatched_credentials_header"
              defaultMessage="Username / password mismatch"
            />
          </h3>
          <FormattedMessage
            id="ui_component.site.recorder.mismatched_credentials_text"
            defaultMessage="It looks like there is a mismatch in your username and password actions."
          />
          <FormattedMessage
            id="ui_component.site.recorder.mismatched_credentials_cta"
            defaultMessage="Please check within the action panel to make sure the username action has a matching password action and vice versa."
          />
          <FormattedMessage
            id="ui_component.site.recorder.mismatched_credentials_hint"
            defaultMessage="You can click the cog icon on the specific action to change its type."
          />
        </SiteRecorderErrorMessage>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.site.recorder.error_modal_confirm"
              defaultMessage="Got it!"
            />
          ),
          isAlert: true,
        }
      );
      return false;
    }

    if (isMissingSecretKey(actions)) {
      await this.context.modal.confirm(
        <SiteRecorderErrorMessage>
          <h3>
            <FormattedMessage
              id="ui_component.site.recorder.missing_secret_key_header"
              defaultMessage="2FA login requires secret key from Authenticator to complete setup"
            />
          </h3>
          <FormattedMessage
            id="ui_component.site.recorder.missing_secret_key_text"
            defaultMessage="For ScreenCloud Dashboards to access your site using 2FA we need your secret key. This can be obtained when setting up 2FA using your existing account or a new account."
          />
          <FormattedMessage
            id="ui_component.site.recorder.missing_secret_key_promise"
            defaultMessage="We store all credentials securely behind our E2E encryption service."
          />
          <FormattedMessage
            id="ui_component.site.recorder.missing_secret_key_more"
            defaultMessage="For more information about secret keys, please refer to our documentation."
          />
          <Button onClick={() => window.open("https://screen.cloud", "_blank")}>
            <FormattedMessage
              id="ui_component.site.recorder.documentation"
              defaultMessage="Documentation"
            />
          </Button>
        </SiteRecorderErrorMessage>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.site.recorder.error_modal_confirm"
              defaultMessage="Got it!"
            />
          ),
          isAlert: true,
        }
      );
      return false;
    }

    const duplicate = findDuplicateCredentialNames(actions);
    if (duplicate) {
      await this.context.modal.confirm(
        <SiteRecorderErrorMessage>
          <h3>
            <FormattedMessage
              id="ui_component.site.recorder.duplicate_credential_name_header"
              defaultMessage="2FA login requires unique credential names for each secret key"
            />
          </h3>
          <FormattedMessage
            id="ui_component.site.recorder.duplicate_credential_name_text"
            defaultMessage="It looks like you have used {credentialName} to identify multiple secret keys."
            values={{ credentialName: <strong>{duplicate}</strong> }}
          />
          <FormattedMessage
            id="ui_component.site.recorder.duplicate_credential_name_action"
            defaultMessage="Credential names cannot be duplicated. Please update these values so they’re unique."
          />
          <FormattedMessage
            id="ui_component.site.recorder.duplicate_credential_name_how"
            defaultMessage="You can click the cog icon next to the one time password step to change your credential name values."
          />
        </SiteRecorderErrorMessage>,
        {
          confirm: (
            <FormattedMessage
              id="ui_component.site.recorder.error_modal_confirm"
              defaultMessage="Got it!"
            />
          ),
          isAlert: true,
        }
      );
      return false;
    }

    return true;
  };

  private handleSave = async (saveType: SaveType) => {
    const { actionMode, actions, url, name, setupConfirmed } = this.state;
    const spaceId = this.context.user.settings.spaceId;

    const valid = await this.validateActions(actions);
    if (!valid) {
      return;
    }

    if (!setupConfirmed) {
      const { confirm } = await this.context.modal.confirm(
        <ConfirmDashboardSetup>
          <span>
            <strong>{name}</strong>
          </span>
          <FormattedMessage
            id="ui_component.site.recorder.confirm_setup_text"
            defaultMessage="Please confirm that setup has been completed for this dashboard. This cannot be changed after the dashboard has been saved."
          />
        </ConfirmDashboardSetup>,
        {
          header: (
            <FormattedMessage
              id="ui_component.site.recorder.confirm_setup_header"
              defaultMessage="Confirm Dashboard Setup"
            />
          ),
          confirm: (
            <FormattedMessage
              id="ui_component.common.label.confirm"
              defaultMessage="Confirm"
            />
          ),
          cancel: (
            <FormattedMessage
              id="ui_component.common.label.cancel"
              defaultMessage="Cancel"
            />
          ),
          isAlert: true,
        }
      );

      if (confirm) {
        this.setState({
          setupConfirmed: true,
        });
      } else {
        return;
      }
    }

    if (actionMode === SiteRecorderActionMode.Create) {
      // sanitise version as '.' not allowed in tags
      const version = this.state.extensionVersion.replaceAll(".", "-");
      const tags = [`extensionversion-${version}`];

      this.createSite(spaceId, actions, url, name, tags)
        .then((site: Site | undefined) => {
          if (site && saveType === SaveType.SaveAndPreview) {
            this.setState({ site });
            this.getThumbnail(site);
          } else {
            this.handleClose();
          }
        })
        .catch((reason) => {
          this.context.modal.confirm(
            <SiteRecorderErrorMessage>
              <FormattedMessage
                id="ui_component.site.recorder.save_failed"
                defaultMessage="Saving your dashboard journey failed. Please try again later, if the problem persists please contact support."
              />
            </SiteRecorderErrorMessage>,
            {
              confirm: (
                <FormattedMessage
                  id="ui_component.site.recorder.error_modal_confirm"
                  defaultMessage="Got it!"
                />
              ),
              isAlert: true,
            }
          );
        });
    } else {
      await this.updateSite(this.props.site);
      this.context.modal.closeFullscreenModal();
    }
  };

  private updateSite = async (
    site: Maybe<Partial<Site>>,
    thumbnailId?: string
  ) => {
    const updateSiteVariables: UpdateSiteByIdMutationVariables = {
      input: {
        id: site?.id,
        type: site?.type || SiteType.Secure,
        config: site?.config,
      },
    };

    if (thumbnailId) {
      updateSiteVariables.input.thumbnailId = thumbnailId;
    }
    if (this.state.advancedSettingsConfig) {
      updateSiteVariables.input.config = this.state.advancedSettingsConfig;
    }
    if (this.state.computeType) {
      updateSiteVariables.input.compute = this.getComputeObject();
    }
    if (this.state.name) {
      updateSiteVariables.input.name = this.state.name;
    }

    await this.props.updateSiteById({
      refetchQueries: this.getRefetchQueries(),
      variables: updateSiteVariables,
    });
  };

  private createSite = async (
    spaceId: UUID,
    actions: SiteRecorderAction[],
    url: string,
    name: string,
    tags?: string[]
  ): Promise<Site | undefined> => {
    const createSiteVariables: CreateSiteMutationVariables = {
      input: {
        spaceId,
        url,
        name,
        actions,
        type: SiteType.Secure,
        config: this.state.advancedSettingsConfig || {},
        compute: this.getComputeObject(),
        tags: tags || [],
      },
    };

    const result = await this.props.createSite({
      refetchQueries: this.getRefetchQueries(),
      variables: createSiteVariables,
    });
    return (result.data?.createSite?.site as Site) || undefined;
  };

  private getThumbnail = async (site: Site) => {
    const screenshotUrl = `${filestackApiURL}/urlscreenshot=mode:window,height:768,width:1366,delay:3000/${site.url}`;
    const response = await fetch(screenshotUrl);
    const blob = await response.blob();

    const base64 = await this.readFileAsync(blob);
    const thumbnailData = base64.toString();
    const uploadedFile = await client.upload(
      thumbnailData as string,
      {},
      {
        access: "public",
        container: appConfig.uploadsBucket,
        location: appConfig.uploadsLocation,
        path: "",
        region: appConfig.s3Region,
      }
    );
    let metadata = await resolveFileKey({
      ...uploadedFile,
      name: this.state.name,
    });

    if (uploadedFile.mimetype!.startsWith("image")) {
      const dimensions = await getImageDimensions(uploadedFile.handle);
      const { width, height } = await dimensions.json();
      metadata = { ...metadata, height, width };
    }

    const fileName = trimToLessThan80Char(this.state.name!);
    const createFileVariables: CreateFileMutationVariables = {
      input: {
        metadata,
        mimetype: uploadedFile.mimetype,
        name: fileName,
        size: uploadedFile.size,
        source: `https://${appConfig.uploadsBaseUrl}/${
          appConfig.uploadsBucket
        }/${escape(metadata.key!)}`,
        spaceId: site?.spaceId,
        tags: [],
      },
    };
    const {
      data: {
        createFile: {
          file: { id },
        },
      },
    } = (await this.props.createFile({
      variables: createFileVariables,
    })) as any;
    await this.updateSite(site, id);
  };

  private readFileAsync(blob: Blob): Promise<any> {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file"));
      };

      fileReader.onload = () => {
        const result = (fileReader && fileReader.result) || {};
        resolve(result);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  private getComputeObject = () => {
    const computeObj = {
      cpu: 512,
      memory: 1024,
    };
    if (this.state.computeType === "preset") {
      switch (this.state.computeSize) {
        case "small":
          computeObj.cpu = 256;
          computeObj.memory = 512;
          break;
        case "medium":
          computeObj.cpu = 512;
          computeObj.memory = 1024;
          break;
        case "large":
          computeObj.cpu = 1024;
          computeObj.memory = 2048;
          break;
        case "xlarge":
          computeObj.cpu = 2048;
          computeObj.memory = 4096;
          break;
      }
      return computeObj;
    } else {
      return computeObj;
    }
  };

  private getRefetchQueries = () => {
    return [
      {
        query: SiteListDocument,
        variables: {
          spaceId: this.context.currentSpace?.id,
          first: FIRST_FETCH_ITEMS,
          endCursor: null,
          orderBy: [SitesOrderBy.NameAsc, SitesOrderBy.IdDesc],
          filter: {
            spaceId: {
              equalTo: this.context.currentSpace?.id,
            },
          },
        },
      },
    ];
  };

  private isSaveDisabled = (): boolean => {
    return !this.state.isDirty;
  };
}

export default withData(SiteRecorderModal);
