import { Icon } from "@screencloud/screencloud-ui-components";
import * as React from "react";
import {
  ConnectDropTarget,
  DragElementWrapper,
  DragSource,
  DragSourceMonitor,
  DragSourceOptions,
  DropTarget,
  DropTargetMonitor,
} from "react-dnd";
import { findDOMNode } from "react-dom";
import { ActionMessage } from "../reducers";
import { LoopControlContainer } from "../styles";
import { renderAction } from "./factory";
import {
  LoopAction,
  SiteRecorderAction,
  SiteRecorderActionType,
} from "./models";

export enum ActionListItemType {
  Action = "action",
  StartLoop = "start-loop",
  EndLoop = "end-loop",
  ChildAction = "child-action",
}

export interface ActionListItemProps {
  index: number;
  type: ActionListItemType;
  action: SiteRecorderAction;
  dispatch: (action: ActionMessage) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onItemDropped: (originalIndex: number, newIndex: number) => void;
  removeItem: (index: number) => void;
  connectDragSource?: DragElementWrapper<DragSourceOptions>;
  connectDropTarget?: ConnectDropTarget;
  isDragging?: boolean;
  step?: string;
  selectItem: (index: number) => void;
}

interface DraggedActionListItem {
  index: number;
  originalIndex: number;
  type: ActionListItemType;
  action: SiteRecorderAction;
}

const dragSource = {
  canDrag(props: ActionListItemProps): boolean {
    if (props.action.type === SiteRecorderActionType.SessionKeepAlive) {
      return false;
    }

    if (
      props.action.type === SiteRecorderActionType.Navigate &&
      props.index < 2
    ) {
      return false;
    }

    if (props.action.name === "Take snapshot") {
      return false;
    }

    return true;
  },
  beginDrag(props: ActionListItemProps): DraggedActionListItem {
    return {
      index: props.index,
      originalIndex: props.index,
      type: props.type,
      action: props.action,
    };
  },
  endDrag(props: ActionListItemProps, monitor: DragSourceMonitor) {
    const { index, originalIndex } = monitor.getItem() as DraggedActionListItem;

    if (monitor.didDrop()) {
      props.onItemDropped(originalIndex, index);
    } else {
      props.moveItem(index, originalIndex);
    }
  },
};

const Draggable = DragSource<ActionListItemProps>(
  "action",
  dragSource,
  (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    };
  }
);

const dropTarget = {
  hover(
    props: ActionListItemProps,
    monitor: DropTargetMonitor,
    component: any
  ) {
    if (!component) {
      return;
    }

    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (
      dragIndex === hoverIndex ||
      props.action.type === SiteRecorderActionType.SessionKeepAlive ||
      (props.action.type === SiteRecorderActionType.Navigate && hoverIndex < 2)
    ) {
      return;
    }

    // Todo: lint
    // Do not use findDOMNode. It doesn’t work with function components and is deprecated in StrictMode.
    //
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = (findDOMNode(
      component
    ) as Element).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

    if (
      (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
      (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
    ) {
      return;
    }

    props.moveItem(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const Droppable = DropTarget<ActionListItemProps>(
  "action",
  dropTarget,
  (connect) => {
    return {
      connectDropTarget: connect.dropTarget(),
    };
  }
);

const BaseActionListItem: React.FC<ActionListItemProps> = (props) => {
  if (!props.connectDragSource || !props.connectDropTarget) {
    return null;
  }

  let item: JSX.Element | undefined;

  switch (props.type) {
    case ActionListItemType.StartLoop:
      item = (
        <LoopControl
          action={props.action}
          index={props.index}
          step={props.step}
          removeItem={props.removeItem}
          selectItem={props.selectItem}
        />
      );
      break;
    case ActionListItemType.EndLoop:
      item = (
        <LoopControl
          action={props.action}
          index={props.index}
          removeItem={props.removeItem}
          selectItem={props.selectItem}
          end
        />
      );
      break;
    default:
      item = renderAction(
        props.action,
        props.index,
        props.removeItem,
        props.selectItem,
        props.step
      );
  }

  const isChildAction = props.type === ActionListItemType.ChildAction;

  return props.connectDropTarget(
    props.connectDragSource(
      <div
        style={{
          opacity: props.isDragging ? 0 : 0.99,
          marginLeft: isChildAction ? 42 : 0,
          borderLeft: isChildAction ? `5px dashed #fecf00` : `none`,
        }}
      >
        {item}
      </div>
    )
  );
};

export const ActionListItem = Droppable(Draggable(BaseActionListItem));

interface LoopControlProps {
  action: LoopAction;
  step?: string;
  end?: boolean;
  index: number;
  removeItem: (index: number) => void;
  selectItem: (index: number) => void;
}

const LoopControl: React.FC<LoopControlProps> = ({
  action,
  step,
  end,
  index,
  removeItem,
  selectItem,
}) => {
  return (
    <LoopControlContainer>
      <div className="index">{step}</div>
      <div className="container">
        <div className="drag-control">
          <Icon name="drag" className="icon" />
        </div>
        <div className="details" onClick={() => selectItem(index)}>
          <div className="name">{end ? "End" : "Start"} loop</div>
          <div className="detail">
            Repeat {action.config.loops} time{action.config.loops > 1 && "s"}
          </div>
        </div>
        <div className="delete-control" onClick={() => removeItem(index)}>
          <Icon name="remove-fill" className="icon" />
        </div>
      </div>
    </LoopControlContainer>
  );
};
