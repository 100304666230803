import { Button, Icon } from "@screencloud/screencloud-ui-components";

import { isUuid, UUID } from "@screencloud/uuid";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import store from "store";
import { appConfig } from "../../appConfig";
import { AppContext } from "../../AppContextProvider/AppContext";
import { CreateLinkPayload } from "../../components/LinkPicker/create";
import { RefType, SortingActions } from "../../constants/constants";
import {
  getImageDimensions,
  trimToLessThan80Char,
} from "../../helpers/mediaHelper";
import {
  AppInstance,
  Channel,
  CreateFileMutationVariables,
  CreateLinkMutationVariables,
  File,
  Link,
  LinkListDocument,
  LinkListQueryVariables,
  LinkType,
  Maybe,
  Playlist,
  Site,
  UpdateLinkByIdMutationVariables,
} from "../../types.g";

import AppPickerComponent from "../AppPicker";
import CanvasPickerComponent from "../CanvasPicker";
import ChannelPicker from "../ChannelPicker";
import LinkPickerComponent from "../LinkContentPicker";
import PlaylistPickerComponent from "../PlaylistPicker";
import RecentPicker from "../RecentPicker";
import SitePickerComponent from "../SitePickerComponent";
import { PickerProps, withData } from "./apollo";
import MediaPickerComponent, {
  CheckBoxStyle,
  MediaPickerActionMode,
} from "./media";
import { StyledMediaPickerContainer } from "./styles";

import client, { resolveFileKey } from "../../helpers/filestackHelper";
import { PrimaryButton } from "../../helpers/whiteLabel";
import { LinkListHeaderActions } from "../LinkListHeader";
import { ScreenPickerActions } from "../ScreenPicker";
import { ContentSettingGroup } from "../ScreenPicker/contentSettingGroup";
import { isOrgWidePath } from "src/utils/orgWideFeature";
import { AppContextType } from "src/AppContextProvider/type";

type MediaPickerItems =
  | Channel[]
  | File[]
  | Playlist[]
  | Link[]
  | AppInstance[];
export interface State {
  mediaId: string[];
  mediaType: RefType;
  mediaData: MediaPickerItems;
  section: RefType;
  sortBy: SortingActions;
  isOrderByAscending: boolean;
  screenPickerAction: ScreenPickerActions;
  selectedPickerItems: MediaPickerItems;
}

export type MediaPickerContainerSelectedItems =
  | Maybe<Partial<Channel>>[]
  | Partial<File>[]
  | Partial<Playlist>[]
  | Maybe<Partial<Link>>[]
  | Maybe<Partial<Site>>[]
  | Partial<AppInstance>[];

export interface MediaPickerContainerComponentProps {
  handleSelectedPickerItem: (screenPickerAction: ScreenPickerActions) => void;
  callBack: (
    mediaId: string[],
    mediaType: RefType,
    data: MediaPickerContainerSelectedItems,
    expiresAt?: Date
  ) => void;
  onRefetchRootMedia?: () => void;
  action: MediaPickerActionMode;
  multiple?: boolean;
  menu: RefType[];
  allowMediaMimeType?: string[];
  selected?: string;
  section?: RefType;
  router?: any;
  screenId?: UUID;
  selectedSection?: RefType;
  screenPickerAction: ScreenPickerActions;
  spaceId?: UUID;
}

class MediaPickerContainerComponent extends React.PureComponent<
  MediaPickerContainerComponentProps & PickerProps,
  State
> {
  public static defaultProps: Partial<MediaPickerContainerComponentProps> = {
    menu: [
      RefType.PLAYLIST,
      RefType.FILE,
      RefType.LINK,
      RefType.SITE,
      RefType.CANVAS,
      RefType.APP,
    ],
    screenPickerAction: ScreenPickerActions.SET_CONTENT,
  };

  public static contextType = AppContext;
  public context: AppContextType;

  constructor(props: MediaPickerContainerComponentProps & PickerProps) {
    super(props);

    // TODO - Sites Media picker behind feature flag

    const selectedSection =
      this.props.selectedSection ===
      store.get(
        "selectedSection",
        props.menu?.includes(RefType.CHANNEL)
          ? RefType.CHANNEL
          : RefType.PLAYLIST
      )
        ? this.props.selectedSection
        : store.get(
            "selectedSection",
            props.menu?.includes(RefType.CHANNEL)
              ? RefType.CHANNEL
              : RefType.PLAYLIST
          );
    this.state = {
      screenPickerAction: props.screenPickerAction,
      isOrderByAscending: true,
      mediaId: [],
      mediaType: RefType.FILE,
      mediaData: [],
      section: props.section || this.getSection(selectedSection),
      selectedPickerItems: [],
      sortBy: SortingActions.SORT_BY_NAME,
    };
  }

  public getSection = (selectedSection: RefType) => {
    let index = 0;
    if (this.props.menu) {
      const getFinalSection = (menu) => {
        if (menu === selectedSection) {
          return menu;
        } else if (
          menu !== selectedSection &&
          index >= this.props.menu.length - 1
        ) {
          return this.props.menu![0];
        } else {
          index++;
          index =
            index >= this.props.menu.length - 1
              ? this.props.menu.length - 1
              : index;
          return getFinalSection(this.props.menu![index]);
        }
      };
      const finalSelectedSection =
        getFinalSection(this.props.menu[index]) || this.props.menu[0];
      return finalSelectedSection;
    }

    return this.props.menu![0];
  };

  public updateSelectedSection = async (section: RefType) => {
    store.set("selectedSection", section);
    this.props.updateSelectedSection(section);
  };

  public onSelectPickerItems = (
    ids: string[],
    refType: RefType,
    pickerItems: MediaPickerItems
  ) => {
    const { callBack } = this.props;

    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedPickerItems: pickerItems as MediaPickerItems,
        };
      },
      () => {
        this.setState({
          mediaId: ids,
          mediaType: refType,
          mediaData: pickerItems,
        });
        callBack(ids, refType, pickerItems);
      }
    );
  };

  public onMenuClick = async (section: RefType) => {
    this.setState((prevState) => ({
      ...prevState,
      section,
      selectedPickerItems: [],
    }));
    this.updateSelectedSection(section);
  };

  public toggleSortAndOrderMediaItem = (action: SortingActions) => {
    if (this.state.sortBy === action) {
      this.setState((prevState: State) => ({
        ...prevState,
        isOrderByAscending: !prevState.isOrderByAscending,
        sortBy: action,
      }));
    } else {
      this.setState({ isOrderByAscending: true, sortBy: action });
    }
  };

  public handleCreateLink = (param: CreateLinkPayload) => {
    this.createLink(param)
      .then(async (data: Link) => {
        this.props.router.gotoComponentByName("Media Picker", false);
        const uploadedFile = await client.upload(
          param.thumbUrl as string,
          {},
          {
            access: "public",
            container: appConfig.uploadsBucket,
            location: appConfig.uploadsLocation,
            path: "",
            region: appConfig.s3Region,
          }
        );

        let metadata = await resolveFileKey({
          ...uploadedFile,
          name: param.name,
        });

        if (uploadedFile.mimetype.startsWith("image")) {
          const dimensions = await getImageDimensions(uploadedFile.handle);
          const { width, height } = await dimensions.json();
          metadata = {
            ...metadata,
            height,
            width,
          };
        }

        const fileName = trimToLessThan80Char(param.name!);
        const spaceId = this.context.user.settings.spaceId;
        const fileInput: CreateFileMutationVariables = {
          input: {
            metadata,
            mimetype: uploadedFile.mimetype,
            name: fileName,
            size: uploadedFile.size,
            source: `https://${appConfig.uploadsBaseUrl}/${
              appConfig.uploadsBucket
            }/${escape(metadata.key!)}`,
            spaceId,
            tags: [],
          },
        };
        const createdFile = (await this.props.createFile({
          variables: fileInput,
        })) as any;
        const fileId =
          createdFile &&
          createdFile.data.createFile.file &&
          createdFile.data.createFile.file.id;

        if (isUuid(fileId)) {
          const updateLink: UpdateLinkByIdMutationVariables = {
            input: {
              fileId,
              id: data.id,
            },
          };
          const linkQueryVariables: LinkListQueryVariables = {
            spaceId,
            endCursor: null,
            orderBy: null,
          };
          await this.props.updateLinkById({
            refetchQueries: [
              {
                query: LinkListDocument,
                variables: linkQueryVariables,
              },
            ],
            variables: updateLink,
          });
        }
      })
      .catch((err) => {
        console.log("error while creating link", err);
      });
  };

  public createLink = (param: CreateLinkPayload) => {
    return new Promise((resolve, reject) => {
      const spaceId = this.context.user.settings.spaceId;
      if (!spaceId) {
        reject("no spaceid");
      }
      const linkTypeParam = param.isInternal
        ? LinkType.Internal
        : param.isCloudRendering
        ? LinkType.Cloud
        : LinkType.Standard;
      const createLinkInput: CreateLinkMutationVariables = {
        input: {
          cloudConfig: param.isCloudRendering
            ? { credential: param.credential }
            : {},
          linkType: linkTypeParam,
          name: param.name || "",
          spaceId,
          tags: param.tags,
          url: param.link || "",
        },
      };
      this.props
        .createLink(createLinkInput, LinkListHeaderActions.SORT_BY_DATE)
        .then((data) => {
          if (data.data?.createLink?.link) {
            resolve(data.data?.createLink?.link);
          }
        });
    });
  };

  public renderOrderCaretIcon = (): JSX.Element => {
    const orderIcon = this.state.isOrderByAscending ? "caret-down" : "caret-up";
    return <Icon className="caret-order" name={orderIcon} />;
  };

  public renderRecentContent = (): JSX.Element | null => {
    return (
      <RecentPicker
        screenId={this.props.screenId}
        callback={(id, refType, contentData) =>
          this.onSelectPickerItems([id], refType, [contentData as any])
        }
      />
    );
  };

  public renderChannelContent = (): JSX.Element | null => {
    return (
      <ChannelPicker
        spaceId={this.props.spaceId}
        callback={(id, channel) =>
          this.onSelectPickerItems([id], RefType.CHANNEL, [
            channel,
          ] as Channel[])
        }
      />
    );
  };

  public renderMediaContent = (): JSX.Element | null => {
    const {
      action,
      allowMediaMimeType,
      menu,
      multiple,
      selected,
      section,
      router,
    } = this.props;
    return (
      <MediaPickerComponent
        action={action}
        allowMediaMimeType={allowMediaMimeType}
        menu={menu}
        multiple={multiple}
        callBack={(ids, refType, files) =>
          this.onSelectPickerItems(ids, refType, files as File[])
        }
        selected={selected}
        section={section}
        router={router}
        handleSelectedPickerItem={this.props.handleSelectedPickerItem}
        checkboxStyle={CheckBoxStyle.CIRCLE}
        spaceId={this.props.spaceId}
      />
    );
  };

  public renderSiteContent = (): JSX.Element | null => {
    return (
      <SitePickerComponent
        isMultipleSelect={this.props.multiple}
        callback={(ids, selectedSites) =>
          this.onSelectPickerItems(ids, RefType.SITE, selectedSites)
        }
        router={this.props.router}
        handleCreateSiteCallback={this.handleCreateSiteCallback}
        spaceId={this.props.spaceId}
      />
    );
  };

  public handleCreateSiteCallback = () => {
    this.props.router.gotoComponentByName("Media Picker", false);
  };

  public renderSelectedSection = (section: RefType): JSX.Element | null => {
    switch (section) {
      case RefType.RECENT:
        return this.renderRecentContent();
      case RefType.CHANNEL:
        return this.renderChannelContent();
      case RefType.FILE:
        return this.renderMediaContent();
      case RefType.PLAYLIST:
        return this.renderPlaylistContent();
      case RefType.LINK:
        return this.renderLinkContent();
      case RefType.CANVAS:
        return this.renderCanvasContent();
      case RefType.APP:
        return this.renderAppContent();
      case RefType.SITE:
        return this.renderSiteContent();
      default:
        return null;
    }
  };

  public renderAppContent = (): JSX.Element => {
    return (
      <AppPickerComponent
        isMultipleSelect={this.props.multiple}
        spaceId={this.props.spaceId}
        callback={(ids, selectedApps) =>
          this.onSelectPickerItems(ids, RefType.APP, selectedApps)
        }
      />
    );
  };

  public renderCanvasContent = (): JSX.Element => {
    return (
      <CanvasPickerComponent
        isMultipleSelect={this.props.multiple}
        spaceId={this.props.spaceId}
        callback={(ids, selectedApps) =>
          this.onSelectPickerItems(ids, RefType.APP, selectedApps)
        }
      />
    );
  };

  public renderPlaylistContent = (): JSX.Element => {
    return (
      <PlaylistPickerComponent
        multiple={this.props.multiple ? this.props.multiple : false}
        spaceId={this.props.spaceId}
        callback={(ids, playlist) =>
          this.onSelectPickerItems(
            ids,
            RefType.PLAYLIST,
            playlist as Playlist[]
          )
        }
      />
    );
  };

  public renderLinkContent = (): JSX.Element => {
    return (
      <LinkPickerComponent
        isMultipleSelect={this.props.multiple}
        spaceId={this.props.spaceId}
        callback={(ids, selectedLinks) =>
          this.onSelectPickerItems(ids, RefType.LINK, selectedLinks)
        }
        createLinkCallback={this.handleCreateLink}
        router={this.props.router}
      />
    );
  };

  public renderPickerActionMode = (
    action: MediaPickerActionMode
  ): JSX.Element | null => {
    if (action === MediaPickerActionMode.ADD) {
      return (
        <>
          <Icon name="plus-fill" />
          <span>Add</span>
        </>
      );
    } else if (action === MediaPickerActionMode.VIEW) {
      return (
        <>
          <Icon name="preview" />
          <span>View</span>
        </>
      );
    } else if (action === MediaPickerActionMode.CHOOSE) {
      return (
        <>
          <Icon name="preview" />
          <span>Choose</span>
        </>
      );
    } else if (action === MediaPickerActionMode.CAST) {
      return (
        <>
          <span>Confirm</span>
        </>
      );
    } else if (action === MediaPickerActionMode.REPLACE) {
      return (
        <>
          <span>Replace</span>
        </>
      );
    } else {
      return null;
    }
  };

  public onExpireDateChange = (expireDate: Date | undefined) => {
    const { callBack } = this.props;
    const { mediaId, mediaType, mediaData } = this.state;
    if (callBack) {
      callBack(mediaId, mediaType, mediaData, expireDate);
    }
  };

  public render() {
    const { selectedPickerItems } = this.state;
    const { action, menu, multiple, handleSelectedPickerItem } = this.props;
    const isMultiSelect = Boolean(multiple);
    const selectedSection = this.getSection(this.state.section);
    const showContentSettingGroup =
      action === MediaPickerActionMode.CAST ||
      action === MediaPickerActionMode.SET_CONTENT;

    return (
      <StyledMediaPickerContainer
        isMultiSelect={isMultiSelect}
        isOrgWidePath={isOrgWidePath()}
      >
        <div id="media-picker__sidebar" data-testid="media-picker-sidebar">
          {menu.map((section) => (
            <Button
              data-testid={`media-picker-menu-${section}`}
              key={`media-picker-menu-${section}`}
              onClick={() => this.onMenuClick(section)}
              transparent
              active={selectedSection === section}
            >
              <Icon
                name={
                  section === RefType.FILE
                    ? "folder"
                    : section === RefType.RECENT
                    ? "clock"
                    : section === RefType.SITE
                    ? "dashboard"
                    : section
                }
              />{" "}
              <FormattedMessage
                id={`ui_component.media_picker_nav.${section}`}
                defaultMessage={section}
              />
            </Button>
          ))}
        </div>
        <div id="media-picker__content">
          {this.renderSelectedSection(selectedSection)}
          <div className="md-footer">
            {showContentSettingGroup && (
              <ContentSettingGroup
                action={this.state.screenPickerAction}
                setAction={(screenPickerAction) => {
                  this.setState({ screenPickerAction });
                }}
                onExpireDateChange={this.onExpireDateChange}
              ></ContentSettingGroup>
            )}
            {selectedPickerItems.length > 0 && multiple && (
              <div className="selected-item">
                <span> Selected</span>
                <span className="count">{selectedPickerItems.length}</span>
              </div>
            )}

            <PrimaryButton
              disabled={selectedPickerItems.length === 0}
              onClick={() => {
                handleSelectedPickerItem(this.state.screenPickerAction);
              }}
              data-testid="button-picker-action"
            >
              {this.renderPickerActionMode(action)}
            </PrimaryButton>
          </div>
        </div>
      </StyledMediaPickerContainer>
    );
  }
}

export default withData(
  MediaPickerContainerComponent
) as React.ComponentType<MediaPickerContainerComponentProps>;
