import { ssm } from "../../state/session/ssm";
import * as api from "@screencloud/billing-client-api";
import { mapFromApi as mapSubscriptionFromApi } from "../hooks/useSubscription";
import { mapFromApi as mapPaymentSourceFromApi } from "../hooks/usePaymentSource";
import { mapFromApi as mapPlanFromApi } from "../hooks/usePlan";
import { mapFromApi as mapCustomerFromApi } from "../hooks/useCustomer";
import { Customer, Provider, Subscription } from "../types";

export const getClient = () => {
  const token = ssm.current.token;
  const idToken = ssm.getAuthToken();
  if (!token) {
    return;
  }

  return new api.client.Billing(token, idToken, "/");
};

/**
 * @remarks
 * Ideally, all the functions below would be in hooks, however because we use class components
 * we still need to have these functions in the service client.
 */

export const getCustomerProvider = async () => {
  try {
    const response = await getClient()?.customer.getProvider();
    return response;
  } catch (error) {
    console.log("Error getting customer provider", error);
    return {
      provider: "chargebee" as Provider,
    };
  }
};
export const getCustomer = async (spaceId: string) => {
  try {
    const response = await getClient()?.customer.getCustomer(spaceId);
    return mapCustomerFromApi(response || ({} as Customer));
  } catch (error) {
    return {} as Customer;
  }
};

export const updateCustomerAddress = async (data: api.Customers.Me.Address.Request, spaceId?: string) => {
  const response = await getClient()?.customer.updateCustomerAddress(data, spaceId);
  return mapCustomerFromApi(response || ({} as Customer));
};

type SubscriptionResponse = api.Subscriptions.Me.Get.Response;
export const getSubscription = async (spaceId?: string): Promise<Subscription> => {
  try {
    const response = await getClient()?.subscriptions.getSubscription(spaceId);
    return mapSubscriptionFromApi(response || ({} as SubscriptionResponse));
  } catch (error) {
    return {} as Subscription;
  }
};

type SubscriptionRenewal = api.Subscriptions.Me.Get.Response;
export const getSubscriptionRenewal = async (spaceId?: string) => {
  const response = await getClient()?.subscriptions.getSubscriptionRenewal(spaceId);
  return mapSubscriptionFromApi(response || ({} as SubscriptionRenewal));
};

type SubscriptionUpdateResponse = api.Subscriptions.Me.Update.Response;
export const updateSubscription = async (data: api.Subscriptions.Me.Update.Request, spaceId?: string) => {
  const response = await getClient()?.subscriptions.updateSubscription(data, spaceId);
  return mapSubscriptionFromApi(response || ({} as SubscriptionUpdateResponse));
};

type SubscriptionStartResponse = api.Subscriptions.Me.Start.Response;
export const startSubscription = async (spaceId?: string) => {
  const response = await getClient()?.subscriptions.startSubscription(spaceId);
  return mapSubscriptionFromApi(response || ({} as SubscriptionStartResponse));
};

type SubscriptionPoNumberResponse = api.Subscriptions.Me.PoNumber.Response;
export const updateSubscriptionPoNumber = async (data: api.Subscriptions.Me.PoNumber.Request, spaceId?: string) => {
  const response = await getClient()?.subscriptions.updateSubscriptionPoNumber(data, spaceId);
  return mapSubscriptionFromApi(response || ({} as SubscriptionPoNumberResponse));
};

type Plan = api.Plans.Me.Get.Response;
export const getPlan = async (spaceId?: string) => {
  try {
    const response = await getClient()?.plans.getPlan(spaceId);
    return mapPlanFromApi(response || ({} as Plan));
  } catch (error) {
    console.log("Error getting plan", error);
    return {} as Plan;
  }
};

type PlanRenewal = api.Plans.Me.Get.Response;
export const getPlanRenewal = async (spaceId?: string) => {
  const response = await getClient()?.plans.getPlanRenewal(spaceId);
  return mapPlanFromApi(response || ({} as PlanRenewal));
};

/**
 *
 * @remarks
 * To retrieve the desired plan(s), you must provide the corresponding version parameter. This parameter is mandatory
 * and determines the specific plan version to be returned. Here are the available plan versions:
 * v1: Signage
 * v2: Legacy Studio
 * v3: 2021 plans (e.g., Starter, Teams)
 * v4: 2022 plans (e.g., Core, Pro)
 * Make sure to include the appropriate version parameter when calling this API endpoint to ensure that the correct
 * plans are returned based on your specific requirements and compatibility needs.
 */
export const searchPlans = async (params: api.Plans.List.Request) => {
  const response = await getClient()?.plans.searchPlans(params);
  return response?.map((plan) => mapPlanFromApi(plan));
};

/**
 *
 * @remarks
 * This endpoint fetches an array of payment sources associated with organizations. Generally, only one payment source
 * is expected, but multiple sources may exist. However, only the top payment source from the array will be returned.
 * The API ensures that the payment sources are ordered appropriately (this includes invoice customers), so we rely on
 * this ordering.
 */
export const getPaymentSource = async (spaceId?: string) => {
  const paymentSources = await getClient()?.paymentsources.getPaymentSource(spaceId);
  if (!paymentSources || !paymentSources.length) {
    return null;
  }
  return mapPaymentSourceFromApi(paymentSources[0]);
};

type PaymentSourceCardSetupResponse = api.PaymentSources.Me.Card.Setup.Response;
export const createPaymentSourceCardSetup = async (
  data: api.PaymentSources.Me.Card.Setup.Request,
  spaceId?: string
): Promise<PaymentSourceCardSetupResponse | undefined> => {
  const response = await getClient()?.paymentsources.createPaymentSourceCardSetup(data, spaceId);
  return response;
};

type PaymentSourceCardPaymentIntentResponse = api.PaymentSources.Me.Card.Payment.Response;
export const createPaymentSourceCardPaymentIntent = async (
  data: api.PaymentSources.Me.Card.Payment.Request,
  spaceId?: string
): Promise<PaymentSourceCardPaymentIntentResponse | undefined> => {
  return getClient()?.paymentsources.createPaymentSourceCardPaymentIntent(data, spaceId);
};

type PaymentSourceCreateCard = api.PaymentSources.Me.Card.Complete.Response;
export const createPaymentSourceCard = async (
  data: api.PaymentSources.Me.Card.Complete.Request,
  spaceId?: string
): Promise<PaymentSourceCreateCard | undefined> => {
  return getClient()?.paymentsources.createPaymentSourceCard(data, spaceId);
};

type PaymentSourcePaypalAuthorizeResponse = api.PaymentSources.Me.Paypal.Authorize.Response;
export const createPaymentSourcePaypalAuthorize = async (
  data: api.PaymentSources.Me.Paypal.Authorize.Request,
  spaceId?: string
): Promise<PaymentSourcePaypalAuthorizeResponse | undefined> => {
  return getClient()?.paymentsources.createPaymentSourcePaypalAuthorize(data, spaceId);
};

type PaymentSourcePaypalCompleteResponse = api.PaymentSources.Me.Paypal.Complete.Response;
export const createPaymentSourcePaypalComplete = async (
  data: api.PaymentSources.Me.Paypal.Complete.Request,
  spaceId?: string
): Promise<PaymentSourcePaypalCompleteResponse | undefined> => {
  return getClient()?.paymentsources.createPaymentSourcePaypalComplete(data, spaceId);
};

type PaymentSourceBankAuthorizeResponse = api.PaymentSources.Me.Bank.Authorize.Response;
export const createPaymentSourceBankAuthorize = async (
  data: api.PaymentSources.Me.Bank.Authorize.Request,
  spaceId?: string
): Promise<PaymentSourceBankAuthorizeResponse | undefined> => {
  return getClient()?.paymentsources.createPaymentSourceBankAuthorize(data, spaceId);
};

type PaymentSourceBankCompleteResponse = api.PaymentSources.Me.Bank.Complete.Response;
export const createPaymentSourceBankComplete = async (
  data: api.PaymentSources.Me.Bank.Complete.Request,
  spaceId?: string
): Promise<PaymentSourceBankCompleteResponse | undefined> => {
  return getClient()?.paymentsources.createPaymentSourceBankComplete(data, spaceId);
};

type PaymentSourceInvoiceResponse = api.PaymentSources.Me.Invoice.Complete.Response;
export const createPaymentSourceInvoice = async (
  spaceId?: string
): Promise<PaymentSourceInvoiceResponse | undefined> => {
  return getClient()?.paymentsources.createPaymentSourceInvoice(spaceId);
};

type CouponsResponse = api.Coupons.Me.Get.Response;
export const getCoupons = async (spaceId?: string): Promise<CouponsResponse | undefined> => {
  return getClient()?.coupons.getCoupons(spaceId);
};

type CouponsRenewalResponse = api.Coupons.Me.Get.Response;
export const getCouponsRenewal = async (spaceId?: string): Promise<CouponsRenewalResponse | undefined> => {
  return getClient()?.coupons.getCouponsRenewal(spaceId);
};

type FeatureFlags = api.FeatureFlags.Me.Get.Response;
export const getBillingFeatureFlags = async (spaceId?: string): Promise<FeatureFlags | undefined> => {
  try {
    const response = await getClient()?.featureflags.getFeatureFlags(spaceId);
    return response;
  } catch (error) {
    return [];
  }
};
