import styled from "styled-components";

export const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .panel-preview {
    display: inline-grid !important;

    > .screen-preview-wrapper {
      width: 85% !important;
      margin: 0 auto;
      padding-bottom: 8%;
    }
  }

  .preview-content {
    width: 100%;
    height: 100%;
    display: inline-grid;
    align-items: center;
    font-family: Lato;
    color: #ffffff;
  }

  span {
    text-align: center;
  }

  .popupMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 16px;
    }
  }

  .btn {
    button {
      background-color: inherit;
      color: #ffffff;
    }
  }
`;
